import authTypes from "./authTypes";

const getDataFromLocalStorage = (item) => {
  return localStorage.getItem(item) || null
}

function getRoles() {
  const roles = getDataFromLocalStorage('roles');
  let parsed = null;
  try {
    parsed = JSON.parse(roles);
  } catch (e) {
    parsed = roles;
  }

  return Array.isArray(parsed) ? parsed : [parsed]
}





const initialState = {
  uuid: getDataFromLocalStorage('uuid'),
  token: getDataFromLocalStorage('token'),
  refresh: getDataFromLocalStorage('refresh'),
  user: null,
  loading: true,
  authenticated: getDataFromLocalStorage('token') && getDataFromLocalStorage('roles') ? true : false,
  verified: null,
  roles: getRoles() || [],
  error: null,
  challenge: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case authTypes.RESET_CHALLENGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case authTypes.RESET_CHALLENGE_SUCCESS:
      return {
        ...state,
        loading: false,
        challenge: null
      };
    case authTypes.LOAD_USER_REQUEST:
    case authTypes.REGISTER_REQUEST:
    case authTypes.CONFIRM_REQUEST:
    case authTypes.AUTHENTICATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authTypes.LOGIN_REQUEST: {
      return {
        ...state,
        username: action.payload.username,
      };
    }
    case authTypes.RESET_PASSWORD_SUCCESS:
    case authTypes.RESET_PASSWORD_FAILURE:
    case authTypes.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case authTypes.VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        verified: true
      };
    case authTypes.VERIFY_FAILURE:
      return {
        ...state,
        verified: false,
      };
    case authTypes.CONFIRM_SUCCESS:
    case authTypes.LOGIN_SUCCESS:
      if (action.payload.challenge) {
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      }
      return {
        ...state,
        ...action.payload,
        loading: false,
        authenticated: true,
      };

    case authTypes.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        site: action.payload,
        authenticated: true,
      };

    case authTypes.LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case authTypes.REFRESH_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    case authTypes.LOGIN_FAILURE:
    case authTypes.RESET_CHALLENGE_FAILURE:
      return {
        ...state,
        loading: false,
        authenticated: false,
        id: null,
        user: null,
        uuid: null,
        token: null,
        refresh: null,
        error: action.payload
      };
    case "CLEAR_ALL":
    case authTypes.LOGOUT:
    case authTypes.AUTHENTICATE_FAILURE:
    case authTypes.REGISTER_FAILURE:
    case authTypes.CONFIRM_FAILURE:
    case authTypes.LOAD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        authenticated: false,
        id: null,
        user: null,
        uuid: null,
        token: null,
        refresh: null,
      };

    default:
      return state;
  }
};
