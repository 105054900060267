import { useEffect, useState } from 'react';

const usePullToRefresh = (threshold = 800) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let startY = 0;
    let currentY = 0;
    let pullingDown = false;

    const handleTouchStart = (e) => {
      startY = e.touches[0].clientY;
      pullingDown = window.scrollY === 0; // Check if we are at the top of the page
    };

    const handleTouchMove = (e) => {
      if (!pullingDown) return;
      currentY = e.touches[0].clientY;
      // console.log('currentY--->',currentY);
      // console.log('startY==>',startY);
      // console.log('threshold-->',threshold);
      if (currentY - startY > threshold) {
        e.preventDefault();
        setLoading(true);
      }
    };

    const handleTouchEnd = () => {
      if (loading) {
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Add a delay to show the loader before reloading
      }
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [threshold, loading]);

  return loading;
};

export default usePullToRefresh;
