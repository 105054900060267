import libraryTypes from "./libraryTypes";

const initialState = {
  unitsOfMeasure: null,
  species: null,
  animalTypes: null,
  animalColors: null,
  diagnoses: null,
  healthRisks: null,
  treatments: null,
  breeds: null,
  tagColors: null,
  records: null,
};

const typeMap = {
  GET_ANIMAL_COLOR_SUCCESS: { list: "animalColors", plural: false },
  GET_UNIT_OF_MEASURE_SUCCESS: { list: "unitsOfMeasure", plural: false },
  GET_SPECIES_SUCCESS: { list: "species", plural: false },
  GET_ANIMAL_TYPE_SUCCESS: { list: "animalTypes", plural: false },
  GET_DIAGNOSIS_SUCCESS: { list: "diagnoses", plural: false },
  GET_HEALTH_RISK_SUCCESS: { list: "healthRisks", plural: false },
  GET_TREATMENT_SUCCESS: { list: "treatments", plural: false },
  GET_BREED_SUCCESS: { list: "breeds", plural: false },

  GET_ANIMAL_COLORS_SUCCESS: { list: "animalColors", plural: true },
  GET_UNITS_OF_MEASURE_SUCCESS: { list: "unitsOfMeasure", plural: true },
  GET_SPECIES_LIST_SUCCESS: { list: "species", plural: true },
  GET_ANIMAL_TYPES_SUCCESS: { list: "animalTypes", plural: true },
  GET_DIAGNOSES_SUCCESS: { list: "diagnoses", plural: true },
  GET_HEALTH_RISKS_SUCCESS: { list: "healthRisks", plural: true },
  GET_TREATMENTS_SUCCESS: { list: "treatments", plural: true },
  GET_BREEDS_SUCCESS: { list: "breeds", plural: true },
  GET_TAG_COLORS_SUCCESS: { list: "tagColors", plural: true },
  GET_ANIMAL_RECORDS_SUCCESS: { list: "records", plural: true },
};

const setList = (state, payload, list) => ({
  ...state,
  [list]: payload.reduce((obj, val) => {
    obj[val.id] = val;
    return obj;
  }, {}),
});

const addValueToList = (state, payload, list) => ({
  ...state,
  [list]: { ...state[list], [payload.id]: payload },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const mapObj = typeMap[action.type];
  if (mapObj) {
    if (mapObj.plural) return setList(state, action.payload, mapObj.list);
    else return addValueToList(state, action.payload, mapObj.list);
  }

  switch (action.type) {
    case "CLEAR_ALL":
    case libraryTypes.CLEAR_LIBRARY_STORE:
      return initialState;

    default:
      return state;
  }
};
