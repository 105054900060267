import authTypes from "./authTypes";

export const clearError = () => ({
  type: authTypes.CLEAR_ERROR,
  payload: {}
})

export const login = (email, password) => ({
  type: authTypes.LOGIN_REQUEST,
  // API has "username" field for email
  payload: { username: email, password },
});

export const resetRequest = (email) => ({
  type: authTypes.RESET_PASSWORD_REQUEST,
  payload: { username: email }
});
export const verify = (verify) => {
  return {
    type: authTypes.VERIFY_REQUEST,
    payload: { ...verify }
  };
};

export const resetChallenge = (data) => ({
  type: authTypes.RESET_CHALLENGE_REQUEST,
  payload: data
});

export const logout = () => ({
  type: authTypes.LOGOUT_ASYNC,
});

export const authenticate = () => ({
  type: authTypes.AUTHENTICATE_REQUEST,
});

export const loadUser = () => ({
  type: authTypes.LOAD_USER_REQUEST,
});
