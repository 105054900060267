import React from 'react';
import PageWrapper from '../../../components/common/PageWrapper/PageWrapper';
import styles from './AnimalDetail.module.css';
import { faNotesMedical,faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Form } from 'react-bootstrap';
import Icon from '../../../components/common/Icon/Icon';

const AnimalDetail = () => (
  <div className={styles.AnimalDetail}>
    <PageWrapper title="CHM-123" icon={faNotesMedical} />
    <div className="p-6 text-white">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      RFID#
      <Form.Control className="text-white text-3xl" value="test" />
      <div className="flex my-3">
        <div className="flex-1">
          TAG #
          <Form.Control />
        </div>
        <div className="flex-1 mx-2">
          PEN
          <Form.Control />
        </div>
        <div className="flex-1">
          LOT
          <Form.Control />
        </div>
      </div>
      Date of Death
      <div className="text-3xl mb-3">12-12-2121</div>
      SUSPECTED CAUSE OF Death
      <p>Died</p>
      OTHER NOTES
      <p>Notes not here</p>
      Veterinarian findings
      <Form.Control as="textarea" className="mb-3" />
      Veterinarian notes
      <Form.Control as="textarea" />
      <div className="my-20 text-center">
        <Icon icon={faCheck} size="60" />
      </div>
    </div>
  </div>
)

export default AnimalDetail 
