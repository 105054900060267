import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

const GenerateRouters = ({ routersConst, roles }) => {
  const hasRouterUserRole = (userRoles, routerRoles) => {
    return userRoles.some((userRole) =>
      routerRoles.some((routerRole) => routerRole === userRole)
    );
  };

  const findRouters = (route) => {
    if (!hasRouterUserRole(roles, route.permissionRoles)) {
      return null;
    } else if (route.redirect) {
      return (
        <Route exact path={route.path} key={route.path}>
          <Redirect to={route.redirect} />
        </Route>
      );
    } else {
      const routeProps = { path: route.path };

      if (route.render) {
        routeProps.render = route.render;
      }

      if (route.component) {
        routeProps.component = route.component;
      }
      return <Route key={route.path} exact {...routeProps} />;
    }
  };

  const templateWithRouters = routersConst.reduce(
    (routersArr, currentRouter) => {
      const routerForRender = findRouters(currentRouter);

      if (routerForRender) {
        return [...routersArr, routerForRender];
      } else return [...routersArr];
    },
    []
  );
  return <Switch>{templateWithRouters}</Switch>;
};

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(GenerateRouters);
