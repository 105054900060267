import {combineReducers} from "redux";
import authReducer from "./auth/authReducer";
import connectionReducer from "./connections/connectionReducer";
import frameReducer from "./frames/frameReducer";
import loadingReducer from "./loading/loadingReducer";
import libraryReducer from "./library/libraryReducer";
import postmortemReducer from "./postmortem/postmortemReducer";
import vetAnimalReducer from "./vetanimal/vetAnimalReducer";
import userReducer from "./user/userReducer";
import customerReducer from "./customers/customerReducer";
import siteReducer from "./sites/siteReducer";
import notificationReducer from './notifications/notificationReducer';
import inviteReducer from './invites/inviteReducer';
import mediaReducer from "./media/mediaReducer";
import trainingReducer from "./training/trainingReducer";
import searchReducer from "./search/searchReducer";


export default combineReducers({
  auth: authReducer,
  vetanimal: vetAnimalReducer,
  postmortem: postmortemReducer,
  connections: connectionReducer,
  frames: frameReducer,
  loading: loadingReducer,
  library: libraryReducer,
  user: userReducer,
  customer: customerReducer,
  site: siteReducer,
  notification: notificationReducer,
  invite: inviteReducer,
  media: mediaReducer,
  training: trainingReducer,
  search: searchReducer
});
