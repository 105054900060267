import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';

import { login, authenticate, resetRequest, verify } from '../../../store/auth';

import Loading from '../../../components/common/Loading/Loading';
import PasswordInput from '../../../components/common/PasswordInput';
import history from '../../../utils/history';

import './ResetScreen.css';

const ResetScreen = ({
  authenticate,
  authenticated,
  loading,
  resetRequest,
  verify,
  verified
}) => {
  const [step, setStep] = useState(1);
  const [reset, setReset] = useState({
    code: "",
    username: "",
    password: "",
    confirmPassword: ""
  });
  const [error, setError] = useState({
    code: "",
    username: "",
    password: "",
    confirmPassword: "",
    verify: ""
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    authenticate();
    if (authenticated) history.push("/a/");
    //eslint-disable-next-line
    if (step === 2) {
      if (verified) {
        setStep(3);
      } else {
        setShow(true);
        setError({
          ...error,
          verify:
            "Verification failed. Please check to make sure you entered your code and e-mail correctly."
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, history, verified, loading]);

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: '' });
    setReset({
      ...reset,
      [e.target.name]: e.target.value,
    });
  };

  const sendMail = (e) => {
    e.preventDefault();
    if (reset.username.trim() === '') {
      setError({
        ...error,
        username: "Email is required."
      });
    } else {
      setStep(2);
      resetRequest(reset.username);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newError = {};
    if (reset.code.trim() === '') {
      newError.code = "Please enter a verification code.";
    }
    if (reset.password.trim() !== reset.confirmPassword.trim()) {
      newError.confirmPassword = "Passwords do not match.";
    }
    if (reset.password.trim().length < 8) {
      newError.password = "Password needs to be at least 8 characters long.";
    }
    if (reset.password.trim().match(/[A-Z]/g) === null) {
      newError.password = "Password needs to have at least 1 capital letter.";
    }
    if (reset.password.trim().match(/\d/g) === null) {
      newError.password = "Password needs to have at least 1 number.";
    }
    if (reset.password.trim().match(/[!@#$%^&*?]/g) === null) {
      newError.password =
        "Password needs to have at least 1 special character !@#$%^&*?";
    }

    if (newError.code || newError.password || newError.confirmPassword) {
      setError(newError);
    } else {
      verify(reset);
      //goto next step only if verified is false or null.
    }
  };

  if (loading) {
    return (
      <div className="login-container my-auto">
        <Loading visible={loading} />
      </div>
    );
  }

  return (
    <div className="login-container w-100">
      {!verified && show && (
        <div className="login-toast-container" style={{ zIndex: 9999999 }}>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={5000}
            autohide
          >
            <Toast.Header bsPrefix="toast-header-error">
              <span>{error.verify}</span>
            </Toast.Header>
          </Toast>
        </div>
      )}
      <Form className="login-button-container d-flex flex-column mx-auto">
        {step === 1 && (
          <>
            <Form.Group className="w-100">
              <Form.Label className="text-white text-left w-100">
                Email
              </Form.Label>
              <Form.Control
                name="username"
                className="w-100 mb-2 rounded"
                placeholder="Enter your email"
                type="text"
                value={reset.username}
                onChange={onChange}
              />
              <span className="error-container text-danger text-sm">
                {error.username}
              </span>
            </Form.Group>
            <div className="recover-container-text w-100 text-left text-sm">
              A password reset will be sent to the address above if it can be
              found in our system.
            </div>
            <div className="recover-container w-100 text-center mt-3">
              <Link className="recover-password-text text-white" to="/u/login">
                Back to login
              </Link>
            </div>
            <button
              className="reset-button rounded"
              type="submit"
              onClick={sendMail}
              disabled={loading}
            >
              SEND RESET
            </button>
          </>
        )}
        {step === 2 && (
          <>
            <Form.Group className="w-100">
              <Form.Label className="text-white text-left w-100">
                Verification code
              </Form.Label>
              <Form.Control
                name="code"
                className="w-100 mb-2 rounded"
                placeholder="Enter verification code"
                type="text"
                value={reset.code}
                onChange={onChange}
              />
              <span className="error-container text-danger text-sm">
                {error.code}
              </span>
            </Form.Group>
            <PasswordInput
              inputLabel="Password"
              inputName="password"
              placeholder="Password"
              passwordValue={reset.password}
              error={error.password}
              handleOnChange={onChange}
            />
            <PasswordInput
              inputLabel="Password verify"
              inputName="confirmPassword"
              placeholder="Confirm password"
              passwordValue={reset.confirmPassword}
              error={error.confirmPassword}
              handleOnChange={onChange}
            />
            <div
              className="recover-container w-100 text-left"
              style={{ color: "#6C757D" }}
            >
              Please check your e-mail for a verification code and enter it
              above to continue.
            </div>
            <button
              className="login-button mb-5 rounded"
              type="submit"
              onClick={onSubmit}
              disabled={loading}
            >
              VERIFY
            </button>
          </>
        )}
        {step === 3 && (
          <>
            <div className="recover-container w-100 text-left text-white">
              Your password has now been reset.
            </div>
            <br />
            <Link className="login-button rounded" to="/u/login">
              GO TO LOGIN
            </Link>
          </>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.loading.VERIFY,
    verified: state.auth.verified
  };
};

const mapStateToDispatch = {
  login,
  authenticate,
  resetRequest,
  verify
};

export default connect(mapStateToProps, mapStateToDispatch)(ResetScreen);
