import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PasswordInput = ({
  inputLabel,
  inputName,
  placeholder,
  passwordValue,
  error,
  handleOnChange
}) => {
  const [isShow, setShow] = useState(false);

  const clickShowPassword = () => {
    setShow(!isShow);
  };

  return (
    <Form.Group className="w-100 block-show">
      <Form.Label className="text-white text-left w-100">
        {inputLabel}
      </Form.Label>
      <div className="w-100 relative">
        <Form.Control
          name={inputName}
          className="w-100 mb-2 rounded"
          style={{ paddingRight: "39px" }}
          placeholder={placeholder}
          type={isShow ? "text" : "password"}
          value={passwordValue}
          onChange={handleOnChange}
        />
        <FontAwesomeIcon
          icon={isShow ? faEyeSlash : faEye}
          className="img-eye"
          color="white"
          onClick={clickShowPassword}
        />
      </div>
      <span className="error-container text-danger text-sm">{error}</span>
    </Form.Group>
  );
};

export default PasswordInput;
