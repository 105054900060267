import types from './trainingTypes';

const initialState = {
    trainings: {},
    loading: false
};

const trainingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_TRAININGS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case types.GET_TRAININGS_SUCCESS:
            return {
                ...state,
                trainings: action.payload,
                loading: false
            }
        case types.GET_TRAINING_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        default:
            return {
                ...state,
                loading: false
            }
    }
};
export default trainingReducer;