import React, { Component } from 'react';
import { connect } from 'react-redux';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import Animal from '../../../components/common/Animal/Animal';
import Icon from '../../../components/common/Icon/Icon';
import Wrapper from '../../../components/common/PageWrapper/PageWrapper';

import { getVetAnimal, updateVetAnimal, defaultVetAnimal } from '../../../store/vetanimal';
import { getTagColors, getDiagnoses } from '../../../store/library';

import history from '../../../utils/history';

export class VetAnimal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animal: defaultVetAnimal(),
      loading: true,
    };

    // if (!this.props.tagColors.length) {
    //   this.props.getTagColors();
    // }

    // if (!this.props.tagDiagnoses.length) {
    //   this.props.getDiagnoses();
    // }

    props.getVetAnimal(props.match.params.id);

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({ animal: this.props.animal, loading: this.props.loading });
    }
  }

  submit() {
    this.props.updateVetAnimal(this.state.animal);
    history.goBack();
  }


  render() {
    const { animal } = this.state;
    const { tagDiagnoses } = this.props;
    const selectedTagDiagnoses = animal && animal.vet_diagnosis_id
      ? tagDiagnoses.find(tagDiagnosis => tagDiagnosis.id === animal.vet_diagnosis_id)
      : null;
    const selectedTagDiagnosesName = selectedTagDiagnoses ? selectedTagDiagnoses.name : '';

    return (
      <Wrapper
        type="all"
        title={animal && animal.rfid}
        icon={faPencilAlt}
        loading={this.state.loading}
      >
        <Animal
          { ...animal }
          selectedTagDiagnoses={selectedTagDiagnosesName}
          tagColors={this.props.tagColors}
          tagDiagnoses={tagDiagnoses}
          mode="vet"
          setParentState={this.setState.bind(this)}
        />
        <div className="mt-20 block flex">
          <div className="flex-1">
            <Icon
              icon={faCheck}
              size="45"
              style={{ display: 'block' }}
              className="m-auto"
              onClick={this.submit.bind(this)}
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const tagColors = state.library.tagColors
    ? Object.values(state.library.tagColors)
    : [];

  const tagDiagnoses = state.library.diagnoses
    ? Object.values(state.library.diagnoses)
    : [];

  return {
    animal: state.vetanimal.veterinary_record_animal,
    loading: state.loading.GET_VETANIMAL,
    tagColors,
    tagDiagnoses
  };
};

const mapDispatchToProps = {
  getVetAnimal,
  getTagColors,
  getDiagnoses,
  updateVetAnimal
};

export default connect(mapStateToProps, mapDispatchToProps)(VetAnimal);
