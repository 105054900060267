import frameTypes from "./frameTypes";

const initialState = {
  back_route: null,
  navbarExtended: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {

    case frameTypes.SET_BACK_ROUTE:
      return {
        ...state,
        back_route: action.payload,
      };

    case frameTypes.CLEAR_BACK_ROUTE:
      return {
        ...state,
        back_route: null,
      };

    case frameTypes.REVERSE_NAVBAR:
      return {
        ...state,
        navbarExtended: !state.navbarExtended,
      };

    default:
      return state;
  }
};
