import frameTypes from "./frameTypes";

export const setFrameBackRoute = (back_route) => ({
  type: frameTypes.SET_BACK_ROUTE,
  payload: {back_route},
});

export const clearFrameBackRoute = () => ({
  type: frameTypes.CLEAR_BACK_ROUTE,
});

export const reverseNavbar = () => ({
  type: frameTypes.REVERSE_NAVBAR,
});
