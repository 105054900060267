import types from './userTypes';
import { defaultUser } from './index';

const initialState = defaultUser();

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        list: { ...action.payload },
        loading: false,
      };
    case types.GET_USER_SUCCESS:
    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case types.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ALL":
      return initialState;
    default: {
      return state;
    }
  }
};
