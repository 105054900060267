import types from "./trainingTypes";

export const getTrainings = () => ({
    type: types.GET_TRAININGS_REQUEST,
    payload: {}
});

export const getTraining = (id) => ({
    type: types.GET_TRAINING_REQUEST,
    payload: {id}
});

