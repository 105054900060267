const trainingTypes =  {

    GET_TRAININGS_REQUEST: "GET_TRAININGS_REQUEST",
    GET_TRAININGS_SUCCESS: "GET_TRAININGS_SUCCESS",
    GET_TRAININGS_FAILURE: "GET_TRAININGS_FAILURE",
    GET_TRAINING_REQUEST: "GET_TRAINING_REQUEST",
    GET_TRAINING_SUCCESS: "GET_TRAINING_SUCCESS",
    GET_TRAINING_FAILURE: "GET_TRAINING_FAILURE"

};

export default trainingTypes;