import React from "react";
import AppRouter from "./routers/AppRouter";
import { Provider } from "react-redux";
import store from "./store/rootStore";

import FeedbackWidget from "./utils/FeedbackWidget";
import PullToRefreshWrapper from './utils/PullToRefreshWrapper';
class App extends React.Component {
  render() {
   
    return (
      <Provider store={store}>
         <PullToRefreshWrapper> 
        <AppRouter />
        <FeedbackWidget />
        </PullToRefreshWrapper>
      </Provider>  
    );
  }
}

export default App;
