import React from "react";
import "./Footer.css";
import history from "../../../utils/history";
import { useSelector } from "react-redux";

import MUTooltip from "@material-ui/core/Tooltip";

const Footer = () => {
  const { location } = history;

  const user = useSelector((state) => state.auth.user) || {};

  const isVet = () => {
    if (user && user.roles) {
      return user.roles.includes("Veterinarian");
    }

    return false;
  };

const isAdmin = () => {
  if (user && user.roles) {
    return user.roles.includes("Admin") || user.roles.includes("SuperAdmin");
  };

  return false;
}

  const footerTabs = [
    {
      title: 'Vet Inbox',
      url: '/a/location/all/inbox',
      icon: 'fa-inbox',
      alt: 'Inbox',
      show: isVet() || isAdmin(),
    },
    {
      title: 'Locations',
      url: '/a/locations',
      icon: 'fa-map-signs',
      alt: 'Vet',
      show: true,
    },
    {
      title: 'Post Mortem',
      url: '/a/postmortem/add',
      icon: 'fa-skull-cow',
      alt: 'Post Mortem',
      show: true,
    },
        {
      title: 'Archive',
      url: '/a/postmortems/complete',
      icon: 'fa-archive',
      alt: 'My Records',
      show: true,
    },
    {
      title: 'Training',
      url: '/a/training',
      icon: 'fa-badge-check',
      alt: 'Reports',
      show: !isVet(),
    },
  ];

  return (
    <div className="footer-container">

      <div className="footer-btns-container d-flex flex-row justify-content-between">
        {footerTabs.map(tab => {
          if (!tab.show) {
            return null;
          } else {
            const isActive = location.pathname.includes(tab.url);

            return (
              <div
                className={`footer-btn ${isActive ? 'active' : ''}`}
                alt={tab.alt}
                onClick={() => history.push(tab.url)}
                key={tab.title}
              >
                <MUTooltip
                  title={tab.title}
                  enterDelay={500}
                  leaveDelay={200}
                  placement="top"
                  classes={{ tooltip: 'text-base' }}
                >
                  <i className={`${isActive ? "fas" : "far"} ${tab.icon}`} />
                </MUTooltip>
              </div>
            )
          }
        })}
      </div>
      {isVet() ? <div className="w-100 h-3 bg-success text-center m-0 p-0 pt-0 mt-0"><small  className="m-0 p-0 text-xs	text-white verySmall">VET ACCESS</small></div> : null }
      {isAdmin() ? <div className="w-100 h-3 bg-danger text-center m-0 p-0 pt-0 mt-0"><small  className="m-0 p-0 text-xs	text-white verySmall">ADMIN ACCESS</small></div> : null }
    </div>
  );
};

export default Footer;
