
import types from "./vetAnimalTypes";
import postmortemTypes from "../postmortem/postmortemTypes";
import {put} from "redux-saga/effects";
import callApi from "../../utils/callApi";
import getSiteEndpoint from "../../utils/getSiteEndpoint";

export function* addVetAnimal(action) {
    const {...animal} = action.payload.animal;
    if (!animal) yield put({type: types.ADD_VETANIMAL_FAILURE});

    yield callApi({
      method: "post",
      url: getSiteEndpoint() + "/veterinary-record-animals",
      type: "ADD_VETANIMAL",
      useFullResponse: false,
      dataProp: "veterinary_record_animal",
      body: animal,
      errorMessage: "There was an error while adding an animal.",
      onSuccess: (data) => {
        //history.push(`/a/postmortem/${data.id}?new`);
      },
    });

    yield put({type: postmortemTypes.GET_POSTMORTEM_REQUEST, payload: { id: animal.veterinary_record_id } });
}

export function* getVetAnimals() {
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/veterinary-record-animals",
    type: "GET_VETANIMALS",
    useFullResponse: false,
    dataProp: "veterinary_record_animals",
    errorMessage: "There was an error while getting animals.",
  });
}

export function* deleteAnimal(action) {
  yield callApi({
    method: "delete",
    url: `${getSiteEndpoint()}/veterinary-record-animals/${action.payload.animalId}`,
    type: "DELETE_VETANIMAL",
    reqData: {id: action.payload.animalId},
    errorMessage: "There was an error while deleting an animal."
  });

  yield put({type: postmortemTypes.GET_POSTMORTEM_REQUEST, payload: { id: action.payload.pmId } });
}
export function* getAnimal(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/veterinary-record-animals/${action.payload.id}`,
    type: "GET_VETANIMAL",
    reqData: {id: action.payload.id},
    errorMessage: "There was an error while getting the animal."
  });
}

export function* updateAnimal(action) {
  const animal = action.payload.animal;
  if (!animal) yield put({type: types.UPDATE_VETANIMAL_FAILURE});
  console.log('animal in redux  ------>',animal);

  yield callApi({
    method: "put",
    url: `${getSiteEndpoint()}/veterinary-record-animals/${animal.id}`,
    type: "UPDATE_VETANIMAL",
    dataProp: "animal",
    body: animal,
    errorMessage: "There was an error while updating an animal.",
  });
}

