import types from './vetAnimalTypes';
import { takeLatest, all } from "redux-saga/effects";
import * as actions from './vetAnimalActions';


export default function* vetAnimalSagas() {
   
    yield all([
        yield takeLatest(types.ADD_VETANIMAL_REQUEST, actions.addVetAnimal),
        yield takeLatest(types.GET_VETANIMALS_REQUEST, actions.getVetAnimals),
        yield takeLatest(types.DELETE_VETANIMAL_REQUEST, actions.deleteAnimal),
        yield takeLatest(types.UPDATE_VETANIMAL_REQUEST, actions.updateAnimal),
        yield takeLatest(types.GET_VETANIMAL_REQUEST, actions.getAnimal)
    ]);
}