import React, { Component } from 'react';
import PageWrapper from '../../../components/common/PageWrapper/PageWrapper';
import { connect } from "react-redux";
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import { getSites } from '../../../store/sites';
import history from '../../../utils/history';
import SiteList from './SiteList';
export class Locations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sites: {}
    };
    // this.props.getSites();
  }

  componentDidUpdate() {

    if (this.state.sites !== this.props.sites) {
      this.setState({
        sites: this.props.sites,
      });
    }

  }

  onClickAction(id) {
    const roles = localStorage.getItem("roles")
    if (roles.includes("Veterinarian") || roles.includes("Admin") || roles.includes("SuperAdmin")) {
      history.push(`/a/location/${id}/inbox`);
    } else {
      history.push(`/a/location/${id}/draft`);
    }
  }

  render() {
    return (
      <PageWrapper loading={this.props.loading} title="My Locations" type="all" icon={faLayerGroup}>
        <p className="p-4 pb-0 text-white text-sm">
          These are the locations you have been linked with.  If you should have access to an additional location please contact Cattle Health Management.
          <br />
          <br />
          <strong>Phone: 1-403-328-4454</strong>
          <br />
          <strong>Email: office@cattlehealthmanagement.com </strong>
        </p>


        <div className="px-6 text-white">
          <SiteList />
          {/* {Object.keys(this.state.sites).length > 0 && Object.keys(this.state.sites).map(key => {
            const site = this.state.sites[key];
            // const siteLocation = `${site.city ? site.city : ''}${site.city && site.region ? ',' : ''} ${site.region ? site.region : ''}`

            return (
              <div
                key={key}
                className="text-white border border-white rounded-sm p-2 pt-3 pl-4 mb-3 cursor-pointer"
                onClick={() => { this.onClickAction(site.id) }}
              >
                <h5>{site.customer.name} - {site.name}</h5>
              </div>
            )
          })
          } */}
        </div>
      </PageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.site.loading,
    sites: state.site.list
  };
};

const mapDispatchToProps = {
  getSites
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);