import moment from "moment";

/**
 * Take a list of all the records in the system and sort them into the proper tab as necessary
 * @param {array} list the list of all postmortems 
 * @param {string} active the tab that is active
 */
export const filterPostmortemList = (list, active) => {
  const filteredPM = list.filter((pm) => {
    const vetRecord = pm.veterinary_record;
    if (!vetRecord) return null
    const isDraft = !!vetRecord.draft;
    const isComplete = vetRecord.completed_at !== null;
    const animals = vetRecord.veterinary_record_animals;
    
    let inProgress = false;
    Object.values(animals).forEach((animal) => {
      if (!animal.media.length) {
        return;
      }
      
      animal.media.forEach(el => {
        if (el.role.includes("Veterinarian") || el.role.includes("SuperAdmin") || el.role.includes("Admin")) {
          inProgress = true;
          return;
        }
      });
    });

    if (active === "draft") {
      return isDraft;
    }

    if (active === "pending") {
      return !isDraft && !inProgress && !isComplete;
    }

    if (active === "inprogress" || active === "scheduled") {
      return inProgress && !isComplete;
    }

    if (active === "complete") {
      return isComplete;
    }

    return null;
  });

  if (active === "complete") {
    filteredPM
      .sort(
        (a, b) => 
          moment(a.veterinary_record.completed_at).isAfter(b.veterinary_record.completed_at) ? 1 : -1
    );
  }

  return filteredPM;
}

// export const mediaAnimalList = (action, list) => {
//   const mediaList = { ...list };

//   action.forEach((photo) => {
//     if (
//       !mediaList[photo.media_attachable_id] ||
//       !mediaList[photo.media_attachable_id].length ||
//       !mediaList[photo.media_attachable_id].find(
//         (item) => item.id === photo.id
//       )
//     ) {
//       const photoList = mediaList[photo.media_attachable_id]
//         ? mediaList[photo.media_attachable_id]
//         : [];
//       mediaList[photo.media_attachable_id] = [photo, ...photoList];
//     }
//   });

//   return mediaList;
// };
export const mediaAnimalList = (action, list) => {
  const mediaList = { ...list };
  const newMediaList = {};

  action.forEach((photo) => {
    if (
      !mediaList[photo.media_attachable_id] ||
      !mediaList[photo.media_attachable_id].length ||
      !mediaList[photo.media_attachable_id].find(
        (item) => item.id === photo.id
      )
    ) {
      const photoList = mediaList[photo.media_attachable_id]
        ? mediaList[photo.media_attachable_id]
        : [];
      mediaList[photo.media_attachable_id] = [photo, ...photoList];

      // Add to newMediaList
      if (!newMediaList[photo.media_attachable_id]) {
        newMediaList[photo.media_attachable_id] = [];
      }
      newMediaList[photo.media_attachable_id].push(photo);
    }
  });

  return newMediaList;
};

export const sortPostmortem = (list) => {
  const sortedPM = list.sort((a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  });

  return sortedPM;
};

export const searchPostmortems = (params) => {
  const searchPM = {
    // "filter[veterinary_records.completed_by_id]": params.vet,
  };

  if (params.vet !== '') {
    searchPM['filter[veterinary_records.completed_by_id]'] = params.vet;
  }

  if (params.rfid !== '') {
    searchPM['filter[veterinary_record_animals.rfid]'] = params.rfid;
  }

  if (params.tag !== '') {
    searchPM['filter[veterinary_record_animals.tag]'] = params.tag;
  }

  if (params.customer !== '') {
    searchPM['filter[veterinary_records.customer_id]'] = params.customer;
  }

  if (params.status === 'draft') {
    searchPM['filter[veterinary_records.draft]'] = 1;
  }

  if (params.status === 'pending') {
    searchPM['filter[veterinary_records.draft]']= 0;
    searchPM['filter[veterinary_records.scheduled_at:isnull]'] = true;
    searchPM['filter[veterinary_records.completed_at:isnull]'] = true;
  }

  if (params.status === 'scheduled') {
    searchPM['filter[veterinary_records.scheduled_at:isnull]'] = false;
    searchPM['filter[veterinary_records.completed_at:isnull]'] = true;
  }

  if (params.status === 'completed') {
    searchPM['filter[veterinary_records.completed_at:isnull]'] = false;
  }

  if (params.completedDate !== '') {
    searchPM[`filter[veterinary_records.completed_at:gt]`] = `${params.completedDate} 00:00:00`;
    searchPM[`filter[veterinary_records.completed_at:lt]`] = `${params.completedDate} 23:59:59`;
  }

  if (params.receivedDate !== '') {
    searchPM[`filter[veterinary_records.record_at:gt]`] = `${params.receivedDate} 00:00:00`;
    searchPM[`filter[veterinary_records.record_at:lt]`] = `${params.receivedDate} 23:59:59`;
  }

  if (params.ticket) {
    searchPM['filter[veterinary_records.pin]'] = params.ticket;
  }

  if (params.site) {
    searchPM['filter[veterinary_records.site_id]'] = params.site;
  }

  if (params.lot) {
    searchPM['filter[veterinary_record_animals.lot]'] = params.lot;
  }

  if (params.page) {
    searchPM['page'] = params.page;
  }

  // set the default sorting to be by created date
  searchPM['sort'] = "-veterinary_records.created_at";

  let query = new URLSearchParams();
  Object.keys(searchPM).map((key) => {
    query.set(key, searchPM[key]);
    return null;
  });

  return query.toString();
};
