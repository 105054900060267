// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ADD_MEDIA_REQUEST: "ADD_MEDIA_REQUEST",
    ADD_MEDIA_SUCCESS: "ADD_MEDIA_SUCCESS",
    ADD_MEDIA_FAILURE: "ADD_MEDIA_FAILURE",
    ADD_SINGLE_MEDIA_REQUEST: "ADD_SINGLE_MEDIA_REQUEST",
    ADD_SINGLE_MEDIA_SUCCESS: "ADD_SINGLE_MEDIA_SUCCESS",
    ADD_SINGLE_MEDIA_FAILURE: "ADD_SINGLE_MEDIA_FAILURE",
    GET_MEDIA_LIST_REQUEST: "GET_MEDIA_LIST_REQUEST",
    GET_MEDIA_LIST_SUCCESS: "GET_MEDIA_LIST_SUCCESS",
    GET_MEDIA_LIST_FAILURE: "GET_MEDIA_LIST_FAILURE",
    GET_MEDIA_REQUEST: "GET_MEDIA_REQUEST",
    GET_MEDIA_SUCCESS: "GET_MEDIA_SUCCESS",
    GET_MEDIA_FAILURE: "GET_MEDIA_FAILURE",
    DELETE_MEDIA_REQUEST: "DELETE_MEDIA_REQUEST",
    DELETE_MEDIA_SUCCESS: "DELETE_MEDIA_SUCCESS",
    DELETE_MEDIA_FAILURE: "DELETE_MEDIA_FAILURE",
}
