import moment from "moment";
import types from "./postmortemTypes";

export const defaultPostmortem = (customer_id = null) => ({
  currentPostmortem: {
    id: null,
    created_by_id: localStorage.uuid,
    created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
    requested_at: moment().format("MM/DD/YYYY"),
    site_id: null,
    customer_id: customer_id,
    customer: {},
    title: "Postmortem",
    description: "Postmortem",
    requested_by_id: localStorage.uuid,
    animals: {},
    draft: 1,
    no_vet_visit_requested: 0,
  },
  list: [],
  pagination:{},
  loading: true,
  report: null,
});

export const defaultVetRecord = () => ({
  scheduled_for: {
    first_name: "Unknown",
    last_name: "Vet",
  },
});

export const getPostmortems = (params) => ({
  type: types.GET_POSTMORTEMS_REQUEST,
  payload: {
    ...params,
  },
});

export const addPostmortems = (postmortem) => {
  return {
    type: types.ADD_POSTMORTEM_REQUEST,
    payload: { postmortem },
  };
};

export const getPostmortem = (id) => ({
  type: types.GET_POSTMORTEM_REQUEST,
  payload: { id },
});

export const updatePostmortem = (postmortem) => ({
  type: types.UPDATE_POSTMORTEM_REQUEST,
  payload: { postmortem },
});

export const scheduledOrCompletePostmortem = (postmortem) =>{ 
  return({
  type: types.SCHEDULED_OR_COMPLETE_POSTMORTEM_REQUEST,
  payload: { postmortem },
})};

export const deletePostmortem = (id) => ({
  type: types.DELETE_POSTMORTEM_REQUEST,
  payload: { id },
});

export const generateReport = (id) => ({
  type: types.POSTMORTEM_REPORT_REQUEST,
  payload: { id },
});

export const resetReport = () => ({
  type: 'RESET_REPORT'
});
export const getMorePostmortemData =(data)=>({
  type: types.GET_MORE_POSTMORTEMS_REQUEST,
  payload:  data ,
})

export const resetList = () => ({
  type: types.RESET_LIST_STORE
});
