import types from "./customerTypes";

const initialState = {
  list: {},
  loading: false,
  pagination: {},
  xyz: [],
  // Add pagination property
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  let stateArray = [];
  switch (action.type) {
    case types.GET_CUSTOMERS_REQUEST:
    case types.GET_CUSTOMER_REQUEST:
    case types.GET_CUSTOMER_SITES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CUSTOMERS_FAILURE:
    case types.GET_CUSTOMER_FAILURE:
    case types.GET_CUSTOMER_SITES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_CUSTOMERS_SUCCESS:
      stateArray = [];
      if (Object.values(state.list).length > 0) {
        const uniqueCustomers = action.payload.data.customers.filter(customer => {
          return !state.list.some(item => item.id === customer.id);
        });

        stateArray = [...state.list, ...uniqueCustomers];
      }else {
        stateArray = [...action.payload.data.customers];
      }
      return {
        ...state,
        list: [...stateArray],
        pagination: { ...action.payload.data.pagination },
        // Update pagination
        loading: false,
      };

    case types.GET_CUSTOMER_SITES_SUCCESS:
      return {
        ...state,
        sites: { ...action.payload },
        loading: false,
      };
    case types.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        sites: state.sites,
        loading: false,
      };
    case types.GET_NEXT_CUSTOMERS_SUCCESS:
      stateArray = [];
      if (Object.values(state.list).length > 0) {
        const uniqueCustomers = action.payload.data.customers.filter(customer => {
          return !state.list.some(item => item.id === customer.id);
        });

        stateArray = [...state.list, ...uniqueCustomers];
      } else {
        stateArray = [...action.payload.data.customers];
      }
      return {
        ...state,
        list: [...stateArray],
        pagination: { ...action.payload.data.pagination },
        // Update pagination
        loading: false,
      }


    default:
      return state;
  }
};
