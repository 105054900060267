import { voidStorage } from "./void.storage"

export class TokenStorage {
  constructor(name, storage) {
    this.name = name
    this.storage = storage
  }

  async removeItem() {
    this.storage.removeItem(this.name)
  }

  async getItem() {


    const token = localStorage.getItem("token");
    const roles = localStorage.getItem("roles");
    const refresh = localStorage.getItem("refresh");
    const uuid = localStorage.getItem("uuid");

    const item = { token, roles, refresh, uuid }

    console.log('[TOKEN STORAGE] GET ITEM')
    return item;

    // const item = this.storage.getItem(this.name)
    // console.log('[TOKEN STORAGE] GET ITEM', item)

    // if (!item) {
    //   return null
    // }

    // return JSON.parse(item)
  }

  async setItem(auth) {
    console.log('[TOKEN STORAGE] SET ITEM')
    if (typeof auth !== "object") {
      throw Error('Auth token must me an object. Cannot store token')
    }

    localStorage.setItem("token", auth.token);
    localStorage.setItem("id", auth.id || '');
    localStorage.setItem("roles", JSON.stringify(auth.roles));
    localStorage.setItem("refresh", auth.refresh);
    localStorage.setItem("uuid", auth.uuid);

    // let s = ""
    // if (typeof auth === "object") {
    //   s = JSON.stringify(auth)
    // } else {
    //   s = `${auth}`
    // }
    // this.storage.setItem(this.name, s)
  }

  getStorage() {
    return this.storage
  }
}

const version = "0"

export const tokenStorage = new TokenStorage(
  `__AUTH_ACCESS_TOKEN_${version}__`,
  typeof window === "undefined"
    ? voidStorage
    : window.localStorage || voidStorage
)
