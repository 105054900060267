import types from "./mediaTypes";

import { mediaAnimalList } from '../../../src/utils/helpers';

const initialState = {
  list: {},
  currentMediaId: '',
  animalId: '',
  loading: false,
  pagination: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  // let obj = null;
  switch (action.type) {
    case types.ADD_SINGLE_MEDIA_REQUEST:
    case types.GET_MEDIA_LIST_REQUEST:
    case types.GET_MEDIA_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_MEDIA_REQUEST:
      return {
        ...state,
        loading: false,
        currentMediaId: action.payload.id,
        animalId: action.payload.animalId
      };
    case types.ADD_SINGLE_MEDIA_SUCCESS:
    case types.GET_MEDIA_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        loading: true,
        list: {
          ...state.list,
          [state.animalId]: state.list[state.animalId].filter(
            (item) => state.currentMediaId !== item.id
          ),
        },
      };
    case types.GET_MEDIA_LIST_SUCCESS:{
      return {
        ...state,
        loading: true,
        list: mediaAnimalList(action.payload, state.list),
        pagination: action?.metaData?.pagination || {},
      };
    }

    case types.ADD_SINGLE_MEDIA_FAILURE:
    case types.GET_MEDIA_LIST_FAILURE:
    case types.GET_MEDIA_FAILURE:
    case types.DELETE_MEDIA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
