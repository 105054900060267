import React, { Component } from 'react';
import { Modal as BSModal, Button } from 'react-bootstrap';

export class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = Modal.defaultState();
    }

    static defaultState() {
        return {
            title: '',
            description: '',
            visible: false,
            animation: false,
            backdrop: 'static',
            action: () => { },
            confirmBtnVisible: true
          }
    }

    render() {

        return (
            <BSModal
                show={this.props.visible}
                onHide={() => {this.props.toggle()}}
                size="lg"
                backdrop={this.props.backdrop}
                animation={this.props.animation}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
            <BSModal.Header closeButton>
                <BSModal.Title id="contained-modal-title-vcenter" dangerouslySetInnerHTML={{__html: this.props.title}}>
                </BSModal.Title>
            </BSModal.Header>
            <BSModal.Body>
                {this.props.description}
                {this.props.children}
            </BSModal.Body>
            <BSModal.Footer>
                <Button variant="secondary" onClick={() => {
                    if (this.props.closeAction) this.props.closeAction();
                    this.props.toggle()}}>Close</Button>
                <Button
                  style={{ display: this.props.confirmBtnVisible ? 'block' : 'none' }}
                  className="btn btn-success"
                  onClick={() => { if (this.props.confirmAction()) this.props.toggle(); }}
                >
                  Confirm
                </Button>
            </BSModal.Footer>
        </BSModal>
        )
    }
}
