import React from 'react';

import AppFrame from '../frames/AppFrame';

import DashboardRouter from '../routers/DashboardRouter/DashboardRouter';
import PostMortemRouter from '../routers/PostMortemRouter/PostMortemRouter';
import VetRouter from '../routers/VetRouter/VetRouter';
import TrainingRouter from '../routers/TrainingRouter/TrainingRouter';
import PasswordChangeRouter from '../routers/PasswordChangeRouter';
import ProfileRouter from '../routers/ProfileRouter';
import InviteUserRouter from '../routers/InviteUserRouter';
import SearchRouter from '../routers/SearchRouter/SearchRouter';

class AuthenticatedView extends React.Component {
  render() {
    return (
      <AppFrame>
        <ProfileRouter />
        <DashboardRouter/>
        {/* NEW */}
        <PostMortemRouter/>
        <VetRouter />
        <TrainingRouter />
        <InviteUserRouter />
        <PasswordChangeRouter />
        <SearchRouter />
      </AppFrame>
    );
  }
}

export default AuthenticatedView;
