import customerTypes from "./customerTypes";

export const getCustomers = () => ({
    type: customerTypes.GET_CUSTOMERS_REQUEST,
    payload: {}
});
export const getCustomer = (id) => ({
    type: customerTypes.GET_CUSTOMER_REQUEST,
    payload: {id}
});
export const getCustomerSites = (userId) => ({
    type: customerTypes.GET_CUSTOMER_SITES_REQUEST,
    payload: {userId}
});
export const getAllData =(url)=>({
    type :customerTypes.GET_NEXT_CUSTOMERS_REQUEST,
    payload :{url}
})

export const defaultUser = () => ({
    "id": undefined,
    "pin": undefined,
    "email": "un@kno.wn",
    "first_name": "Unknown",
    "last_name": "User",
    "phone": "12345",
    "city": null,
    "region": null,
    "country": null,
    "email_verified_at": null,
    "remember_token": null,
    "created_at": "2021-04-17T21:38:03.000000Z",
    "updated_at": "2021-05-25T01:25:45.000000Z",
    "deleted_at": null,
    "customers": [],
    "list": {},
    loading: true
});
