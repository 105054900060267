import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import Datetime from "react-datetime";
import moment from "moment";

import history from "../../../utils/history";

// import { faSearch, faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import { Form } from "react-bootstrap";
import MUTooltip from "@material-ui/core/Tooltip";

import { getUsers } from "../../../store/user";
import { getCustomers, getAllData } from "../../../store/customers";
import { searchFilterValue } from "../../../store/search";
// import { searchGetPostmortems } from '../../../store/search';

import CustomDropDown from "../customDropDown";
import Icon from "../Icon/Icon";
import PageWrapper from "../PageWrapper/PageWrapper";

const statusList = [
  {
    id: "",
    label: "-- Choose Status --",
  },
  {
    id: "completed",
    label: "Completed",
  },
  {
    id: "draft",
    label: "Draft",
  },
  {
    id: "pending",
    label: "Pending",
  },
  {
    id: "scheduled",
    label: "Scheduled",
  },

];

const RecordSearch = () => {
  const userId = useSelector((state) => state.user.id);
  const vetNames = useSelector((state) => state.user.list);
  const customers = useSelector((state) => state.customer.list);
  const filterValues = useSelector((state) => state.search.filterValues);
  const pagination = useSelector((state) => state.customer.pagination);

  const [siteOptionList, setSiteOptionList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!vetNames || !Object.keys(vetNames).length || !vetNames.length || "") {
      console.log('vetNamesvetNames==>',vetNames);
      dispatch(getUsers({ "filter[roles.name]": "Veterinarian,Admin" }));
    }

    if (
      !customers ||
      !Object.keys(customers).length ||
      !customers.length ||
      ""
    ) {
      dispatch(getCustomers());
    }

    // if (userId) {
    //   onFilterChange(userId, "vet");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = useSelector((state) => {
    let hasLoad = false;
    Object.entries(state.loading).forEach(([key, val]) => {
      if (val) {
        hasLoad = true;
      }
    });

    return hasLoad;
  });

  const selectedVet =
    userId &&
    Object.values(vetNames).find((vet) => vet.id === filterValues.vet);
  const vetOptionsList = [];
  vetOptionsList.push({
    id: "",
    label: "-- Choose a Vet --",
  });
  if (selectedVet !== null) {
    Object.values(vetNames).forEach((vet) =>{
      return(
        vetOptionsList.push({
          id: vet.id,
          label: vet.first_name && vet.last_name ? `${vet.first_name} ${vet.last_name}${vet.id === userId ? " (me)" : "" } ${vet.roles.includes('Admin') ?  "(Admin)" : ''} ` : "Unknown name",
        })
      )
    }
    );
  }
  vetOptionsList.sort((a, b) => a.label.localeCompare(b.label));

  const selectedVetForDropdown = selectedVet
    ? {
        id: selectedVet.id,
        label:
          selectedVet.first_name && selectedVet.last_name ? selectedVet.first_name +  " " +  selectedVet.last_name + `${selectedVet.id === userId ? " (me)" : ""}` : "Unknown name",
      }
    : null;

  const customerOptionList = [];
  customerOptionList.push({
    id: "",
    label: "-- Choose a Client --",
  });
  if (customers && Object.values(customers).length) {
    Object.values(customers).map((customer) => {
      customerOptionList.push({
        id: customer.id,
        label: customer.name,
      });
      return null;
    });
  }
  customerOptionList.sort((a, b) => a.label.localeCompare(b.label));

  const loadMoreCallback = async (props) => {
    if (pagination?.next_page_url) {
      dispatch(getAllData(pagination));
    } else {
    }
  };

  const selectedCustomer = Object.values(customers).find(
    (customer) => customer.id === filterValues.customer
  );
  const selectedCustomerForDropdown = selectedCustomer
    ? {
        id: selectedCustomer.id,
        label: selectedCustomer.name ? selectedCustomer.name : "",
      }
    : null;

  useEffect(() => {
    if (!selectedCustomer) {
      setSiteOptionList([]);
      onFilterChange("", "site");
      return;
    }
    // Set the sites from the customer
    const sites = selectedCustomer.sites;
    const mappedSites = [];
    mappedSites.push({
      id: "",
      label: "-- Choose a Site --",
    });

    sites.map((site) =>
      mappedSites.push({
        id: site.id,
        label: site.name,
      })
    );

    setSiteOptionList(mappedSites);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  const selectedSite = siteOptionList.find(
    (val) => val.id === filterValues.site
  );

  const statusOptionsList = statusList.map((status) => ({
    id: status.id,
    label: status.label,
  }));
  const selectedStatus = statusList.find(
    (val) => val.id === filterValues.status
  );
  const selectedStatusForDropdown = selectedStatus
    ? {
        id: selectedStatus.id,
        label: selectedStatus.label ? selectedStatus.label : "",
      }
    : null;

  const onFilterChange = (e, name) => {
    const event = e && e.target ? e.target.value : e;
    dispatch(searchFilterValue(name, event));
  };

  const resultSearch = () => {
    // dispatch(searchGetPostmortems({filterValues}));
    history.push("/a/search/result");
  };
  return (
    <div>
      {loading ? (
        <PageWrapper
          title="RECORD SEARCH"
          type="all"
          // icon={faSearch}
          loading={loading}
        />
      ) : (
        <div>
          {/* <PageWrapper title="RECORD SEARCH" type="all" icon={faSearch} /> */}
          <p className="px-4 text-white text-sm">
            Search for a record. Search terms will combine to provide an
            advanced search. Leave boxes blank to exclude them from your search.
          </p>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              Ticket #
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Ticket Number"
              defaultValue={""}
              onChange={(e) => onFilterChange(e, "ticket")}
              className="text-2xl text-med rounded"
            />
          </div>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              RFID#
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="RFID"
              defaultValue={filterValues.rfid}
              onChange={(e) => onFilterChange(e, "rfid")}
              className="text-2xl text-med rounded"
            />
          </div>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              TAG #
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="TAG"
              defaultValue={filterValues.tag}
              onChange={(e) => onFilterChange(e, "tag")}
              className="text-2xl text-med rounded"
            />
          </div>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              LOT
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="LOT"
              defaultValue={filterValues.lot}
              onChange={(e) => onFilterChange(e, "lot")}
              className="text-2xl text-left w-100 mb-1"
            />
          </div>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              SUBMISSION DATE
            </Form.Label>
            <Datetime
              initialValue={
                filterValues.receivedDate
                  ? moment(filterValues.receivedDate).format("MM/DD/YYYY")
                  : filterValues.receivedDate
              }
              timeFormat={false}
              inputProps={{
                className: "text-2xl form-control rounded",
                readOnly: false,
                placeholder: "Select a date",
              }}
              onChange={(e) => {
                if (e instanceof moment) {
                  if (!e.isValid()) {
                    onFilterChange("", "receivedDate");
                  } else {
                    onFilterChange(e.format("YYYY-MM-DD"), "receivedDate");
                  }
                } else {
                  onFilterChange("", "receivedDate");
                }
              }}
            />
          </div>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              COMPLETED DATE
            </Form.Label>
            <Datetime
              initialValue={
                filterValues.completedDate
                  ? moment(filterValues.completedDate).format("MM/DD/YYYY")
                  : filterValues.completedDate
              }
              timeFormat={false}
              inputProps={{
                className: "text-2xl form-control rounded",
                readOnly: false,
                placeholder: "Select a date",
              }}
              onChange={(e) => {
                if (e instanceof moment) {
                  if (!e.isValid()) {
                    onFilterChange("", "completedDate");
                  } else {
                    onFilterChange(e.format("YYYY-MM-DD"), "completedDate");
                  }
                } else {
                  onFilterChange("", "completedDate");
                }
              }}
            />
          </div>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              VET
            </Form.Label>
            <CustomDropDown
              optionsList={vetOptionsList}
              itemText="There are no vets"
              selectedOption={selectedVetForDropdown}
              placeholderTitle="Please choose a vet"
              onSelect={(e) => onFilterChange(e, "vet")}
              theme="dark"
              customMenu={false}
              menuCanOpen={true}
            />
          </div>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              CLIENT
            </Form.Label>
            <CustomDropDown
              optionsList={customerOptionList}
              itemText="There are no customers"
              selectedOption={selectedCustomerForDropdown}
              placeholderTitle="Please choose a customer"
              onSelect={(e) => onFilterChange(e, "customer")}
              theme="dark"
              customMenu={false}
              menuCanOpen={true}
              loadMoreCallback={loadMoreCallback}
              showLoadmoreButton={true}
              buttontext={
                pagination?.next_page_url ? "LOAD More" : ""
              }
            />
          </div>
          <>
            {siteOptionList.length ? (
              <div className="px-6 mb-3">
                <Form.Label className="text-white text-left w-100 mb-1">
                  SITE
                </Form.Label>
                <CustomDropDown
                  optionsList={siteOptionList}
                  itemText="There are no sites"
                  selectedOption={selectedSite}
                  onSelect={(e) => onFilterChange(e, "site")}
                  placeholderTitle="Please choose a site"
                  theme="dark"
                  customMenu={false}
                  menuCanOpen={true}
                />
              </div>
            ) : (
              <></>
            )}
          </>
          <div className="px-6 mb-3">
            <Form.Label className="text-white text-left w-100 mb-1">
              STATUS
            </Form.Label>
            <CustomDropDown
              optionsList={statusOptionsList}
              itemText="There are no status"
              selectedOption={selectedStatusForDropdown}
              placeholderTitle="Please choose a status"
              onSelect={(e) => onFilterChange(e, "status")}
              theme="dark"
              customMenu={false}
              menuCanOpen={true}
            />
          </div>
          <div className="mt-20 block flex">
            <div className="flex-1">
              <MUTooltip
                title="Back"
                enterDelay={500}
                leaveDelay={200}
                placement="top"
                classes={{
                  tooltip: "text-base ",
                }}
              >
                <Icon
                  // icon={faTimes}
                  size="45"
                  style={{ display: "block" }}
                  className="m-auto cursor-pointer"
                  onClick={() => history.goBack()}
                />
              </MUTooltip>
            </div>
            <div className="flex-1">
              <MUTooltip
                title="Go to adding an animal"
                enterDelay={500}
                leaveDelay={200}
                placement="top"
                classes={{
                  tooltip: "text-base ",
                }}
              >
                <Icon
                  // icon={faArrowRight}
                  size="45"
                  style={{ display: "block" }}
                  className="m-auto cursor-pointer"
                  onClick={() => resultSearch()}
                />
              </MUTooltip>
              <p className="mt-6 block text-white text-center px-2">SEARCH</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordSearch;
