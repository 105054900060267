import types from "./postmortemTypes";
import { put } from "redux-saga/effects";
import callApi from "../../utils/callApi";
import getSiteEndpoint from "../../utils/getSiteEndpoint";
import history from "../../utils/history";

export function* addPostmortems(action) {
  const { ...postmortem } = action.payload.postmortem;
  if (!postmortem) yield put({ type: types.ADD_POSTMORTEM_FAILURE });

  if (postmortem.animals) postmortem.animals = undefined;

  yield callApi({
    method: "post",
    url: getSiteEndpoint() + "/post-mortems",
    type: "ADD_POSTMORTEM",
    useFullResponse: false,
    dataProp: "post_mortem",
    body: postmortem,
    errorMessage: "There was an error while adding an postmortem.",
    onSuccess: (data) => {
      //history.push(`/a/postmortem/${data.id}?new`);
    },
  });
}

export function* getPostmortems(params) {
  let defaultParams = { sort: "-created_at" };

  if (params) {
    params = params.payload || {};
  } else {
    params = {};
  }
  params = {
    ...defaultParams,
    ...params,
  };

  let query = new URLSearchParams();
  Object.keys(params).map((key) => {
    query.set(key, params[key]);
    return null;
  });




  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/post-mortems?" + query.toString(),
    type: "GET_POSTMORTEMS",
    dataProp: "post_mortems",
    useFullResponse: false,
    errorMessage: "There was an error while getting post mortems.",
    transformSuccessPayload: (data) => {
      return [...new Map(data.map((item) => [item.id, item])).values()];
    },
  });
}


export function* getAllPostmortemsData(url) {
  console.log('call api--->', url.payload);
  const httpsUrl = url.payload.replace("http://", "https://");
  yield callApi({
    method: "get",
    url: httpsUrl,
    type: "GET_MORE_POSTMORTEMS",
    dataProp: "post_mortems",
    useFullResponse: false,
    errorMessage: "There was an error while getting post mortems.",
    transformSuccessPayload: (data) => {
      return [...new Map(data.map((item) => [item.id, item])).values()];
    },
  });
}

export function* getPostmortem(action) {
  const id = action.payload.id;
  if (!id) yield put({ type: types.GET_POSTMORTEM_FAILURE });
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/post-mortems/" + id,
    type: "GET_POSTMORTEM",
    dataProp: "post_mortem",
    useFullResponse: false,
    errorMessage: "There was an error while getting post mortem.",
  });
}

export function* updatePostmortem(action) {
  const pm = action.payload.postmortem;
  if (!pm) yield put({ type: types.UPDATE_POSTMORTEM_FAILURE });

  const { redirect, ...body } = pm || {};

  yield callApi({
    method: "put",
    url: `${getSiteEndpoint()}/post-mortems/${pm.id}`,
    type: "UPDATE_POSTMORTEM",
    useFullResponse: false,
    dataProp: "post_mortem",
    body,
    errorMessage: "There was an error while updating an post mortem.",
  });

  if (redirect) {
    history.push(redirect);
  }

}

export function* scheduledOrCompletePostmortem(action) {
  const pm = action.payload.postmortem;
  const isComplete = pm.completed_at;

  if (!pm) yield put({ type: types.SCHEDULED_OR_COMPLETE_POSTMORTEM_FAILURE });

  let actionName = isComplete ? "completed" : "scheduled";

  if (!isComplete && pm.scheduled_for_id === "") {
    actionName = "unnassigned";
  }

  yield callApi({
    method: "put",
    url: `${getSiteEndpoint()}/post-mortems/${pm.id}`,
    type: "SCHEDULED_OR_COMPLETE_POSTMORTEM",
    useFullResponse: false,
    dataProp: "post_mortem",
    body: pm,
    successMessage: `The post mortem was successfully ${actionName}.`,
    errorMessage: `There was an error ${actionName} post mortem.`,
  });

  if (actionName === "completed") {
    history.push(`/a/vet/ticketdetail/${pm.id}/report`);
  }
}

export function* deletePostmortem(action) {
  const id = action.payload.id;
  if (!id) yield put({ type: types.DELETE_POSTMORTEM_FAILURE });
  yield callApi({
    method: "DELETE",
    url: `${getSiteEndpoint()}/post-mortems/${id}`,
    type: "DELETE_POSTMORTEM",
    useFullResponse: false,
    dataProp: "post_mortem",
    body: id,
    errorMessage: "There was an error while deleting an post mortem.",
  });
}

export function* postmortemReport(action) {
  const id = action.payload.id;
  if (!id) yield put({ type: types.POSTMORTEM_REPORT_FAILURE });
  yield callApi({
    method: "GET",
    url: `${getSiteEndpoint()}/post-mortems/${id}/report`,
    type: "POSTMORTEM_REPORT",
    useFullResponse: false,
    dataProps: "report",
    body: id,
    errorMessage: "There was an error while generating the report.",
  });
}
