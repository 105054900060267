import { faSkullCow } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as md5 from 'md5';
import moment from 'moment';
import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { Modal } from "../../../components/common/Modal/Modal.js";
import { defaultPostmortem } from '../../../store/postmortem';
import history from "../../../utils/history";

import './PostMortemRow.css';

import MUTooltip from "@material-ui/core/Tooltip";

export default class PostMortemRow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...props,
      postmortem: props.postmortem || defaultPostmortem(),
      action: null,
      toggleDropdown: false,
      modal: Modal.defaultState(),
      pm_scheduled_at: props.postmortem.veterinary_record.scheduled_at || new moment().format("MM/DD/YYYY"),
      tab:props.tab
    };
  }


  componentDidUpdate() {
    if (md5(JSON.stringify(this.state.postmortem)) !== md5(JSON.stringify(this.props.postmortem))) {
      this.setState({
        ...this.state,
        postmortem: this.props.postmortem
      });
    }
  }

  /* ========================================================================================================================
                                                    HELPERS
========================================================================================================================*/

  getID() {
    const { postmortem } = this.state;

    if(!postmortem.id) {
      return 'NONE';
    }

    const numbers = String(this.state.postmortem.id).match(/\d+/g);

    if (numbers) {
      return numbers.join('').substring(0, 10);
    }

    return 99999;
  }

  actionAllowed(action) {
    return this.props.actions && this.props.actions.hasOwnProperty(action);
  }

  getCustomerSiteFormatted() {
    const customer = this.props.postmortem.veterinary_record.customer;
    const site = this.props.postmortem.veterinary_record.site;

    return `${customer ? customer.name : ''} - ${site ? site.name : ''}`; 
  }

  /* ========================================================================================================================
                                                    ACTIONS
========================================================================================================================*/

viewOnClick() {
  history.push(`/a/postmortem/view/${this.state.postmortem.id}`)

}

reportClick(event) {
  const vetRecord = this.state.postmortem.veterinary_record;

  event.stopPropagation();

  history.push(`/a/postmortem/${vetRecord.id}/report`);
}

scheduleClick(event) {
  const vetRecord = this.state.postmortem.veterinary_record;

  event.stopPropagation();

  this.setState({
    ...this.state,
    action: 'schedule',
    modal: {
      ...this.state.modal,
      visible: true,
      title: `Schedule #${vetRecord.pin}`,
      description: '',
      body: 'schedule'
    }
  });
}

deleteOnClick(event) {
  event.stopPropagation();
  this.setState({
    ...this.state,
    action: 'delete',
    modal: {
      ...this.state.modal,
      visible: true,
      title: "Delete Post Mortem?",
      description: "Are you sure you want to delete this post mortem?",
      body: ''
    }
  });
}

confirmDeleteAction() {
  this.props.actions.delete();
}

toggleModalAction() {
  this.setState({
    modal: { ...this.state.modal, visible: !this.state.modal.visible }
  });
}

runAction() {
  if (this.state.action != null
    && typeof this.props.actions[this.state.action] != 'undefined'
    && this.props.actions[this.state.action] !== ""
    && this.props.actions[this.state.action] != null) {

    if (this.state.action === 'schedule') {
      this.props.actions[this.state.action]({
        id: this.state.postmortem.id,
        scheduled_at: this.state.pm_scheduled_at,
      });
    } else
      this.props.actions[this.state.action]();

    this.setState({action: null});
    this.toggleModalAction();
  } else {
    console.error(`Action '${this.state.action}' does not exist`);
  }
}
getVetName() {
  let vetRecord =this.state.postmortem.veterinary_record  || { scheduled_for: { first_name: "Unknown", last_name: "Name" } };
  let firstName = vetRecord.completed_by.first_name;
  let lastName = vetRecord.completed_by.last_name;

  return <>{firstName || ''} {lastName || ''}</>
}


/* ========================================================================================================================
                                                    MAIN RENDER
========================================================================================================================*/

  render() {
    const vetRecord = this.state.postmortem.veterinary_record || { veterinary_record_animals: {} };
    const vetAnimals = vetRecord.veterinary_record_animals || {};
    const numAnimals = Object.keys(vetAnimals).length;
    const { scheduled_for, completed_by } = vetRecord;
    // const scheduledVetNameInitials = scheduled_for && scheduled_for.first_name && scheduled_for.last_name
    //   ? `${scheduled_for.first_name[0]}${scheduled_for.last_name[0]}`
    //   : 'N/A';
    // const complitedVetNameInitials = completed_by && completed_by.first_name && completed_by.last_name
    //   ? `${completed_by.first_name[0]}${completed_by.last_name[0]}`
    //   : 'N/A';
    const whiteCircleClasses = 'bg-white rounded-circle text-black flex justify-content-center align-items-center';
    // draft
    // pending
  

    return (
      <div
        onClick={() => {if(this.actionAllowed('view')) this.viewOnClick();}}
        className="flex bg-blu mb-4 p-3 border border-white rounded-sm active:border border-gre active:text-gre block text-tw-white cursor-pointer"
      >
        <div className="flex-1 flex flex-column flex-fill">
          <h5 className="mb-0">
            #{vetRecord.pin}
          </h5>
          <span>
            { this.getCustomerSiteFormatted() }
          </span>
          <span className="mt-2 min-w-36 block flex-initial text-sm">
            {(vetRecord.created_at && !vetRecord.scheduled_at && !vetRecord.completed_at  &&
              <span>
                {`Submitted: ${(new moment(vetRecord.created_at)).format('MMMM Do YYYY')}`}
              </span>
            )}

            {(vetRecord.scheduled_at && !vetRecord.completed_at  &&
              <span>
                {`Scheduled: ${(moment.parseZone(vetRecord.scheduled_at).local(true)).format('MMMM Do YYYY')}`}
              </span>
            )}

            {(vetRecord.completed_at &&
              <span>
                {`Submitted: ${(moment.parseZone(vetRecord.created_at).local(true)).format('MMMM Do YYYY')}`}
              </span>
            )}
          </span>
          {(vetRecord.created_by &&
            <h6 className="mt-1 overflow-ellipsis overflow-hidden min-w-0  block flex-1 flex-shrink">
               submitted by: {`${vetRecord.created_by.first_name} ${vetRecord.created_by.last_name}`}
            </h6>
          )}
          <span className="mt-2">
            <FontAwesomeIcon icon={faSkullCow} />  {numAnimals} records 
          </span>
        </div>

        <div className="flex actions-container">
          {/* {this.actionAllowed('report') && (
            <MUTooltip
              title="Generate Report"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <span
                className={`${whiteCircleClasses} mb-2 ml-1`}
                style={{
                  fontSize: '1.2rem',
                  height: '2.5rem',
                  width: '2.5rem'
                }}
                onClick={this.reportClick.bind(this)}
              >
                <i 
                  className="fas fa-file text-black"
                  style={{ fontSize: '1.2rem' }}
                />
              </span>
            </MUTooltip>
          )} */}

          {this.actionAllowed('schedule') && (
            <MUTooltip
              title="Schedule"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <span
                className={`${whiteCircleClasses} mb-2 ml-1`}
                style={{
                  fontSize: '1.2rem',
                  height: '2.5rem',
                  width: '2.5rem'
                }}
                onClick={this.scheduleClick.bind(this)}
              >
                <i
                  className="fas fa-calendar-alt text-black"
                  style={{ fontSize: '1.2rem' }}
                />
              </span>
            </MUTooltip>
          )}
          
          {scheduled_for && !completed_by && (
                <span className="float-right">{scheduled_for.first_name} {scheduled_for.last_name}</span>
            // <MUTooltip
            //   id={`${scheduled_for.id}-${new Date().getTime()}`}
            //   title={`Scheduled for: ${scheduled_for.first_name} ${scheduled_for.last_name}`}
            //   enterDelay={500}
            //   leaveDelay={200}
            //   placement="top"
            //   classes={{
            //     tooltip: "text-base ",
            //   }}
            // >
            //   <span
            //     className={`${whiteCircleClasses} mb-2 ml-1`}
            //     style={{
            //       fontSize: '1.2rem',
            //       height: '2.5rem',
            //       width: '2.5rem'
            //     }}
            //   >
            //     {scheduledVetNameInitials}
            //   </span>
            // </MUTooltip>
          )}
          {completed_by && this.state.tab === "complete" && (
             <span className="float-right">{completed_by.first_name} {completed_by.last_name}</span>
            // <MUTooltip
            //   id={`${completed_by.id}-${new Date().getTime()}`}
            //   title={`Completed by: ${completed_by.first_name} ${completed_by.last_name}`}
            //   enterDelay={500}
            //   leaveDelay={200}
            //   placement="top"
            //   classes={{
            //     tooltip: "text-base ",
            //   }}
            // >
            //   <span
            //     className={`${whiteCircleClasses} mb-2 ml-1`}
            //     style={{
            //       fontSize: '1.2rem',
            //       height: '2.5rem',
            //       width: '2.5rem'
            //     }}
            //   >
            //     {/* {complitedVetNameInitials} */}
            //     {completed_by.first_name} {completed_by.last_name}
            //   </span>
            // </MUTooltip>
          )}

          {/* {this.actionAllowed('delete') && (
            <MUTooltip
              title="Delete Post Mortem"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
                <span
                className={`${whiteCircleClasses} mb-2 ml-1`}
                style={{
                  fontSize: '1.2rem',
                  height: '2.5rem',
                  width: '2.5rem'
                }}
                onClick={this.deleteOnClick.bind(this)}
              >
                <i className="fas fa-times text-black" style={{height: "1.2rem"}} />
              </span>
            </MUTooltip>
          )} */}
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <Modal
            {...this.state.modal}
            confirmAction={this.runAction.bind(this)}
            toggle={this.toggleModalAction.bind(this)}
          >
            {this.state.modal.body === "schedule" ? (
              <Datetime
                timeFormat={false}
                inputProps={{
                  style: { color: "#000" },
                  readOnly: true,
                  className: "form-control rounded",
                }}
                value={
                  this.state.pm_scheduled_at
                    ? moment(this.state.pm_scheduled_at).format("MM/DD/YYYY")
                    : new moment().format("MM/DD/YYYY")
                }
                onChange={(e) => {
                  if (e instanceof moment)
                    this.setState({
                      pm_scheduled_at: e.format("YYYY-MM-DD HH:mm:ss"),
                    });
                }}
              />
            ) : null}
          </Modal>
        </div>
      </div>
    )
  }
}
