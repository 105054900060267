import { connect } from "react-redux";
import {
    deletePostmortem,
    updatePostmortem,
} from "../../../store/postmortem";
import PostMortemRow from "../PostMortemRow/PostMortemRow";
import {React} from 'react'

const PostMortemTabRowsSmall = (props) => {

    const deleteAction = (id) => {
        props.deletePostmortem(id);
    }

    const scheduleAction = (postmortem) => {
        props.updatePostmortem(postmortem)
    }

    // Create a sorted version of records based on the condition
    let sortedRecords = Object.values(props.records);
    if (props.tab === "complete") {
        sortedRecords = sortedRecords.filter(record => record.veterinary_record && record.veterinary_record.draft === 0);
        sortedRecords.sort((a, b) => {
            // Add your sorting logic here if needed
            // Example: Sort by a specific field
            return a.veterinary_record.completed_at - b.veterinary_record.completed_at;
        });
    }


    return sortedRecords.map((pm, index) => {
        if (!pm.veterinary_record) {
            return <React.Fragment key={index} />;
        }

        let updatedActions = {
            view: "",
        };

        if (props.tab === "draft" || props.tab === "pending") {
            updatedActions.delete = () => deleteAction(pm.id);
        }

        if (props.tab === "pending" && props.isVet) {
            updatedActions.schedule = () => scheduleAction
        }

        if (props.tab === "complete") {
            updatedActions.report = () => {}
        }

        return (
            <PostMortemRow
                key={index}
                actions={updatedActions}
                postmortem={pm}
                tab={props.tab}
            />
        )
    });

}

const mapStateToProps = (state) => {
    return {};
};
  
const mapDispatchToProps = {
    deletePostmortem,
    updatePostmortem,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostMortemTabRowsSmall);
