import { useCallback, useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getSites } from "../../../store/sites";
import history from "../../../utils/history";
import "./Location.css";

const SiteList = ({ sites, loading, getSites, pagination }) => {
  // const records = useMemo(() => Object.values(sites), [sites]);
  const records = useMemo(() => Object.values(sites), [sites]);

  const isLoading = useMemo(() => loading, [loading]);
  const [page, setPage] = useState(1);

  const perPage = 12;

  const total = useMemo(() => pagination.total ?? 0, [pagination.total]);
  const pageCount = useMemo(
    () => (total ? Math.ceil(total / perPage) : 0),
    [total]
  );

  useEffect(() => {
    getSites({
      per_page: perPage,
      page,
      sort: "customer.name",
    });
  }, [perPage, page, getSites]);

  const gotoSite = useCallback((id) => {
    const roles = localStorage.getItem("roles");
    if (
      roles.includes("Veterinarian") ||
      roles.includes("Admin") ||
      roles.includes("SuperAdmin")
    ) {
      history.push(`/a/location/${id}/inbox`);
    } else {
      history.push(`/a/location/${id}/draft`);
    }
  }, []);
  

  if (!isLoading && !records.length) {
    return <p className="text-white">No sites found</p>;
  }

  return (
    <div className="mb-8">
      {/* <pre>{JSON.stringify(pagination, null, 2)}</pre>
      {pageCount} */}
      {records.map(({ id, customer, name }, i) => (
        <div
          key={`${id}${i}`}
          className="text-white border border-white rounded-sm p-2 pt-3 pl-4 mb-3 cursor-pointer"
          onClick={() => gotoSite(id)}
        >
          <h5>
            <span className="mr-2">{customer ? customer.name : 'N/A'}</span>
            <span>-</span>
            <span className="ml-2">{name}</span>
          </h5>
        </div>
      ))}
      <ReactPaginate
        breakLabel="..."
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination-container"
        pageClassName="pagination-item"
        activeClassName="pagination-item-active"
        previousClassName={`pagination-previous ${
          page === 1 ? "disabled" : ""
        }`}
        nextClassName={`pagination-next ${
          page === pageCount ? "disabled" : ""
        }`}
        pageLinkClassName="pagination-link"
        previousLinkClassName="pagination-link"
        nextLinkClassName="pagination-link"
        pageRangeDisplayed={2}
        onPageChange={(selectedItem) => {
          setPage(selectedItem.selected + 1);
        }}
        previousLabel={
          <span
            style={{
              color: page === 1 ? "#999" : "#fff",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {"<Previous"}
          </span>
        }
        nextLabel={
          <span
            style={{
              color: page === pageCount ? "#999" : "#fff",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {"Next >"}
          </span>
        }
        previousLinkProps={{
          style: { pointerEvents: page === 1 ? "none" : "auto" },
          onClick: page === 1 ? null : () => setPage(page - 1),
        }}
        nextLinkProps={{
          style: { pointerEvents: page === pageCount ? "none" : "auto" },
          onClick: page === pageCount ? null : () => setPage(page + 1),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.site.loading,
    sites: state.site.list,
    pagination: state.site.pagination,
  };
};

const mapDispatchToProps = {
  getSites,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteList);
