import authTypes from "./authTypes";
import { delay, put, retry } from "redux-saga/effects";
import axios from "axios";
import qs from "querystring";
import store from "../rootStore";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../utils/setAuthToken";
import callApi from "../../utils/callApi";

const config = {
  headers: {"Content-Type": "application/x-www-form-urlencoded"},
};

export function* clearError(action) {
  yield delay(5000)
  yield put({ type: authTypes.CLEAR_ERROR, payload: action })
}

export function* login(action) {
  yield callApi({
    method: "post",
    url: process.env.REACT_APP_AUTH_ENDPOINT + "/login",
    type: "LOGIN",
    useFullResponse: true,
    errorMessage: "Login failed.",
    body: action.payload,
    onSuccess: (data) => {
      const {uuid, token, refresh} = data;
      localStorage.setItem("token", token);
      localStorage.setItem("refresh", refresh);
      localStorage.setItem("uuid", uuid);

    },
  });
}

export function* resetRequest(action) {
  yield callApi({
    method: "post",
    url: process.env.REACT_APP_AUTH_ENDPOINT + "/reset-password",
    type: "RESET_PASSWORD",
    useFullResponse: true,
    errorMessage: "Reset request failed.",
    body: action.payload,
    onSuccess: (data) => {
    },
  });
}
export function* verify(action) {
  yield callApi({
    method: "post",
    url: process.env.REACT_APP_AUTH_ENDPOINT + "/confirm-reset-password",
    type: "VERIFY",
    useFullResponse: true,
    errorMessage: "Password verification failed",
    body: action.payload,
    onSuccess: (data) => {},
  });
}

export function* logout() {
  try {
    yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/logout`,
      { token: localStorage.getItem('token') }
    );
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("refresh");
    localStorage.removeItem("uuid");
    store.dispatch({ type: "CLEAR_ALL" });
    yield put({ type: authTypes.LOGOUT });
  } catch (err) {
    console.error("err: " + err.message);
    yield put({ type: authTypes.LOGOUT });
  }
}

export function* refreshToken() {
  let error = null;
  let newAuth = null;
  try {
    setAuthToken();
    const currentAuth = JSON.parse(localStorage.getItem("auth"));
    const body = {token: currentAuth.refresh};
    const res = yield retry(
      10,
      2000,
      axios.post,
      `${process.env.REACT_APP_AUTH_ENDPOINT}/refresh`,
      qs.stringify(body),
      config
    );
    if (res && res.data && res.data.state === "okay") {
      // Store the new tokens
      newAuth = {
        ...currentAuth,
        id: res.data.id,
        token: res.data.token,
        user: jwt_decode(res.data.id),
      };
      localStorage.setItem("auth", JSON.stringify(newAuth));
      yield put({
        type: authTypes.REFRESH_TOKEN,
        payload: newAuth,
      });
    } else {
      error = "Could not authenticate with the server.";
    }
  } catch (err) {
    error = err.message;
  }

  if (error) {
    localStorage.removeItem("auth");
    console.error(error);
    yield put({
      type: authTypes.AUTHENTICATE_FAILURE,
      payload: error,
    });
  }
}

export function* loadUser() {
  const uuid = localStorage.uuid;
  if (!uuid)
    yield put({
      type: authTypes.LOAD_USER_FAILURE,
    });
  else {
    yield callApi({
      method: "get",
      url: process.env.REACT_APP_CRM_ENDPOINT + "/api/v1/users/" + uuid,
      type: "LOAD_USER",
      dataProp: "user",
      errorMessage: "Could not load user.",
    });
  }
}

export function* resetChallenge(action) {
  try {
    const { session, username, password } = action.payload;
    let resetData = null;
    yield callApi({
      method: "post",
      url: `${process.env.REACT_APP_AUTH_ENDPOINT}/challenge`,
      type: "RESET_CHALLENGE",
      useFullResponse: true,
      errorMessage: "Could not update password.",
      body: { session, username, password, ...action.payload.userInfo },
      onSuccess: (data) => {
        resetData = data.state;
      }
    });
    if (resetData) {
      yield put({ type: authTypes.LOGIN_REQUEST, payload: { username, password } });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: authTypes.RESET_CHALLENGE_FAILURE,
      payload: error,
    });
  }}

export function* authenticate() {
  if (!localStorage.token)
    store.dispatch({type: authTypes.AUTHENTICATE_FAILURE});
  else
    try {
      yield callApi({
        method: "post",
        url: `${process.env.REACT_APP_AUTH_ENDPOINT}/refresh`,
        type: "REFRESH",
        body: {token: localStorage.refresh},
        useFullResponse: true,
        errorMessage: "Could not refresh token.",
        onSuccess: (data) => {
          const roles = JSON.stringify(data.roles);
          localStorage.setItem("roles", roles);
          localStorage.setItem("token", data.token);
        },
      });

      // const site = process.env.REACT_APP_SITE_ID;

      //Changed this to just status to simply check to make sure API is still up (was sites endpoint.)
      yield callApi({
        method: "get",
        url: `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/status`,
        type: "AUTHENTICATE",
        dataProp: "site",
        errorMessage: "Could not authenticate.",
      });
    } catch (err) {
      store.dispatch({type: authTypes.AUTHENTICATE_FAILURE});
    }
}
