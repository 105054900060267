import connectionTypes from "./connectionTypes";
import { takeLatest, all } from "redux-saga/effects";
import {
  connect,
  reconnect,
  disconnect,
  pause,
  unpause,
  suspend,
  unsuspend,
  setConfig,
} from "./connectionActions";

function* connectWatcher() {
  yield takeLatest(connectionTypes.CONNECT_ASYNC, connect);
}

function* reconnectWatcher() {
  yield takeLatest(connectionTypes.RECONNECT_ASYNC, reconnect);
}

function* disconnectWatcher() {
  yield takeLatest(connectionTypes.DISCONNECT_ASYNC, disconnect);
}

function* pauseWatcher() {
  yield takeLatest(connectionTypes.PAUSE, pause);
}

function* unpauseWatcher() {
  yield takeLatest(connectionTypes.UNPAUSE, unpause);
}

function* suspendWatcher() {
  yield takeLatest(connectionTypes.SUSPEND, suspend);
}

function* unsuspendWatcher() {
  yield takeLatest(connectionTypes.UNSUSPEND, unsuspend);
}

function* setConfigWatcher() {
  yield takeLatest(connectionTypes.SET_CONFIG_ASYNC, setConfig);
}

export default function* connectionSagas() {
  yield all([
    connectWatcher(),
    reconnectWatcher(),
    disconnectWatcher(),
    pauseWatcher(),
    unpauseWatcher(),
    suspendWatcher(),
    unsuspendWatcher(),
    setConfigWatcher(),
  ]);
}
