import types from './customerTypes';
import { takeLatest, all } from "redux-saga/effects";
import * as actions from './customerActions';


export default function* customerSagas() {

    yield all([
        yield takeLatest(types.GET_CUSTOMERS_REQUEST, actions.getCustomers),
        yield takeLatest(types.GET_NEXT_CUSTOMERS_REQUEST, actions.getAllData),
        yield takeLatest(types.GET_CUSTOMER_REQUEST, actions.getCustomer),
        yield takeLatest(types.GET_CUSTOMER_SITES_REQUEST, actions.getCustomerSites),
        // yield takeLatest(types.GET_CUSTOMERS_SUCCESS,actions.getAllData)
    ]);
}

