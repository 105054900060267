import connectionTypes from "./connectionTypes";
import { put } from "redux-saga/effects";
import store from "../rootStore";
import history from "../../utils/history";

const getState = () => store.getState().connections;

export function* disconnect() {
  const { device } = getState();
  if (device && device.gatt.connected) device.gatt.disconnect();
  yield put({
    type: connectionTypes.DISCONNECT,
    payload: "Disconnected.",
  });
}

export function* reconnect() {
  const { device, gatt } = getState();
  if (device && device.gatt.connected) gatt.disconnect();
  yield put({ type: connectionTypes.DISCONNECT, payload: "Reconnecting..." });
  yield put({ type: connectionTypes.CONNECT_REQUEST });
}

export function* pause() {
  yield put({ type: connectionTypes.SET_PAUSED, payload: true });
}

export function* unpause() {
  yield put({ type: connectionTypes.SET_PAUSED, payload: false });
}

export function* suspend() {
  yield put({ type: connectionTypes.SET_SUSPENDED, payload: true });
}

export function* unsuspend() {
  yield put({ type: connectionTypes.SET_SUSPENDED, payload: false });
}

export function* setConfig(action) {
  yield put({ type: connectionTypes.SET_CONFIG, payload: action.payload });
}

export function* connect() {
  let retry = true;
  while (!getState().connected && retry)
    if (!getState().connected) {
      yield put({ type: connectionTypes.SET_CONNECTING, payload: true });
      //let runLevel = 'start'
      yield put({
        type: connectionTypes.SET_MESSAGE,
        payload: "Connecting...",
      });
      try {
        if (!getState().device) {
          yield put({
            type: connectionTypes.SET_MESSAGE,
            payload: "Searching for device...",
          });
          const device = yield navigator.bluetooth.requestDevice({
            filters: [{ services: [getState().scannerConfig.serviceUUID] }],
          });
          yield put({
            type: connectionTypes.SET_DEVICE,
            payload: device,
          });
          if (device && device.gatt.connected) yield device.gatt.disconnect();
          const callback = () => {
            if (!getState().connecting)
              store.dispatch({
                type: connectionTypes.DISCONNECT,
                payload: "Lost connection.",
              });
          };
          device.addEventListener("gattserverdisconnected", callback);
        }
        // runLevel = 'device';
        if (!getState().GattServer) {
          yield put({
            type: connectionTypes.SET_MESSAGE,
            payload: "Connecting to device...",
          });
          const server = yield getState().device.gatt.connect();
          yield put({ type: connectionTypes.SET_SERVER, payload: server });
        }
        // runLevel = 'server';
        if (!getState().service) {
          yield put({
            type: connectionTypes.SET_MESSAGE,
            payload: "Reading device...",
          });
          const service = yield store
            .getState()
            .connections.gattServer.getPrimaryService(
              store.getState().connections.scannerConfig.serviceUUID
            );
          yield put({ type: connectionTypes.SET_SERVICE, payload: service });
        }
        // runLevel = 'service';
        if (!getState().characteristic) {
          yield put({
            type: connectionTypes.SET_MESSAGE,
            payload: "Scanning...",
          });
          const characteristic = yield getState().service.getCharacteristic(
            store.getState().connections.scannerConfig.notifyCharacteristicUUID
          );
          yield put({
            type: connectionTypes.SET_CHARACTERISTIC,
            payload: characteristic,
          });
        }
        // runLevel = 'characteristic';
        yield put({
          type: connectionTypes.SET_CONNECTED,
          payload: true,
        });
        getState().characteristic.startNotifications();
        const callback = (event) => {
          const { scanning, scannerConfig } = store.getState().connections;
          if (scanning) {
            const value = event.target.value;
            const a = [];
            // Convert raw data bytes to hex values just for the sake of showing something.
            // In the "real" world, you'd use data.getUint8, data.getUint16 or even
            // TextDecoder to process raw data bytes.
            const start = scannerConfig.resultStart;
            const end = scannerConfig.resultEnd;
            for (let i = start; i < value.byteLength + end; i++) {
              a.push(
                "" + value.getUint8(i).toString(16).slice(-2).substr(1, 1)
              );
            }
            const outData = a.join("");
            store.dispatch({
              type: connectionTypes.SET_FORMATTED_RESPONSE,
              payload: outData,
            });
            history.push("/a/animals/" + outData);
          }
        };
        getState().characteristic.addEventListener(
          "characteristicvaluechanged",
          (event) => {
            callback(event);
          }
        );
        yield put({
          type: connectionTypes.SET_MESSAGE,
          payload: "Scanning...",
        });
        yield put({
          type: connectionTypes.SET_CONNECTING,
          payload: false,
        });
        history.push("/a/connection/scanner-select");
        retry = false;
      } catch (error) {
        //alert('Connection error: '+error);
        if (getState().connectionRetry <= getState().connectionRetryLimit) {
          yield put({ type: connectionTypes.INCREMENT_RETRIES });
        } else {
          retry = false;
          alert(
            "Maximum connection attemps exceeded, device could not be reached."
          );
          const device = getState().device;
          if (device && device.gatt && device.gatt.connected)
            device.gatt.disconnect();
          yield put({
            type: connectionTypes.DISCONNECT,
            payload: "Connection failed.",
          });
        }
      }
    }
}
