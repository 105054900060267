import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table } from "react-bootstrap";
import moment from "moment";

import MUTooltip from "@material-ui/core/Tooltip";
import { faSkullCow } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import ReactPaginate from "react-paginate";

import { getMediaList } from "../../../store/media";

import { Modal } from "../Modal/Modal";
import ModalPhoto from "../Modal/ModalPhoto";

class AnimalRow extends Component {
  constructor(props) {
    super(props);
    this.perPage = 10;
    this.state = {
      ...props.data,
      tagColor: null,
      modal: Modal.defaultState(),
      isOpenPhoto: false,
      selectedPhoto: {},
      page: 1,
      pageCount: 0,
    };

    this.deleteOnClick.bind(this);
  }

  /* ========================================================================================================================
                                                    HELPERS
========================================================================================================================*/

  componentDidUpdate(prevProps) {
    if (prevProps.pagination.total !== this.props.pagination.total) {
      
      this.updateTotalAndPageCount();
    }
//  this.viewOnClick()
  }

  getID() {
    return this.props.data.rfid || "NA";
  }

  actionAllowed(action) {
    return (
      this.props.actions &&
      this.props.actions.hasOwnProperty(action) &&
      this.props.actions[action]
    );
  }

  setModalState(key, val) {
    this.setState(
      this.setState((state, props) => {
        let modal = { ...state.modal };
        modal[key] = val;
        return {
          modal: modal,
        };
      })
    );
  }

  getTagColor() {
    if (!this.props.data.tag_color) {
      return "";
    }

    return this.props.data.tag_color.name;
  }

  /* ========================================================================================================================
                                                    ACTIONS
========================================================================================================================*/

  openPhoto = (isOpen, selectedPhoto = {}) => {
    this.setState({
      isOpenPhoto: isOpen,
      selectedPhoto,
    });
    this.setModalState("visible", false);
  };
  closePhoto =(isOpen, selectedPhoto = {} )=>{
    this.setState({
      isOpenPhoto: isOpen,
      selectedPhoto,
    });
    if (!isOpen) {
      this.setModalState("visible", true); // Reopen the main modal when closing the photo modal
    }
  }


  viewOnClick() {
    if (!this.props.mediaList[this.props.data.id]) {
      this.props.getMediaList({
        animalId: this.props.data.id,
        page: this.state.page,
      });
    }

    this.setModalState("action", this.props.actions.delete);
    this.setModalState(
      "title",
      `<i class="fas fa-skull-cow"></i> ${this.getID()}`
    );
    this.setModalState("confirmBtnVisible", false);
    this.setModalState("visible", true);
  }

  editOnClick() {
    this.props.actions.edit();
  }

  uploadOnClick() {
    this.props.actions.uploadPhoto();
    // this.props.getMediaList(this.props.match.params.animalID);
    this.props.getMediaList({
      animalId: this.props.data.id,
      page: this.state.page,
    });
  }

  deleteOnClick(event) {
    event.stopPropagation();
    this.setState({
      ...this.state,
      action: "delete",
      modal: {
        ...this.state.modal,
        confirmBtnVisible: true,
        visible: true,
        title: "Are you sure?",
        description: `Remove animal #${this.getID()} from the postmortem?`,
      },
    });
  }

  toggleModalAction() {
    this.setState((state, props) => {
      const temp = !state.modal.visible ? Modal.defaultState() : state.modal;
      return {
        ...state,
        modal: { ...temp, visible: !state.modal.visible },
      };
    });
  }

  runAction() {
    if (
      this.state.action !== null &&
      typeof this.props.actions[this.state.action] !== "undefined" &&
      !!this.props.actions[this.state.action]
    ) {
      this.props.actions[this.state.action]();
      this.setState({ action: null });
    } else {
      console.error(`Action '${this.state.action}' does not exist`);
    }
    return true;
  }

  updateTotalAndPageCount() {
    const total = this.props.pagination.total ?? 0;
    const pageCount = total ? Math.ceil(total / this.perPage) : 0;
    this.setState({ total, pageCount });
  }

  setPage = (page) => {
    this.setState({ page }, () => {
      this.props.getMediaList({
        animalId: this.props.data.id,
        page: this.state.page,
      });
    });
  };
  /* ========================================================================================================================
                                                    MAIN RENDER
========================================================================================================================*/

  render() {
    const date = moment.parseZone(this.state.customer_record_at).local(true);
    const dateOfDeath = date.isValid() ? date.format("MMMM Do YYYY") : "None";
    const vetDiagnos = this.props.data.vet_diagnosis
      ? this.props.data.vet_diagnosis
      : null;
    const vetDiagnosName = vetDiagnos ? vetDiagnos.name : "None";
    const animalRowContainerClass = `flex text-white my-2 p-2 flex bg-blu mb-2
      active:border border-gre active:text-gre rounded block text-tw-white cursor-pointer`;

    return (
      <>
        {this.state.modal.description === "" && (
          <Modal
            {...this.state.modal}
            confirmAction={this.runAction.bind(this)}
            toggle={this.toggleModalAction.bind(this)}
          >
            {this.props.loading ? (
              <div className="photos-loading">
                <i className="fas fa-spinner text-9xl fa-spin photos-loading-color" />
              </div>
            ) : this.props.mediaList[this.props.data.id]?.length ? (
              <>
                <strong>Photo</strong>
                <div className="photos-container-modal">
                  {this.props.mediaList[this.props.data.id].map((photoObj) => (
                    <div
                      className="m-1 rounded p-2 card-container-modal"
                      key={photoObj.id}
                    >
                      <div
                        className="card-photo-container-modal"
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src={photoObj.image}
                          onClick={() => this.openPhoto(true, photoObj)}
                          className="card-photo-modal"
                          alt="photoAnimal"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{backgroundColor:'#1f304a'}}> 
                <ReactPaginate
                  breakLabel="..."
                  pageCount={this.state.pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination-container"
                  pageClassName="pagination-item"
                  activeClassName="pagination-item-active"
                  previousClassName={`pagination-previous ${
                    this.state.page === 1 ? "disabled" : ""
                  }`}
                  nextClassName={`pagination-next ${
                    this.state.page === this.state.pageCount ? "disabled" : ""
                  }`}
                  pageLinkClassName="pagination-link"
                  previousLinkClassName="pagination-link"
                  nextLinkClassName="pagination-link"
                  forcePage={this.state.page - 1}
                  pageRangeDisplayed={2}
                  onPageChange={(selectedItem) => {
                    this.setPage(selectedItem.selected + 1);
                  }}
                  previousLabel={
                    <span
                      style={{
                        color: this.state.page === 1 ? "#999" : "#fff",
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      {"<Previous"}
                    </span>
                  }
                  nextLabel={
                    <span
                      style={{
                        color:  this.state.page === this.state.pageCount ? "#999" : "#fff",
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      {"Next >"}
                    </span>
                  }
                  previousLinkProps={{
                    style: {
                      pointerEvents: this.state.page === 1 ? "none" : "auto",
                    },
                    onClick:
                      this.state.page === 1
                        ? null
                        : () => this.setPage(this.state.page - 1),
                  }}
                  nextLinkProps={{
                    style: {
                      pointerEvents:
                        this.state.page === this.state.pageCount
                          ? "none"
                          : "auto",
                    },
                    onClick:
                      this.state.page === this.state.pageCount
                        ? null
                        : () => this.setPage(this.state.page + 1),
                  }}
                />
                 </div>
                <hr />
              </>
            ) : null}
            <Table striped bordered hover className="table-fixed">
              <tbody>
                <tr>
                  <th className="w-1/3">Tag</th>
                  <td>
                    {this.getTagColor()} {this.props.data.tag}
                  </td>
                </tr>
                <tr>
                  <th>RFID</th>
                  <td>{this.props.data.rfid}</td>
                </tr>
                <tr>
                  <th>Pen</th>
                  <td>{this.props.data.pen}</td>
                </tr>
                <tr>
                  <th>Lot</th>
                  <td>{this.props.data.lot}</td>
                </tr>
                <tr>
                  <th>Date of Death</th>
                  <td>{dateOfDeath}</td>
                </tr>
              </tbody>
            </Table>
            <strong>Customer Notes</strong>
            <br />
            {this.props.data.customer_notes || "None"} <hr />
            <strong>Customer Diagnosis</strong>
            <br />
            {this.props.data.customer_diagnosis_note || "None"} <hr />
            {/* <strong>Animal History</strong>
            <br />
            {this.props.data.customer_animal_history || "None"} <hr /> */}
            <strong>Veterinarian Diagnosis</strong>
            <br />
            {vetDiagnosName} <hr />
            <strong>Veterinarian Notes</strong>
            <br />
            {this.props.data.vet_diagnosis_note || "None"}
            {/* <strong>Veterinarian Findings</strong>
            <br />
            {this.props.data.vet_findings || "None"} */}
          </Modal>
        )}
        {this.state.modal.description !== "" && (
          <Modal
            {...this.state.modal}
            confirmAction={this.runAction.bind(this)}
            toggle={this.toggleModalAction.bind(this)}
          />
        )}
        
        {this.state.isOpenPhoto && (
          <ModalPhoto
            allPhotos={this.props.mediaList}
            id={this.props.data.id}
            selectedPhoto={this.state.selectedPhoto}
            onClosePhoto={ ()=> this.closePhoto(false,this.state.selectedPhoto )}
          />
        )}


        <div
          onClick={() => {
            if (this.actionAllowed("view")) {
              this.viewOnClick();
            }
          }}
          className={animalRowContainerClass}
        >
          <span className="leading-7 flex flex-1">
            <FontAwesomeIcon
              className="mx-3 mt-2"
              icon={faSkullCow}
              color="#FFF"
            />
            <div>
              RFID {this.props.data.rfid}
              <p>Tag {this.props.data.tag}</p>
              {/* <p>
                {this.state.customer_record_at
                  ? "D.O.D " + moment.parseZone(this.state.customer_record_at).local(true).format('MMMM Do YYYY')
                  : ""}
              </p> */}
              <p>
                {this.state.customer_diagnosis
                  ? this.state.customer_diagnosis
                  : ""}
              </p>
            </div>
            {this.actionAllowed("check") && (
              <FontAwesomeIcon className="mx-3" icon={faCheck} color="#FFF" />
            )}
          </span>
          {this.actionAllowed("assign") && (
            <MUTooltip
              title="Assign"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <span className="fa-stack flex-initial cursor-pointer">
                <i className="fas fa-circle fa-stack-1x text-white" />
                <span
                  className="fa-stack-1x text-black"
                  style={{ fontSize: "0.5rem" }}
                >
                  CW
                </span>
              </span>
            </MUTooltip>
          )}
          {this.actionAllowed("schedule") && (
            <MUTooltip
              title="Schedule"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <span className="fa-stack flex-initial cursor-pointer">
                <i className="fas fa-circle fa-stack-1x text-white" />
                <i
                  className="fas fa-calendar-alt fa-stack-1x text-black"
                  style={{ fontSize: "0.5rem" }}
                />
              </span>
            </MUTooltip>
          )}
          {this.actionAllowed("edit") && (
            <MUTooltip
              title="Edit an animal"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <span
                onClick={this.editOnClick.bind(this)}
                className="fa-stack flex-initial cursor-pointer"
              >
                <i className="fas fa-circle fa-stack-1x text-white" />
                <i
                  className="fas fa-pen fa-stack-1x text-black"
                  style={{ fontSize: "0.5rem" }}
                />
              </span>
            </MUTooltip>
          )}
          {this.actionAllowed("uploadPhoto") && (
            <MUTooltip
              title="Upload photo"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <span
                className="fa-stack flex-initial cursor-pointer"
                onClick={this.uploadOnClick.bind(this)}
              >
                <i className="fas fa-circle fa-stack-1x text-white" />
                <i
                  className="fas fa-camera fa-stack-1x text-black"
                  style={{ fontSize: "0.5rem" }}
                />
              </span>
            </MUTooltip>
          )}

          {this.actionAllowed("delete") && (
            <MUTooltip
              title="Delete an animal"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <span
                onClick={this.deleteOnClick.bind(this)}
                className="fa-stack flex-initial cursor-pointer"
              >
                <i className="fas fa-circle fa-stack-1x text-white" />
                <i
                  className="fas fa-times fa-stack-1x text-black"
                  style={{ fontSize: "0.5rem" }}
                />
              </span>
            </MUTooltip>
          )}
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mediaList: state.media.list,
    loading: state.loading.GET_MEDIA_LIST,
    tagDiagnoses: state.library.diagnoses
      ? Object.values(state.library.diagnoses)
      : [],
    tagColor: state.library.tagColors
      ? Object.values(state.library.tagColors)
      : [],
    pagination: state.media.pagination,
  };
};

const mapDispatchToProps = {
  getMediaList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnimalRow)
);
