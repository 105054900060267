
import { put, delay } from "redux-saga/effects";

import notificationTypes from "./notificationTypes";

export function* clearNotification() {
  yield delay(5000)
  yield put({ type: notificationTypes.CLEAR_NOTIFICATION });
}

export function* setNotification(action) {
  yield put({ type: notificationTypes.SET_NOTIFICATION_SUCCESS, payload: action.metaData })
}
