import types from './mediaTypes';

export const addSingleMedia = (obj) => {
  return {
    type: types.ADD_SINGLE_MEDIA_REQUEST,
    payload: obj,
  };
};

export const getMediaList = (animalIdobj) => {
  return {
    type: types.GET_MEDIA_LIST_REQUEST,
    payload: animalIdobj,
  };
};

export const getMedia= ({ mediaID }) => ({
    type: types.GET_MEDIA_REQUEST,
    payload: { mediaID },
  });

  export const deleteMedia= ({ id, animalId}) => ({
    type: types.DELETE_MEDIA_REQUEST,
    payload: { id, animalId },
  });
