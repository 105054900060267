import React, { useEffect } from 'react';
import markerSDK from '@marker.io/browser';

const FeedbackWidget = () => {
  useEffect(() => {
    const loadMarkerWidget = async () => {
      await markerSDK.loadWidget({
        project: '6615098a330fdb8a52cb0419',
      });
    };

    loadMarkerWidget();
  }, []);

  return <div id="markerio-widget"></div>;
};

export default FeedbackWidget;
