// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CLEAR_ERROR: "CLEAR_ERROR",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  AUTHENTICATE_REQUEST: "AUTHENTICATE_REQUEST",
  AUTHENTICATE_SUCCESS: "AUTHENTICATE_SUCCESS",
  AUTHENTICATE_FAILURE: "AUTHENTICATE_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  LOGOUT_ASYNC: "LOGOUT_ASYNC",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  VERIFY_REQUEST: "VERIFY_REQUEST",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  VERIFY_FAILURE: "VERIFY_FAILURE",
  RESET_CHALLENGE_REQUEST: "RESET_CHALLENGE_REQUEST",
  RESET_CHALLENGE_SUCCESS: "RESET_CHALLENGE_SUCCESS",
  RESET_CHALLENGE_FAILURE: "RESET_CHALLENGE_FAILURE",
};
