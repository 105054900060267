import types from './inviteTypes';

const initialState = {
  loading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.INVITE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.INVITE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.INVITE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
