// eslint-disable-next-line import/no-anonymous-default-export
export default {

  // BACK_ROUTE
  SET_BACK_ROUTE: "SET_BACK_ROUTE",
  CLEAR_BACK_ROUTE: "CLEAR_BACK_ROUTE",

  // NAVBAR_EXTENDED
  REVERSE_NAVBAR: "REVERSE_NAVBAR",
};
