import types from './userTypes';
import { takeLatest, all } from 'redux-saga/effects';
import * as actions from './userActions';

export default function* userSagas() {
    yield all([
        yield takeLatest(types.GET_USERS_REQUEST, actions.getUsers),
        yield takeLatest(types.GET_USER_REQUEST, actions.getUser),
        yield takeLatest(types.UPDATE_USER_REQUEST, actions.updateUser),
        yield takeLatest(types.UPDATE_PASSWORD_REQUEST, actions.updatePassword)
    ]);
}