import { Toast } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Navbar } from "../../components/common";
import ResetChallengeScreen from "../../screens/profile/ResetChallengeScreen";

const AuthFrame = ({children}) => {
  const [showToast, setShowToast] = useState(false);
  const notification = useSelector(state => state.notification);
  const auth = useSelector(state => state.auth);

  const [isEditProfile, setIsEditProfile] = useState(
    auth.challenge === 'NEW_PASSWORD_REQUIRED'
  );

  useEffect(() => {
    if (notification.toastMessage) {
      setShowToast(true);
    }
  }, [notification]);

  useEffect(() => {
    setIsEditProfile(auth.challenge === 'NEW_PASSWORD_REQUIRED')
  }, [auth]);

  const closeEditProfile = () => {
    setIsEditProfile(false);
  }

  return (
    <div className='w-100 h-100 flex flex-column justify-center align-items-center'>
      <Navbar big={true} menu={false} />
      {isEditProfile ? (
        <ResetChallengeScreen closeEditProfile={closeEditProfile}/>
      ) : (
        <>
          <div className="login-toast-container" style={{ zIndex: 100 }}>
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Header bsPrefix={`toast-header-${notification.toastError ? 'error' : 'success'}`}>
                <span>{notification.toastMessage}</span>
              </Toast.Header>
            </Toast>
          </div>
          {children}
        </>
      )}
    </div>
  );
};

export default AuthFrame;
