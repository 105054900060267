import callApi from '../../utils/callApi';

import getSiteEndpoint from '../../utils/getSiteEndpoint';
import { searchPostmortems } from '../../utils/helpers';

export function* searchGetPostmortems(params) {
  const val = params.payload.filterValues;

  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/post-mortems?" + searchPostmortems(val),
    type: "SEARCH_GET_POSTMORTEMS",
    dataProp: "post_mortems",
    useFullResponse: true,
    errorMessage: "There was an error while getting post mortems.",
    transformSuccessPayload: (data) => {
      return data;
      // return [...new Map(data.map(item =>[item.id, item])).values()];
    }
  });
}
