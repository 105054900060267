import userTypes from './userTypes';

export const getUsers = (params) => ({
    type: userTypes.GET_USERS_REQUEST,
    payload: {
        ...params
    }
});

export const getUser = (uuid) => ({
    type: userTypes.GET_USERS_REQUEST,
    payload: {uuid: uuid}
});

export const updateUser = (user) => ({
    type: userTypes.UPDATE_USER_REQUEST,
    payload: user
});

export const updatePassword = (password) => ({
    type: userTypes.UPDATE_PASSWORD_REQUEST,
    payload: password
});

export const defaultUser = () => ({
    "id": undefined,
    "pin": undefined,
    "email": "un@kno.wn",
    "first_name": "",
    "last_name": "",
    "phone": "12345",
    "city": null,
    "region": null,
    "country": null,
    "email_verified_at": null,
    "remember_token": null,
    "created_at": "2021-04-17T21:38:03.000000Z",
    "updated_at": "2021-05-25T01:25:45.000000Z",
    "deleted_at": null,
    "customers": [],
    "list": {},
    loading: true,
    status: {
        error: null,
        message: ''
    }
});
