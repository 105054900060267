// eslint-disable-next-line import/no-anonymous-default-export
export default {

    GET_SITES_REQUEST: "GET_SITES_REQUEST",
    GET_SITES_SUCCESS: "GET_SITES_SUCCESS",
    GET_SITES_FAILURE: "GET_SITES_FAILURE",
    GET_SITE_REQUEST: "GET_SITE_REQUEST",
    GET_SITE_SUCCESS: "GET_SITE_SUCCESS",
    GET_SITE_FAILURE: "GET_SITE_FAILURE"

}