import React from 'react';

import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EmptyList = ({ text = '' }) => {
	return (
		<div className='h-100 flex flex-column justify-center align-items-center'>
			<div>
				<FontAwesomeIcon icon={faFile} color='white' className='text-4xl' />
			</div>
			<div className='text-center mt-4'>
				<p className='text-white'>{text}</p>
			</div>
		</div>
	)
};

export default EmptyList;
