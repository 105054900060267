import types from './postmortemTypes';
import { takeLatest, all } from "redux-saga/effects";
import * as actions from './postmortemActions';


export default function* postmortemSags() {
    yield all([
        yield takeLatest(types.ADD_POSTMORTEM_REQUEST, actions.addPostmortems),
        yield takeLatest(types.GET_POSTMORTEMS_REQUEST, actions.getPostmortems),
        yield takeLatest(types.GET_POSTMORTEM_REQUEST, actions.getPostmortem),
        yield takeLatest(types.GET_MORE_POSTMORTEMS_REQUEST,actions.getAllPostmortemsData),
        yield takeLatest(types.UPDATE_POSTMORTEM_REQUEST, actions.updatePostmortem),
        yield takeLatest(types.SCHEDULED_OR_COMPLETE_POSTMORTEM_REQUEST, actions.scheduledOrCompletePostmortem),
        yield takeLatest(types.DELETE_POSTMORTEM_REQUEST, actions.deletePostmortem),
        yield takeLatest(types.POSTMORTEM_REPORT_REQUEST, actions.postmortemReport),
    ]);
}