// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CLEAR_CONNECTION_STORE: "CLEAR_CONNECTION_STORE",
  CONNECT_ASYNC: "CONNECT_ASYNC",
  RECONNECT_ASYNC: "RECONNECT_ASYNC",
  DISCONNECT_ASYNC: "DISCONNECT_ASYNC",
  SET_MESSAGE: "SET_MESSAGE",
  PAUSE: "PAUSE",
  UNPAUSE: "UNPAUSE",
  SUSPEND: "SUSPEND",
  UNSUSPEND: "UNSUSPEND",
  SET_PAUSED: "SET_PAUSED",
  SET_SUSPENDED: "SET_SUSPENDED",
  SET_CONNECTING: "SET_CONNECTING",
  SET_CONNECTED: "SET_CONNECTED",
  SET_DEVICE: "SET_DEVICE",
  SET_SERVER: "SET_SERVER",
  SET_SERVICE: "SET_SERVICE",
  SET_CHARACTERISTIC: "SET_CHARACTERISTIC",
  SET_RETRY_LIMIT: "SET_RETRY_LIMIT",
  INCREMENT_RETRIES: "INCREMENT_RETRIES",
  SET_FORMATTED_RESPONSE: "SET_FORMATTED_RESPONSE",
  SET_CONFIG: "SET_CONFIG",
  SET_CONFIG_ASYNC: "SET_CONFIG_ASYNC",
  DISCONNECT: "DISCONNECT",
};
