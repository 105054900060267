// export const downloadPDF = (base64String) => {
//     const linkSource = `data:application/pdf;base64,${base64String}`;
//     const downloadLink = document.createElement("a");
//     const fileName = "CHM_Post_Mortem_report.pdf";

//     downloadLink.href = linkSource;
//     downloadLink.download = fileName;
//     downloadLink.click();
//     console.log('RITESH--->');
// }

export const downloadPDF = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const linkSource = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    const fileName = "CHM_Post_Mortem_report.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;

    // Append link to body for Firefox
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Release the URL object after the download
    URL.revokeObjectURL(linkSource);

    console.log('RITESH--->');
}

















