import React from "react";
import {Router, Switch, Route, Redirect} from "react-router-dom";
import history from "../utils/history";
import {NotFound} from "../components/common";
import AuthenticatedView from "../views/AuthenticatedView";
import PublicView from "../views/PublicView";
import PrivateRoute from "./types/PrivateRoute";
import PublicRoute from "./types/PublicRoute";

class AppRouter extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>

          {/* Redirect empty to authenticated */}
          <Route exact path="/">
            <Redirect to='/a/'/>
          </Route>

          {/* public routes */}
          <Route path="/u/">
            <PublicRoute component={PublicView}/>
          </Route>

          {/* private routes */}
          <Route path="/a/">
            <PrivateRoute component={AuthenticatedView}/>
          </Route>

          {/* catch all/ not found route */}
          <Route path='*' component={NotFound}/>

        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
