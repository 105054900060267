import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import Datetime from 'react-datetime';

import CustomDropDown from '../customDropDown';

export default class Animal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: props.mode,
      tagColorFilterValue: '',
      selectedTagColor: props.data && props.data.tag_color_id
        ? props.tagColors.find(tagColor => tagColor.id === props.data.tag_color_id).name
        : '',
      selectedTagDiagnoses: props.data && props.data.vet_diagnosis_id
        ? props.tagDiagnoses.find(tagDiagnosis => tagDiagnosis.id === props.data.tag_diagnoses_id).name
        : ''
    };
  }

  selectTagColor = (_, e) => {
    this.props.setParentState({
      tag_color_id: e.target.id,
      selectedTagColor: this.props.tagColors.find(tagColor => tagColor.id === e.target.id).name
    });
  }

  selectTagDiagnoses = (_, e) => {
    this.props.setParentState({
      vet_diagnosis_id: e.target.id,
      selectedTagDiagnoses: this.props.tagDiagnoses.find(diagnoses => diagnoses.id === e.target.id).name
    });
  }

  inMode(mode) {
    return this.props.mode === mode;
  }
  notInMode(mode) {
    return this.props.mode !== mode;
  }

  render() {
    const tagDiagnosesOptionsList = this.props.tagDiagnoses.map(tag => 
        ({ id: tag.id, label: tag.name }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    const selectedDiagnoses = this.props.selectedTagDiagnoses
      ? { id: this.props.tag_diagnoses_id, label: this.props.selectedTagDiagnoses}
      : null;

    const tagColorOptionsList = this.props.tagColors.map(tag => ({id: tag.id, label: tag.name }));
    const selectedColor = this.props.selectedTagColor
      ? { id: this.props.tag_color_id, label: this.props.selectedTagColor }
      : null;

    return (
      <>
        <div className="px-6">
          <Form.Label className="text-white text-left w-100 ">RFID#</Form.Label>
          <Form.Control
            type="text"
            placeholder='RFID'
            defaultValue={this.props.rfid}
            onChange={e => {this.props.setParentState({ rfid: e.target.value }) }}
            className="text-med rounded"
          />
        </div>
        <div className="px-6 flex pt-4">
          <div className="flex-1">
            <Form.Label className="text-white text-left w-100 ">TAG #</Form.Label>
            <Form.Control
              type="text"
              placeholder="Tag"
              defaultValue={this.props.tag}
              onChange={e => { this.props.setParentState({tag: e.target.value }) }}
              className="text-med"
            />
          </div>
          <div className="flex-1 mx-3">
            <Form.Label className="text-white text-left w-100 ">PEN</Form.Label>
            <Form.Control
              type="text"
              placeholder='Pen'
              defaultValue={this.props.pen}
              onChange={e => { this.props.setParentState({pen: e.target.value }) }}
              className="text-med"
            />
          </div>
          <div className="flex-1">
            <Form.Label className="text-white text-left w-100 ">LOT</Form.Label>
            <Form.Control
              type="text"
              placeholder='Lot'
              defaultValue={this.props.lot}
              onChange={e => { this.props.setParentState({ lot: e.target.value }) }}
              className="text-med"
            />
          </div>
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100 ">TAG COLOR</Form.Label>
          <CustomDropDown
            optionsList={tagColorOptionsList}
            selectedOption={ selectedColor }
            placeholderTitle="Tag color"
            onSelect={this.selectTagColor.bind(this)}
            theme="dark"
            customMenu={true}
            menuCanOpen={true}
          />
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100 ">DATE OF DEATH</Form.Label>
          <Datetime
            timeFormat={false}
              value={this.props.customer_record_at ? moment.parseZone(this.props.customer_record_at).local(true).format("MM/DD/YYYY") : (new moment()).format('MM/DD/YYYY')}
              inputProps={{
              className: 'text-2xl rounded form-control bg-transparent text-white border !border-gray-400 w-full p-3',
              placeholder: 'MM/DD/YYYY',
              readOnly: true
            }}
            onChange={e => {
              let val = '';
              if (e instanceof moment)
                val = e.format("MM/DD/YYYY");
              else
                e = '';
              this.props.setParentState({customer_record_at: val })
            }}
          />
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100 ">ANIMAL HISTORY/OTHER NOTES</Form.Label>
          {this.notInMode('vet') && (
            <Form.Control
              as="textarea"
              placeholder="Other notes you want to point out..."
              defaultValue={this.props.customer_notes}
              className="text-med text-white"
              style={{ minHeight: '150px' }}
              onChange={e => this.props.setParentState({  customer_notes: e.target.value })}
            />
          )}
          {this.inMode('vet') && (
            <div className="text-white">
              {this.props.customer_notes || "None"}
            </div>
          )}
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100 ">SUSPECTED CAUSE OF DEATH</Form.Label>
          {this.notInMode('vet') && (
            <Form.Control
              as="textarea"
              placeholder="suspected cause of death..."
              defaultValue={this.props.customer_diagnosis_note}
              className="text-med text-white"
              style={{ minHeight: '150px' }}
              onChange={e => this.props.setParentState({ customer_diagnosis_note: e.target.value })}

            />
          )}
          {this.inMode('vet') && (
            <div className="text-white">
              {this.props.customer_diagnosis_note || "None"}
            </div>
          )}
        </div>
        {this.inMode('vet') && (
          <div className="px-6 pt-4">
            <Form.Label className="text-white text-left w-100 ">VETERINARIAN DIAGNOSIS</Form.Label>
            <CustomDropDown
              optionsList={tagDiagnosesOptionsList}
              selectedOption={selectedDiagnoses}
              placeholderTitle="Please choose a diagnosis"
              theme="dark" 
              customMenu={true}
              menuCanOpen={true}
              onSelect={this.selectTagDiagnoses.bind(this)}
            />
           {/* <Form.Label className="text-white text-left w-100 pt-4">VETERINARIAN DIAGNOSIS NOTE</Form.Label> */}
           <Form.Label className="text-white text-left w-100 pt-4">VETERINARIAN NOTES</Form.Label>
            <Form.Control
              as="textarea"
              value={this.props.vet_diagnosis_note || ""}
              className="text-xl rounded"
              placeholder="Veterinarian note"
              onChange={e => this.props.setParentState({ vet_diagnosis_note: e.target.value })}
            />
          </div>
        )}
        { /* this.notInMode('vet') && (<div className="text-white">{ this.props.customer_diagnosis || "None" }</div>) */ }
        {/* {this.inMode('vet') && (
          <div className="px-6 pt-4">
            <Form.Label className="text-white text-left w-100 ">VETERINARIAN FINDINGS</Form.Label>
            <Form.Control
              as="textarea"
              value={this.props.vet_findings || ""}
              className="text-xl rounded"
              placeholder="Veterinarian findings"
              onChange={e => { this.props.setParentState({vet_findings: e.target.value }) }}
            />
          </div>
        )} */}
        {/* this.inMode('vet') && (<div className="text-white"> {this.props.customer_notes || "None"} </div>) */ }
     </>
    )
  }
}
