import types from './siteTypes';

const initialState = {
  list: {},
  pagination: {},
  loading: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SITES_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case types.GET_SITES_SUCCESS:
      return {
        ...state,
        list: { ...action.payload },
        pagination: action?.metaData?.pagination || {},
        loading: false
      }
    case types.GET_SITE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    default:
      return state;
  }
}