import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { authenticate, loadUser } from "../../store/auth";
import PropTypes from "prop-types";
import { unsuspend } from "../../store/connections";
import history from "../../utils/history";

const PrivateRoute = ({
  component: Component,
  authenticated,
  authenticate,
  loading,
  unsuspend,
  loadUser,
  ...rest
}) => {
  useEffect(() => {
    authenticate();
    unsuspend();
    
    //eslint-disable-next-line
  }, [history.location, authenticated]);
  //Prevents page from flashing briefly
  if (loading) {
    loadUser();
    return null;
  }  
  else {

    return (
        !authenticated ? <Redirect to='/u/login' /> : <Route component={Component} />
    );
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  authenticate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = {
  authenticate,
  unsuspend,
  loadUser
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
