import React, { Component } from 'react';
import {Router} from "react-router-dom";
import history from "../../utils/history";
import DashBoard from '../../screens/postmortem/DashBoard/DashBoard';
import PostMortemWizard from '../../screens/postmortem/PostMortemWizard/PostMortemWizard';
import GenerateRouters from "../helpers/GenerateRouters";
import UploadPhoto from '../../components/common/UploadPhoto';
import PostMortemReport from '../../components/common/PostMortemTicket/PostMortemReport';

const postMortemRouters = [
  {
    path: "/a/postmortems",
    redirect: "/a/postmortems/draft",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
  },
  {
    path: "/a/postmortems/:tab",
    component: DashBoard,
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
  },
  {
    path: "/a/postmortem/add",
    component: PostMortemWizard,
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
  },
  {
    path: "/a/postmortem/:id",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
    render: (props) => <PostMortemWizard {...props} isEdit={true} isAdd={true} />,
  },
  {
    path: "/a/postmortem/:id/report",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
    render: (props) => <PostMortemReport {...props} />
  },
  {
    path: "/a/postmortem/view/:id",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
    render: (props) => <PostMortemWizard {...props} isEdit={true} />,
  },
  {
    path: "/a/postmortem/view/:id/:animalID/uploadPhoto",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
    render: (props) => <UploadPhoto {...props} />,
  }
];

export default class PostMortemRouter extends Component {
  render() {
    return (
      <Router history={history}>
        <GenerateRouters routersConst={postMortemRouters} />
      </Router>
    )
  }
}
