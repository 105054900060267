import axios from "axios";
import qs from "qs";
import { put, retry } from "redux-saga/effects";
import { AuthTokenProvider } from '../utils/interceptors/auth-token.provider';
import { refreshTokenInterceptorProvider } from '../utils/interceptors/refresh-token.interceptor';
import { tokenStorage } from '../utils/interceptors/token.storage';
import setAuthToken from "./setAuthToken";

if (!axios.interceptors.response.handlers.length) {
  axios.interceptors.response.use(
    (r) => r,
    refreshTokenInterceptorProvider(
      new AuthTokenProvider(tokenStorage),
      {
        refresh: async (dto, config) => {
          return axios.post(`${process.env.REACT_APP_AUTH_ENDPOINT}/refresh`, dto)
        }
      }
    ).reject)
}

export default function* callApi({
  method,
  url,
  type,
  dataProp,
  useFullResponse,
  errorMessage,
  successMessage,
  body = null,
  onSuccess = null,
  reqData = null,
  isSendFormData = false,
  transformSuccessPayload = (data) => data,
}) {
  const config = {
    headers: isSendFormData
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/x-www-form-urlencoded" },
  };
  let error = null;
  let status = null;
  try {

    setAuthToken();
    let tries = 3;
    if (method === "post") tries = 1;
    if (method === "put") tries = 2;
    const formData = new FormData();
    if (isSendFormData) {
      for (const key in body) {
        if (Object.hasOwnProperty.call(body, key)) {
          formData.append(key, body[key])
        }
      }
    }

    const res = yield retry(tries, 1000, axios, {
      method,
      url,
      data: isSendFormData
        ? formData
        : body && qs.stringify(body),
      ...config,
      params: reqData || {}

    });

    const { success, state, status, data, session, challenge } = res.data;
    console.log("API Responsein CALLApi file ++++++++++====> :", res.data);
    if (state === "challenge") {
      const actualData = {
        session,
        challenge,
      };

      yield put({
        type: `${type}_SUCCESS`,
        payload: actualData,
        metaData: {
          toastErorr: false,
          toastMessage: successMessage,
        },
      });
    } else {
      if (success || state === "okay" || status === "success") {
        const actualData = useFullResponse ? res.data : dataProp ? data[dataProp] : data;
        // console.log("res.data,,dataProp,actualData", actualData)
        // console.log("API Responsein CALLApi file ++++++++++====> :", res.data);

        if (dataProp === "post_mortems") {
          yield put({
            type: `${type}_SUCCESS`,
            payload: res.data,
            metaData: {
              toastErorr: false,
              toastMessage: successMessage,
              pagination: res.data?.data?.pagination,
            },
          });
        }
        else {
          yield put({
            type: `${type}_SUCCESS`,
            payload: transformSuccessPayload(dataProp === "customers" ? res.data : actualData),
            metaData: {
              toastErorr: false,
              toastMessage: successMessage,
              pagination: res.data?.data?.pagination
            },
          });
        }


        if (onSuccess) {
          onSuccess(dataProp === "customers" ? res.data : actualData);
        }
      } else {
        error = errorMessage;
      }
    }
  } catch (err) {
    error = errorMessage;

    if (err.response && err.response.status === 401) {
      console.log('MANUALLY HANDLING 401 RESPONSE')
      localStorage.removeItem("token");
      localStorage.removeItem("roles");
      localStorage.removeItem("refresh");
      localStorage.removeItem("uuid");
    }

  }
  if (error) {
    yield put({
      type: `${type}_FAILURE`,
      payload: error,
      metaData: {
        toastError: true,
        toastMessage: errorMessage,
      },
    });

    // only push if the server error was 401
    if (status === 401) {
      // history.push('/u/login');
    }
  }
}
