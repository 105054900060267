
import { put } from "@redux-saga/core/effects";
import callApi from "../../utils/callApi";
import getSiteEndpoint from "../../utils/getSiteEndpoint";

export function* getSites(params) {
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/sites",
    type: "GET_SITES",
    useFullResponse: false,
    dataProp: "sites",
    errorMessage: "There was an error while getting sites.",
    reqData: { per_page: 10, sort: 'name', ...(params?.payload || {}) }
  });
}

export function* getSite(id) {
  id = id.payload.id;
  if (!id) { put({}); }

  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/sites/" + id,
    type: "GET_SITE",
    useFullResponse: false,
    dataProp: "site",
    errorMessage: "There was an error while getting site.",
  });
}

