import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import MUTooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { Form, Toast } from "react-bootstrap";
import Datetime from "react-datetime";

import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faEnvelope,
  faPaperPlane,
  faPlus,
  faSave,
  faSyringe,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheck,
  faFileChartLine,
  faPen,
  faSkullCow,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Animal from "../../../components/common/Animal/Animal";
import AnimalRow from "../../../components/common/AnimalRow/AnimalRow";
import Icon from "../../../components/common/Icon/Icon";
import PageWrapper from "../../../components/common/PageWrapper/PageWrapper";
import CustomDropDown from "../../../components/common/customDropDown";

import {
  getAllData,
  getCustomer,
  getCustomerSites,
  getCustomers,
} from "../../../store/customers";
import { getDiagnoses, getTagColors } from "../../../store/library";
import { getMediaList } from "../../../store/media";
import {
  addPostmortems,
  defaultPostmortem,
  getPostmortem,
  updatePostmortem,
} from "../../../store/postmortem";
import { defaultUser, getUsers } from "../../../store/user";
import {
  addVetAnimal,
  defaultVetAnimal,
  deleteVetAnimal,
  getVetAnimal,
  getVetAnimals,
  updateVetAnimal,
} from "../../../store/vetanimal";
// import { getAllData } from "../../../store/customers/customerActions";

import history from "../../../utils/history";

import getSiteEndpoint from "../../../utils/getSiteEndpoint";
import "./PostMortemWizard.css";

export class PostMortemWizard extends Component {
  historyListener = null;

  constructor(props) {
    super(props);
    this.api = axios.create({
      baseURL: getSiteEndpoint(),
    });

    this.state = {
      showToast: false,
      preStep: "",
      steps: {
        main: true,
        animal: false,
        submit: false,
        sent: false,
        animalDelete: false,
        complete: false,
      },
      animal: null,
      error: null,
      postmortem: defaultPostmortem().currentPostmortem,
      user: defaultUser(),
      currentUser: {},
      loading: true,
      pm_completed_at: null,
      isOpenCamera: false,
      isLoadMore: false,
    };

    this.vetRoute = undefined;

    if (props.match.params.id) {
      props.getPostmortem(props.match.params.id);
    }

    if (this.isClient()) {
      props.getCustomers(localStorage.getItem("uuid"));
      props.getCustomerSites(localStorage.getItem("uuid"));
    } else {
      props.getCustomers();
      // this.getCustomerOnThis()
      //   .then((customerData) => {
      //     console.log("Customer data:", customerData);
      //   })
      //   .catch((error) => {
      //     console.error("Failed to fetch customer data:", error);
      //   });

      props.getUsers({ "filter[roles.name]": "Veterinarian ,Admin" });
    }

    this.background = null;

    this.next = this.next.bind(this);
  }
  getCustomerOnThis = async () => {
    try {
      const response = await this.api.get(`/customers`);
      return response.data;
    } catch (error) {
      console.error("Error fetching customer data:", error);
      return null;
    }
  };

  componentDidMount() {
    if (this.props.isEdit) {
      this.setState({
        steps: {
          main: false,
          animal: false,
          submit: true,
          sent: false,
          animalDelete: false,
          complete: false,
        },
      });
    }

    if (this.props.match.params.animalID) {
      this.props.getVetAnimal(this.props.match.params.animalID);
      this.setState({
        steps: {
          main: false,
          animal: true,
          submit: false,
          sent: false,
          animalDelete: false,
          complete: false,
        },
      });
    }

    if (!this.props.tagColors.length) {
      this.props.getTagColors({ sort: "name", per_page: 100 });
    }

    if (!this.props.tagDiagnoses.length) {
      this.props.getDiagnoses();
    }

    this.background = document.getElementById("background");

    if (
      !this.isVet() &&
      this.props.currentUser.customers &&
      this.props.currentUser.customers.length > 0
    ) {
      this.setState({
        postmortem: {
          ...this.state.postmortem,
          customer_id: this.props.currentUser.customers[0].id,
        },
      });

      this.props.getCustomer(this.props.currentUser.customers[0].id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customers !== this.props.customers) {
      // Update the isLoadMore state if the data has been updated
      this.setState({
        isLoadMore: false, // Update this based on your logic
      });
    }

    if (this.props.pm_completed_at !== prevProps.pm_completed_at) {
      this.setState({
        pm_completed_at: this.props.pm_completed_at,
      });
    }
    //If customer has only one site. Auto assign it to streamline the process. Otherwise a dropdown will show below.
    if (
      this.props.customer !== prevProps.customer &&
      this.props.customer.sites &&
      this.props.customer.sites.length === 1
    ) {
      this.setState({
        postmortem: {
          ...this.state.postmortem,
          site_id: this.props.customer.sites[0].id,
        },
      });
    }

    if (this.state.user !== this.props.user) {
      this.setState((state) => {
        return {
          user: this.props.user,
        };
      });
    }

    if (this.state.currentUser !== this.props.currentUser) {
      this.setState({
        currentUser: this.props.currentUser,
      });
    }
    if (this.state.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }

    if (
      prevProps.vetanimal.veterinary_record_animal !==
      this.props.vetanimal.veterinary_record_animal
    ) {
      this.setState({
        animal: this.props.vetanimal.veterinary_record_animal,
      });
    }

    if (this.isDirty() && !this.props.isEdit) {
      if (!this.historyListener) {
        this.historyListener = history.block(
          "There are unsaved changes! Are you sure you want to leave?"
        );
      }
    } else {
      if (this.historyListener) {
        this.historyListener();
      }
    }
  }

  componentWillUnmount() {
    // stop listening to history event
    this.historyListener && this.historyListener();
  }

  /* ========================================================================================================================
                                                        HELPERS
  ========================================================================================================================*/

  isDirty() {
    let shouldNotify = false;

    if (this.state.animal) {
      Object.keys(this.state.animal).forEach((i) => {
        if (this.state.animal[i]) {
          shouldNotify = true;
          return;
        }
      });
    }

    return shouldNotify;
  }

  next(active) {
    let steps = { ...this.state.steps };
    let preStep = "";

    for (let step in steps) {
      if (steps[step]) {
        preStep = step;
      }
      steps[step] = step === active;
    }

    this.setState({
      steps,
      preStep,
      error: null,
    });

    this.background.scrollTo({
      top: 0,
    });

    return true;
  }

  currentStep(step) {
    return this.state.steps[step];
  }

  getCurrentStep() {
    for (let prop in this.state.steps) {
      if (this.state.steps[prop]) return prop;
    }
  }

  setError(msg = null) {
    this.setState({ error: msg });

    // Scroll to top after get validation error
    if (msg) {
      this.background.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  isVetRoute() {
    if (this.vetRoute != null) return this.vetRoute;

    this.props.location.pathname.split("/").forEach((el) => {
      if (el === "vet") {
        this.vetRoute = true;
        return;
      }
    });
    return this.vetRoute;
  }

  isAdmin() {
    if (!this.props.currentUser) {
      return false;
    }

    return (
      this.props.currentUser.roles.includes("Admin") ||
      this.props.currentUser.roles.includes("SuperAdmin")
    );
  }

  isVet() {
    if (!this.props.currentUser) return false;

    return (
      this.props.currentUser.roles.includes("Veterinarian") || this.isAdmin()
    );
  }

  isClient() {
    if (!this.props.currentUser) return false;

    return this.props.currentUser.roles.includes("Client");
  }

  isAddRoute() {
    if (this.addRoute != null) return this.addRoute;

    this.props.location.pathname.split("/").forEach((el) => {
      if (el === "add") {
        this.addRoute = true;
        return;
      }
    });
    return this.addRoute;
  }

  setValue(key, value) {
    this.props[`${this.getCurrentStep()}`][`${key}`] = value;
  }

  getID() {
    if (this.props.postmortem.id) {
      let numbers = String(this.props.postmortem.id).match(/\d+/g);
      if (numbers) {
        return numbers.join("").substring(0, 5);
      }
    }
    return 99999;
  }

  completeTicket() {
    this.next("complete");
    // if (this.props.postmortem.veterinary_record.scheduled_at) {
    //   this.next('complete')
    // } else {
    //   this.setError('Post mortem must be scheduled before it can be completed');
    // }
  }

  /* ========================================================================================================================
                                                      ACTIONS
  ========================================================================================================================*/
  mainSubmit() {
    if (!this.state.postmortem.requested_at) {
      this.setError("Please choose a date.");
    } else if (!this.state.postmortem.customer_id) {
      this.setError("Please select a customer.");
    } else if (!this.state.postmortem.site_id) {
      this.setError("Please select a site.");
    } else {
      this.setState({ error: null });
      let obj = this.state.postmortem;

      this.props.addPostmortems(obj);
      this.setState({ animal: defaultVetAnimal() });
      this.next("animal");
    }
  }
  /**
   *
   *
   */
  animalSubmit() {
    // if (!this.state.animal.rfid) {
    //   this.setError('RFID cannot be blank');
    // }
    // else if (!this.state.animal.tag) {
    //   this.setError('Tag cannot be blank');
    // } else if (!this.state.animal.pen) {
    //   this.setError('Pen cannot be blank');
    // } else if (!this.state.animal.lot) {
    //   this.setError('Lot cannot be blank');
    // }
    //else {
    this.setError();

    if (typeof this.state.animal.id == "undefined") {
      this.props.addVetAnimal({
        ...this.state.animal,
        customer_record_at: this.state.animal.customer_record_at
          ? this.state.animal.customer_record_at
          : new moment().format("MM/DD/YYYY"),
        veterinary_record_id:
          this.props.postmortem.veterinary_record &&
          this.props.postmortem.veterinary_record.id
            ? this.props.postmortem.veterinary_record.id
            : null,
      });
    } else {
      console.log('addanimal api==>',);
      this.props.updateVetAnimal(this.state.animal);
    }
    // const postmortemId = this.props.match.params.id
    //   ? this.props.match.params.id
    //   : this.props.postmortem.id;

    if (this.props.match.params.animalID) {
      history.goBack();
    }

    // stop listening before we go back
    this.historyListener && this.historyListener();

    if (this.props.match.url.includes("add")) {
      history.push(
        `/a/postmortem/${this.props.postmortem.veterinary_record.id}?customer_id=${this.state.postmortem.customer_id}`
      );
    } else {
      this.next("submit");
    }
    //};
  }

  submit() {
    const draftUpdateObj = { draft: 0, id: this.props.postmortem.id };
    this.props.updatePostmortem(draftUpdateObj);
    //Finally go to the next screen.
    this.next("sent");
  }

  addPhotoToAnimal(animal) {
    this.setState({ animal: animal });
    history.push(
      `/a/postmortem/view/${this.props.match.params.id}/${animal.id}/uploadPhoto`
    );
  }

  editAnimalAction(animal) {
    this.setState({ animal: animal });
    this.next("animal");
  }

  deleteAnimalAction(animal, postmortemId) {
    this.props.deleteVetAnimal(animal.id, postmortemId);
  }

  selectCustomer = (eventKey, event) => {
    if (eventKey === null) {
      // Handle the case when eventKey is null
      console.log("Event key is null");
      // Add your logic here
    } else {
      // Handle the normal case
      this.setState({
        ...this.state,
        postmortem: {
          ...this.state.postmortem,
          customer_id: this.props.customers[eventKey].id,
          customer: this.props.customers[eventKey],
          site_id: null,
        },
      });
    }
  };

  selectSite = (eventKey, event) => {
    this.setState({
      ...this.state,
      postmortem: {
        ...this.state.postmortem,
        site_id: eventKey,
      },
    });
  };

  /* ========================================================================================================================
                                                      MAIN RENDER
  ========================================================================================================================*/

  render() {
    if (this.props.loading && !this.state.isLoadMore) {
      return (
        <PageWrapper
          title="Details"
          loading={this.props.loading}
          type="all"
          icon={faPlus}
        />
      );
    }

    return (
      <div className="h-100">
        {this.currentStep("main") && this.mainStep()}
        {this.state.steps.animal && this.animalStep()}
        {this.state.steps.submit && this.submitStep()}
        {this.state.steps.sent && this.sentStep()}
        {this.state.steps.complete && this.completeStep()}
        <div className="login-toast-container">
          <Toast
            onClose={() => {
              this.setState({
                showToast: false,
              });
            }}
            show={this.state.showToast}
            delay={4000}
            autohide
          >
            <Toast.Header bsPrefix={`toast-header-success`}>
              <span>{"Data is updated"}</span>
            </Toast.Header>
          </Toast>
        </div>
      </div>
    );
  }

  /* ========================================================================================================================
                                                      STEP DISPLAYS
  ========================================================================================================================*/

  mainStep() {
    const { customers, currentUser, pagination, getAllData } = this.props;

    let postmortem = this.state.postmortem;
    const { no_vet_visit_requested } = postmortem;

    const loadMoreCallback = async (props) => {
      if (pagination?.next_page_url) {
        this.setState({ isLoadMore: true });
        await getAllData(pagination);
      } else {
        console.log("DATA IS UPDATED");
      }
    };

    const customerOptionList =
      customers && Object.values(customers).length
        ? Object.entries(customers)
            .map(([key, customer]) => ({
              id: customer.id,
              label: customer.name,
              key,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) // Sort alphabetically by customer name
        : null;

    const selectedCustomer = this.state.postmortem.customer_id
      ? {
          id: this.state.postmortem.customer_id,
          label: this.state.postmortem.customer.name,
        }
      : null;
    const selectedSite = this.state.postmortem.site_id
      ? {
          id: this.state.postmortem.site_id,
          label: this.isClient()
            ? Object.values(this.props.customer.sites).find(
                (site) => site.id === this.state.postmortem.site_id
              ).name
            : this.state.postmortem.customer.sites.find(
                (site) => site.id === this.state.postmortem.site_id
              ).name,
        }
      : null;

    const vetSitesValues = Object.values(
      this.state.postmortem.customer?.sites || {}
    );
    const customerSitesValues = Object.values(this.props.customer?.sites || {});
    const sitesValues = this.isClient() ? customerSitesValues : vetSitesValues;

    const siteOptionsList = sitesValues.length
      ? sitesValues.map(({ id, name }) => ({ id, label: name }))
      : null;

    const isSitesLength = this.isClient()
      ? this.isVet() ||
        !currentUser.customers ||
        currentUser.customers.length === 0
      : (this.props.customer.sites && this.props.customer.sites.length > 0) ||
        this.isVet() ||
        !currentUser.customers ||
        currentUser.customers.length === 0;

    const menuCanOpen = this.isClient()
      ? this.props.customer && this.props.customer.sites
      : this.state.postmortem.customer && this.state.postmortem.customer.sites;

    return (
      <div>
        <PageWrapper
          title="New Post Mortem"
          type="all"
          icon={faPlus}
        ></PageWrapper>
        {this.state.error && (
          <div className="mx-6 mt-4 alert-danger alert">{this.state.error}</div>
        )}
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100 ">
            PM TICKET #
          </Form.Label>
          <span className="text-5xl text-white">New Ticket</span>
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100">DATE</Form.Label>
          <Datetime
            initialValue={postmortem.requested_at}
            timeFormat={false}
            inputProps={{
              className: "text-2xl form-control rounded",
              readOnly: true,
            }}
            onChange={(e) => {
              if (e instanceof moment)
                this.setState({
                  postmortem: {
                    ...postmortem,
                    requested_at: e.format("MM/DD/YYYY"),
                  },
                });
            }}
          />
        </div>
        {isSitesLength && (
          <div className="px-6 pt-4">
            <Form.Label className="text-white text-left w-100">
              CLIENT
            </Form.Label>
            <CustomDropDown
              optionsList={customerOptionList}
              itemText="There are no customers"
              selectedOption={selectedCustomer}
              placeholderTitle="Please choose a customer"
              onSelect={this.selectCustomer}
              theme="dark"
              customMenu={false}
              buttontext={pagination?.next_page_url ? "Load More" : ""}
              isLoading={this.state.isLoadMore}
              loadMoreCallback={loadMoreCallback}
              menuCanOpen={Object.values(customers).length > 0}
              showLoadmoreButton={true}
            />
          </div>
        )}
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100">SITE</Form.Label>
          <CustomDropDown
            optionsList={siteOptionsList}
            itemText="The chosen customer has no sites"
            selectedOption={selectedSite}
            placeholderTitle={
              this.props.customer
                ? "Please choose a site"
                : "Choose a customer first"
            }
            onSelect={this.selectSite}
            theme="dark"
            customMenu={false}
            menuCanOpen={menuCanOpen}
          />
        </div>
        <div className="px-6 pt-4">
          <Form.Check
            type="checkbox"
            label="No post-mortem required"
            className="rounded"
            id="no_vet_visit"
            checked={no_vet_visit_requested}
            onChange={() => {
              this.setState({
                postmortem: {
                  ...postmortem,
                  no_vet_visit_requested: no_vet_visit_requested ? 0 : 1,
                },
              });
            }}
          />
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100">NOTES</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Please enter any additional generic information for the clinic to be aware of. E.g. if a vet has been or is already on site to perform the PM. Note that history for each animal can be added in the subsequent pages."
            value={postmortem.note}
            className="text-med rounded"
            style={{ minHeight: "150px" }}
            onChange={(e) => {
              this.setState({
                postmortem: { ...postmortem, note: e.target.value },
              });
            }}
          />
        </div>
        <div className="mt-20 block flex">
          <div className="flex-1">
            <MUTooltip
              title="Back"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <Icon
                icon={faTimes}
                size="45"
                style={{ display: "block" }}
                className="m-auto cursor-pointer"
                onClick={() => history.goBack()}
              />
            </MUTooltip>
          </div>
          <div className="flex-1">
            <MUTooltip
              title="Go to adding an animal"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <Icon
                icon={faArrowRight}
                size="45"
                style={{ display: "block" }}
                className="m-auto cursor-pointer"
                onClick={this.mainSubmit.bind(this)}
              />
            </MUTooltip>
            <p className="mt-6 block text-white text-center px-2">
              CREATE PM AND ADD ANIMALS
              <br /> TO SUBMISSION
            </p>
          </div>
        </div>
      </div>
    );
  }

  animalStep() {
    const { postmortem, tagColors, tagDiagnoses } = this.props;
    const { veterinary_record } = postmortem;

    let animal = this.state.animal || defaultVetAnimal();

    const selectedTagColor =
      animal && animal.tag_color_id
        ? tagColors.find((tagColor) => tagColor.id === animal.tag_color_id)
        : null;
    const selectedTagColorName = selectedTagColor ? selectedTagColor.name : "";

    const selectedTagDiagnoses =
      animal && animal.vet_diagnosis_id
        ? tagDiagnoses.find(
            (tagDiagnosis) => tagDiagnosis.id === animal.vet_diagnosis_id
          )
        : null;
    const selectedTagDiagnosesName = selectedTagDiagnoses
      ? selectedTagDiagnoses.name
      : "";

    return (
      <div>
        <PageWrapper
          title={animal.id ? "Save Changes" : "Add Animal"}
          type="all"
          loading={this.state.loading}
          icon={animal.id ? faPen : faPlus}
        />

        <div className="px-6 pt-4">
          {this.state.error && (
            <div className="alert-danger alert">{this.state.error}</div>
          )}
          <span className="text-2xl mb-3 block text-white">
            CHM-{veterinary_record?.pin}
          </span>
        </div>
        <Animal
          {...animal}
          mode={this.isVetRoute() ? "vet" : ""}
          selectedTagColor={selectedTagColorName}
          selectedTagDiagnoses={selectedTagDiagnosesName}
          tagColors={tagColors}
          tagDiagnoses={tagDiagnoses}
          setParentState={(animalData) => {
            //filter out noise? Seems to pass componentdidupdate function too
            if (typeof animalData == "object") {
              this.setState({
                animal: { ...this.state.animal, ...animalData },
              });
            }
          }}
        />
        <div className="mt-20 block flex">
          <div className="flex-1">
            <MUTooltip
              title="Back to Post Mortem details"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <Icon
                icon={faTimes}
                size="45"
                style={{ display: "block" }}
                className="m-auto cursor-pointer"
                onClick={() => {
                  if (this.props.match.params.animalID) {
                    history.goBack();
                  } else {
                    this.next("submit");
                  }
                }}
              />
            </MUTooltip>
            <p className="mt-3 mb-2 block text-white text-center">
              Discard Changes22
            </p>
          </div>
          <div className="flex-1">
            <MUTooltip
              title={`${animal.id ? "Edit an Animal" : "Add an animal"}`}
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <Icon
                icon={faSave}
                size="45"
                style={{ display: "block" }}
                className="m-auto cursor-pointer"
                onClick={this.animalSubmit.bind(this)}
              />
            </MUTooltip>
            <p className="mt-3 mb-2 block text-white text-center">
              {animal.id ? "Save Changes" : "Add Animal"}
            </p>
          </div>
        </div>
      </div>
    );
  }

  submitStep() {
    const { postmortem, isEdit } = this.props;
    console.log('POSTMERTEM=>',postmortem);

    const { veterinary_record } = postmortem;
    let animals = veterinary_record
      ? veterinary_record.veterinary_record_animals
      : { veterinary_record_animals: [] };

    //Update animal for parent form
    if (this.state.animal) {
      animals.find((v, i) => {
        if (this.state.animal.id === v.id) {
          animals[i] = this.state.animal;
        }
        return null;
      });
    }
    const isDraft = !!veterinary_record.draft;
    const isCompleted = veterinary_record.completed_at !== null;
    const inProgress = !!veterinary_record.in_progress;
    const noVetVisit = !!veterinary_record.no_vet_visit_requested;

    const createdAt = postmortem.created_at
      ? postmortem.created_at
      : veterinary_record.created_at;
    const isSchedule = veterinary_record && veterinary_record.scheduled_for;
    const animalList = Object.keys(animals).filter(
      (animal) => animals[animal].veterinary_record_id === postmortem.id
    );
    const vetRecord = veterinary_record || { scheduled_at: null, note: "" };
    const scheduleTextNotScheduleAt = isEdit
      ? !this.isVetRoute()
        ? "Not scheduled"
        : "Click to Schedule"
      : "";
    const scheduleDate =
      vetRecord.scheduled_at != null
        ? moment
            .parseZone(vetRecord.scheduled_at)
            .local(true)
            .format("MMMM Do, YYYY")
        : scheduleTextNotScheduleAt;

    const completeDate =
      vetRecord.completed_at != null
        ? moment
            .parseZone(vetRecord.completed_at)
            .local(true)
            .format("MMMM Do, YYYY")
        : scheduleDate;

    const selectedUnassigned = {
      id: "unassignedId",
      label: "Unassigned",
    };

    const vetNameScheduled = vetRecord.scheduled_for_id
      ? {
          id: vetRecord.scheduled_for_id,
          label: `${vetRecord.scheduled_for?.first_name || "Unknown"} ${
            vetRecord.scheduled_for?.last_name || "Name"
          }`,
        }
      : { id: selectedUnassigned.id, label: selectedUnassigned.label };

      const vetName = vetRecord.scheduled_for_id
      ? {
          id: vetRecord.scheduled_for_id,
          label: `${vetRecord.scheduled_for?.first_name || "Unknown"} ${
            vetRecord.scheduled_for?.last_name || "Name"
          }`,
        }
      : vetNameScheduled;

    const vetOptionList =
      isEdit && this.isVetRoute() && vetName.label !== "Unassigned"
        ? [
            selectedUnassigned,
            ...Object.values(this.state.user.list).map((user) => ({
              id: user.id,
              label: `${user?.first_name || "Unknown"} ${
                user?.last_name || "Name"
              }`,
            })),
          ]
        : Object.values(this.state.user.list).map((user) => {
          return(
            ({
              id: user.id,
              label: `${user.first_name || "Unknown"} ${ user?.last_name || "Name"} ${user?.roles.includes('Admin') ? "(Admin)":''}`,
            })
          )
        }
        );
          
          // console.log('vetOptionList 2222==>',vetName);
          vetOptionList.sort((a, b) => a.label.localeCompare(b.label));
    return (
      <PageWrapper
        loading={this.state.loading}
        title={isEdit && !this.props.isAdd ? "Edit Post Mortem" : "Details"}
        type="all"
        icon={isEdit ? (this.isAddRoute() ? faPlus : faPen) : faSkullCow}
      >
        {isEdit && !isDraft ? (
          <></>
        ) : (
          <p className="p-4 pb-0 text-white text-sm">
            Review your Post Mortem ticket before you send it to Cattle Health
            Management. The submission button can be found at the bottom of the
            page.
          </p>
        )}
        <div className="px-6 pt-4">
          <div
            className={`${this.state.error ? "" : "hidden"} alert alert-danger`}
          >
            {this.state.error}
          </div>
          <Form.Label className="text-white text-left w-100 ">
            PM TICKET # 
          </Form.Label>
          <span className="text-xl text-white ml-5">CHM-{vetRecord.pin}</span>
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100 ">
            CREATED
          </Form.Label>
          <span className="text-xl text-white  ml-5">
            {new moment(createdAt).format("MMMM Do, YYYY")}
          </span>
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100 ">
            SUBMITTED BY
          </Form.Label>
          <span className="text-xl text-white ml-5">
            {vetRecord.created_by &&
              vetRecord.created_by.first_name +
                " " +
                vetRecord.created_by.last_name}
          </span>
        </div>
        <div className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100 ">
            STATUS
          </Form.Label>
          <span className="text-xl text-white ml-5">
            {vetRecord.draft ? "Draft" : isCompleted ? "Completed"  : inProgress ? "In Progress"  : "Pending"}
          </span>
        </div>
        <div className="px-6 pt-4 flex">
          <div className="flex-1" style={{ width: "50%" }}>
            <Form.Label className="text-white text-left w-100 ">
              CUSTOMER
            </Form.Label>
            <span
              className="text-xl text-white  ml-5"
              style={{ width: "100%", wordBreak: "break-word" }}
            >
              {vetRecord.customer ? vetRecord.customer.name : ""} :{" "}
              {vetRecord.site ? vetRecord.site.name : ""}
            </span>
          </div>
        </div>
        {vetRecord.no_vet_visit_requested === 1 && (
          <div className="mx-6 mt-6 p-2 flex items-center border border-white rounded-xl">
            <FontAwesomeIcon icon={faCheck} size="2x" color="white" />
            <div className="flex-1 ml-2" style={{ width: "calc(100% - 80px)" }}>
              <Form.Label className="text-white text-left w-100 m-0">
                {" "}
                No vet visit requested{" "}
              </Form.Label>
            </div>
          </div>
        )}
        {!this.isAddRoute() && !vetRecord.no_vet_visit_requested && (
          <>
            <div
              className={`mx-6 mt-4 p-2 flex items-center items-center ${
                !isEdit ? "" : "border border-white"
              } rounded-xl`}
            >
              <Icon
                icon={faCalendar}
                size="50"
                className="flex-initial pr-20 "
              />
              <div className="flex-1">
                <Form.Label className="text-white text-left w-100 ">
                  {vetRecord.completed_at ? "COMPLETED" : "SCHEDULED"}
                </Form.Label>
                <Datetime
                  timeFormat={false}
                  inputProps={{
                    disabled: !this.isVetRoute() || !isEdit,
                    className:
                      "w-100 bg-transparent text-white submit-scheduled-text",
                    value: completeDate,
                  }}
                  onChange={(e) => {
                    let val = "";

                    if (e instanceof moment) {
                      val = e.format("MM/DD/YYYY");
                    } else {
                      e = "";
                    }

                    this.setState({
                      ...this.state,
                      postmortem: {
                        ...this.state.postmortem,
                        scheduled_at: val,
                      },
                    });
                    this.props.updatePostmortem({
                      id: postmortem.id,
                      scheduled_at: val,
                      scheduled_for_id: this.state.currentUser.id,
                    });
                  }}
                />
              </div>
            </div>
            <div
              className={`mx-6 mt-6 p-2 flex items-center ${
                !isEdit ? "" : "border border-white"
              } rounded-xl`}
            >
              {
                <Icon
                  icon={faSyringe}
                  size="50"
                  className="flex-initial pr-20"
                />
              }
              <div className="flex-1" style={{ width: "calc(100% - 80px)" }}>
                <Form.Label className="text-white text-left w-100">
                  VETERINARIAN
                </Form.Label>
                <CustomDropDown
                  optionsList={vetOptionList}
                  selectedOption={vetName}
                  placeholderTitle="Not assigned"
                  onSelect={(eventKey, e) => {
                    this.setState({
                      postmortem: {
                        ...this.state.postmortem,
                        scheduled_for_id: eventKey,
                      },
                    });
                    this.props.updatePostmortem({
                      id: postmortem.id,
                      scheduled_for_id:
                        eventKey === "unassignedId" ? "" : eventKey,
                    });
                  }}
                  theme="dark"
                  customMenu={false}
                  menuCanOpen={(isEdit && this.isVetRoute()) || this.isAdmin()}
                />
              </div>
            </div>
          </>
        )}
        <div>
          <Form.Label className="px-6 pt-4 text-white text-left w-100 ">
            Animals
          </Form.Label>
          {animalList.length ? (
            animalList.map((key) => {
              const actions = {
                view: () => this.viewAnimalAction(animals[key]),
              };
    
              if (this.isAdmin()) {
                actions.delete = () =>
                  this.deleteAnimalAction(animals[key], postmortem.id);
                actions.edit = () => this.editAnimalAction(animals[key]);
                actions.uploadPhoto = () => this.addPhotoToAnimal(animals[key]);
              } else if (this.isVet()) {
                // if the ticket is completed
                if (isCompleted) {
                  actions.uploadPhoto = () =>
                    this.addPhotoToAnimal(animals[key]);
                } else {
                  actions.delete = () =>
                    this.deleteAnimalAction(animals[key], postmortem.id);
                  actions.edit = () => this.editAnimalAction(animals[key]);
                  actions.uploadPhoto = () =>
                    this.addPhotoToAnimal(animals[key]);
                }
              } else if (this.isClient()) {
                if (noVetVisit) {
                  actions.edit = () => this.editAnimalAction(animals[key]);
                  actions.uploadPhoto = () =>
                    this.addPhotoToAnimal(animals[key]);
                }

                if (isDraft || (!inProgress && !isCompleted)) {
                  if (!inProgress && vetName.label === "Unassigned") {
                    actions.delete = () =>
                      this.deleteAnimalAction(animals[key], postmortem.id);
                    actions.edit = () => this.editAnimalAction(animals[key]);
                  }
                }
                if (!isCompleted) {
                  actions.uploadPhoto = () =>
                    this.addPhotoToAnimal(animals[key]);
                }
              }

              //Always check to see if complete.
              actions.check =
                animals[key].vet_diagnosis && animals[key].vet_findings;

              return (
                <AnimalRow key={key} data={animals[key]} actions={actions} />
              );
            })
          ) : (
            <p className="px-6 text-secondary text-2xl">There are no animals</p>
          )}
          {(isDraft || noVetVisit) && (
            <div className="flex items-center text-white my-2 p-2 flex bg-blu mb-2 active:border border-gre active:text-gre rounded block text-tw-white">
              <Icon
                icon={faPlus}
                size="24"
                className="mx-1 cursor-pointer"
                style={{ display: "block" }}
              />
              <span
                className="flex-1 cursor-pointer block align-middle "
                onClick={() => {
                  this.setState({ animal: defaultVetAnimal() });
                  this.next("animal");
                }}
              >
                Add Animal
              </span>
            </div>
          )}
        </div>
        <div>
          <div className="px-6 pt-4 text-white text-left w-100">Notes</div>
          {vetRecord.note ? (
            <div className="px-8 text-white w-100 text-2xl break-all">
              {vetRecord.note}
            </div>
          ) : (
            <div className="my-2 mx-8 text-secondary text-2xl">
              There are no notes
            </div>
          )}
        </div>
        <div className="mt-20 block flex">
          <div className="flex-1">
            <MUTooltip
              title="Back"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <Icon
                onClick={() => history.goBack()}
                icon={faArrowLeft}
                size="45"
                className="m-auto cursor-pointer"
                style={{ display: "block" }}
              />
            </MUTooltip>
          </div>
          {!!(isDraft || this.isAddRoute()) && (
            <>
              <div
                className="flex-1 cursor-pointer"
                onClick={() => {
                  this.setState({ animal: defaultVetAnimal() });
                  this.next("animal");
                }}
              >
                <MUTooltip
                  title="Go to adding an animal"
                  enterDelay={500}
                  leaveDelay={200}
                  placement="top"
                  classes={{
                    tooltip: "text-base ",
                  }}
                >
                  <Icon
                    icon={faPlus}
                    size="45"
                    className="m-auto cursor-pointer"
                    style={{ display: "block" }}
                  />
                </MUTooltip>
                <p className="mt-10 block text-white text-center">
                  ADD ANIMAL TO PM
                </p>
              </div>
              {!!(
                animalList.length &&
                isDraft &&
                !isSchedule &&
                !isCompleted &&
                !this.isVetRoute()
              ) && (
                <div className="flex-1">
                  <MUTooltip
                    title="Send Post mortem"
                    enterDelay={500}
                    leaveDelay={200}
                    placement="top"
                    classes={{
                      tooltip: "text-base ",
                    }}
                  >
                    <Icon
                      icon={faEnvelope}
                      size="45"
                      className="m-auto cursor-pointer"
                      style={{ display: "block" }}
                      onClick={this.submit.bind(this)}
                    />
                  </MUTooltip>
                  <p className="mt-10 block text-white text-center">
                    SEND PM REQUEST
                  </p>
                </div>
              )}
            </>
          )}
          {!!(
            (this.isVetRoute() || this.isAdmin()) &&
            isSchedule &&
            !isCompleted
          ) && (
            <div className="flex-1">
              <MUTooltip
                title="Complete Post mortem"
                enterDelay={500}
                leaveDelay={200}
                placement="top"
                classes={{
                  tooltip: "text-base ",
                }}
              >
                <Icon
                  icon={faCheck}
                  size="45"
                  className="m-auto cursor-pointer"
                  style={{ display: "block" }}
                  onClick={() => {
                    this.completeTicket();
                  }}
                />
              </MUTooltip>
            </div>
          )}
          {isCompleted && (
            <div className="flex-1">
              <MUTooltip
                title="Generate Report"
                enterDelay={500}
                leaveDelay={200}
                placement="top"
                classes={{
                  tooltip: "text-base",
                }}
              >
                <Icon
                  icon={faFileChartLine}
                  size="45"
                  className="m-auto cursor-pointer"
                  style={{ display: "block" }}
                  onClick={() => {
                    history.push(`/a/vet/ticketdetail/${postmortem.id}/report`);
                  }}
                />
              </MUTooltip>
            </div>
          )}
        </div>
      </PageWrapper>
    );
  }

  completeStep() {
    let postmortem = this.props.postmortem;
    const vetRecord = this.props.postmortem.veterinary_record.pin;
    const currentDateTime = moment().format("MM/DD/YYYY");

    return (
      <PageWrapper
        loading={this.state.loading}
        title="Complete Post Mortem"
        type="all"
        icon={faCheck}
      >
        {/* HELP TEXT */}
        <p className="text-white p-4">
          Completing this ticket will render it visible to your customer, as a
          read-only file.
        </p>
        {/* END HELP TEXT */}

        <div className="px-6 pt-4">
          <div
            className={`${this.state.error ? "" : "hidden"} alert alert-danger`}
          >
            {this.state.error}
          </div>
          <Form.Label className="text-white text-left w-100 ">
            PM TICKET #
          </Form.Label>
          <span className="text-xl ml-5 text-white">CHM-{vetRecord}</span>
        </div>
        <div
          className={`mx-6 mt-4 p-2 flex items-center items-center ${
            this.props.isEdit ? "border border-white" : ""
          }`}
        >
          {<Icon icon={faCalendar} size="50" className="flex-initial pr-20 " />}
          <div className="flex-1">
            <Form.Label className="text-white text-left w-100 ">
              COMPLETED
            </Form.Label>
            <Datetime
              timeFormat={false}
              initialValue={currentDateTime}
              inputProps={{
                disabled: !this.isVetRoute() || !this.props.isEdit,
                className:
                  "w-100 bg-transparent text-white submit-scheduled-text",
              }}
              onChange={(e) => {
                let val = "";

                if (e instanceof moment) {
                  val = e.format("MM/DD/YYYY");
                } else {
                  e = "";
                }
                this.setState({ pm_completed_at: val });
              }}
            />
          </div>
        </div>
        <div className="mt-20 block flex">
          <div className="flex-1">
            <MUTooltip
              title="Back"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <Icon
                onClick={() => history.goBack()}
                icon={faArrowLeft}
                size="45"
                className="m-auto cursor-pointer"
                style={{ display: "block" }}
              />
            </MUTooltip>
          </div>
          <div className="flex-1">
            <MUTooltip
              title="Complete Post Mortem"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <Icon
                onClick={() => {
                  this.props.updatePostmortem({
                    id: postmortem.id,
                    completed_at: this.state.pm_completed_at ?? currentDateTime,
                    completed_by_id: this.state.currentUser.id,
                    redirect: `/a/vet/ticketdetail/${postmortem.id}/report`,
                  });
                  // history.push(`/a/vet/ticketdetail/${postmortem.id}/report`);
                }}
                icon={faCheck}
                size="45"
                className="m-auto cursor-pointer"
                style={{ display: "block" }}
              />
            </MUTooltip>
          </div>
        </div>
      </PageWrapper>
    );
  }

  sentStep() {
    return (
      <div>
        <PageWrapper title="PM Sent" type="all">
          <div className="text-center text-8xl py-20">
            {
              <FontAwesomeIcon
                icon={faPaperPlane}
                color="white"
                className="text-center"
              />
            }
            <p className="text-2xl text-white mt-5">
              Your request has been submitted
            </p>
          </div>
        </PageWrapper>
        <div className="flex">
          <div className="flex-1 flex justify-center">
            <MUTooltip
              title="Go Back"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base",
              }}
            >
              <Icon
                icon={faArrowLeft}
                size="45"
                onClick={() => history.goBack()}
                className="cursor-pointer"
                title="go back"
              />
            </MUTooltip>
          </div>
          <div className="flex-1 flex justify-center">
            <MUTooltip
              title="Create Another"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base",
              }}
            >
              <Icon
                icon={faPlus}
                size="45"
                className="cursor-pointer"
                onClick={() => {
                  this.setState({
                    postmortem: defaultPostmortem(
                      this.props.location.search.split("=")[1]
                    ).currentPostmortem,
                  });
                  history.push("/a/postmortem/add");
                }}
              />
            </MUTooltip>
          </div>
        </div>
      </div>
    );
  }
}

/* ========================================================================================================================
                                                      REDUX HELPERS
========================================================================================================================*/

const mapStateToProps = (state, ownProps) => {
  const { vetanimal } = state;
  const postmortem = state.postmortem.currentPostmortem;
  const { veterinary_record } = postmortem;
  let animals = postmortem.animals;

  if (veterinary_record) {
    for (const animal of veterinary_record.veterinary_record_animals) {
      animals[animal.id] = animal;
    }
  }

  const tagColors = state.library.tagColors
    ? Object.values(state.library.tagColors)
    : [];
  const tagDiagnoses = state.library.diagnoses
    ? Object.values(state.library.diagnoses)
    : [];
  if (vetanimal.new.id) {
    animals[vetanimal.new.id] = vetanimal.new;
    vetanimal.new = {};
  } else if (vetanimal.edited.id) {
    animals[vetanimal.edited.id] = vetanimal.edited;
    vetanimal.edited = {};
  }

  let loading = () => {
    let hasLoad = false;

    Object.entries(state.loading).map(([key, val]) => {
      if (val && key !== "GET_MEDIA_LIST") {
        hasLoad = true;
      }
      return false;
    });
    return hasLoad;
  };

  let customer = {};

  if (state.customer) {
    customer = Object.assign({}, state.customer);
    delete customer.list;
  }
  // Extract pagination from customers

  return {
    loading: loading(),
    postmortem: {
      ...postmortem,
      animals,
    },
    postMortemLoading: loading.GET_POSTMORTEM,
    tagColors,
    tagDiagnoses,
    vetanimal: vetanimal,
    user: state.user,
    currentUser: state.auth.user,
    customers: state.customer.list,
    customer: customer,
    pagination: state.customer.pagination,
    pm_completed_at: postmortem.veterinary_record
      ? postmortem.veterinary_record.completed_at
      : null,
  };
};

const mapDispatchToProps = {
  addPostmortems,
  addVetAnimal,
  updateVetAnimal,
  updatePostmortem,
  getVetAnimals,
  getVetAnimal,
  getPostmortem,
  getTagColors,
  getDiagnoses,
  deleteVetAnimal,
  getUsers,
  getCustomer,
  getCustomers,
  getAllData,
  getCustomerSites,
  getMediaList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostMortemWizard);
