import types from './searchTypes';

  export const searchFilterValue = (name, value) => ({
    type: types.SEARCH_FILTER_VALUE,
    payload: { name, value },
  });

  export const searchGetPostmortems = (params) => ({
    type: types.SEARCH_GET_POSTMORTEMS_REQUEST,
    payload: { ...params },
  });
