import React from "react";
import {Router, Switch, Route} from "react-router-dom";
import history from "../../utils/history";
import LoginScreen from "../../screens/auth/LoginScreen/LoginScreen";
import ResetScreen from "../../screens/auth/ResetScreen";

class AuthRouter extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/u/login' component={LoginScreen}/>
          <Route exact path='/u/reset' component={ResetScreen}/>
        </Switch>
      </Router>
    );
  }
}

export default AuthRouter;
