import React, { forwardRef } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import history from "../../utils/history";

import TodoScreen from "../../screens/general/TodoScreen/TodoScreen";

const DashboardRouter = forwardRef((props, ref) => {
  const auth = useSelector(state => state.auth);

  const display_locations = auth.roles.includes("Veterinarian") || auth.roles.includes("SuperAdmin") || auth.roles.includes("Admin");

  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/a/'>
          <Redirect to={display_locations ? '/a/location/all/inbox' : '/a/postmortems'} />
        </Route>
        <Route exact path='/a/todo' component={TodoScreen}/>
      </Switch>
    </Router>
  );
})

export default DashboardRouter;
