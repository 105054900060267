import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import history from "../../../utils/history";
import {useState} from "react";
import {logout, loadUser} from "../../../store/auth";
import {connect} from "react-redux";
import {CSSTransition} from "react-transition-group";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-solid-svg-icons";
import { reverseNavbar } from "../../../store/frames";
import Logo from '../Logo/Logo';

const menuItems = [
  {
    text: "CHM Home",
    path: "/a/",
  }
];

const Navbar = ({ logout, big, menu, navbarExtended, reverseNavbar, path, user }) => {
  const [showMenu, setShowMenu] = useState(false);

  const isVet = () => {
    if (user && user.roles) return user.roles.includes("Veterinarian") || user.roles.includes("Admin") || user.roles.includes("SuperAdmin");
    return false;
  };

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setShowMenu(false);
  }, [path]);

  return (
    <div className={`${(big && navbarExtended && !showMenu)? 'header-container flex justify-center align-items-center' : 'header-container inner'}`}>
  {/*     <div
        className='backicon-container'
        onClick={() => history.goBack()}
        style={{ display : menu ? 'flex' : 'none' }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color='#4DBD74'
        />
      </div> */}
      <div
        className='menuicon-container'
        onClick={() => setShowMenu(!showMenu)}
        style={{ display : menu ? 'flex' : 'none' }}
      >
        <FontAwesomeIcon icon={faBars}  className="text-dark"/>
      </div>
      <div className={`logo-container ${!menu ? "flex justify-center" : ""}`}>
        <Logo 
          onClick={() => history.push("/a/")}
          fill={(big && navbarExtended && !showMenu) ? "#FFF":null }
          style={(big && navbarExtended && !showMenu) ? { width: "60%", maxWidth: "311px" } : { marginTop: 0, width: 100, marginLeft: "18px" }}>
        </Logo>
      </div>
      <CSSTransition
        in={showMenu}
        timeout={300}
        classNames='nav-menu'
        unmountOnExit
        onEnter={() => setShowMenu(true)}
        onExited={() => setShowMenu(false)}
      >
        <div className='nav-menu d-flex flex-column'>
          {menuItems.map((item, idx) => (
            <button
              key={item.text}
              className='menu-item rounded'
              style={{ marginTop: !idx ? '36px' : '16px' }}
              onClick={() => {
                setShowMenu(false);
                history.push(item.path);
              }}
            >
              {item.text}
            </button>
          ))}
          <button
            key="CHM Web"
            className='menu-item mt-3 rounded'
            onClick={() => {
              setShowMenu(false);
              window.open("https://cattlehealth.co","_blank");
            }}
          >
            CHM Web
          </button>
          <Link to={'/a/profile'} className="menu-item mt-3 text-center align-middle">
            <button
              key="Profile"
              className="w-100 h-100 text-uppercase rounded"
              onClick={() => setShowMenu(false)}
            >
              Profile
            </button>
          </Link>
          {isVet() && (
            <Link to={'/a/invite-user'} className="menu-item mt-3 text-center align-middle">
              <button
                key="Invite user"
                className="w-100 h-100 text-uppercase rounded"
                onClick={() => setShowMenu(false)}
              >
                Invite user
              </button>
            </Link>
          )}
          <Link
            to={'/a/change-password'}
            className="menu-item mt-3 text-center align-middle"
          >
            <button
              className='w-100 h-100 text-uppercase rounded'
              onClick={() => setShowMenu(false)}
            >
              <span>Change password</span>
            </button>
          </Link>
          <button
            className='menu-item mt-3 rounded'
            onClick={() => {
              setShowMenu(false);
              if (!navbarExtended) reverseNavbar();
              logout();
            }}
          >
            <span>Logout</span>
          </button>
          <span className='copyright'>Copyright ©{currentYear} CHM</span>
        </div>
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  navbarExtended: state.frames.navbarExtended,
});

const mapDispatchToProps = {
  logout,
  loadUser,
  reverseNavbar,
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
