// import React, { useState } from 'react';

// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';

// import '../UploadPhoto/UploadPhoto.css';

// const ModalPhoto = ({ allPhotos, selectedPhoto, onClosePhoto, id }) => {
  
//   let photoList = allPhotos ? allPhotos[id] : [{ image: selectedPhoto }]
//   let index = 0;
//   for (let i = 0; i < photoList.length; i++) {
//     if (photoList[i].id === selectedPhoto.id) {
//       index = i;
//       break;
//     }
//   }

//   const [photoIndex, setIndex] = useState(index);
  
//   return (
//       <Lightbox
//         mainSrc={photoList[photoIndex].image}
//         nextSrc={photoList[(photoIndex + 1) % photoList.length]}
//         prevSrc={photoList[(photoIndex + photoList.length - 1) % photoList.length]}
//         onCloseRequest={(e) => {
//           e.preventDefault();
//           onClosePhoto(false);
//         }}
//         onMovePrevRequest={() => setIndex((photoIndex + photoList.length - 1) % photoList.length)
//         }
//         onMoveNextRequest={() => setIndex((photoIndex + 1) % photoList.length)
//         }
//       />
//     // <div className="show-photo-container">
//     //   <img
//     //     src={selectedPhoto}
//     //     className="w-100 h-100"
//     //     style={{ objectFit: "contain" }}
//     //     alt="photoModal"
//     //   />
//     //   <FontAwesomeIcon
//     //     onClick={(e) => {
//     //       e.preventDefault();
//     //       onClosePhoto(false);
//     //     }}
//     //     icon={faTimes}
//     //     color="#FFF"
//     //     className="show-photo-close-btn"
//     //   />
//     // </div>
//   );
// };

// export default ModalPhoto;


import React, { useState, useRef } from 'react';
import '../UploadPhoto/UploadPhoto.css';

const ModalPhoto = ({ allPhotos, selectedPhoto, onClosePhoto, id }) => {
  let photoList = allPhotos ? allPhotos[id] : [{ image: selectedPhoto }];
  let initialIndex = 0;

  for (let i = 0; i < photoList.length; i++) {
    if (photoList[i].id === selectedPhoto.id) {
      initialIndex = i;
      break;
    }
  }

  const [photoIndex, setPhotoIndex] = useState(initialIndex);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 1));
  };

  const handleReset = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleTouchStart = (e) => {
    console.log('handleTouchStart=>',e.touches.length );
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      imageRef.current.startX = touch.clientX - position.x;
      imageRef.current.startY = touch.clientY - position.y;
    }
  };
  
  
  // const handleTouchMove = (e) => {
  //   console.log('handleTouchMove==>',e.touches.length );
  //   if (e.touches.length === 1) {
  //     const touch = e.touches[0];
  //     setPosition({
  //       x: touch.clientX - imageRef.current.startX,
  //       y: touch.clientY - imageRef.current.startY,
  //     });
  //   }
  // };
  
  const handleTouchMove = (e) => {
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      const newX = touch.clientX - imageRef.current.startX;
      const newY = touch.clientY - imageRef.current.startY;
  
      const minX = -100; // Minimum x position
      const maxX = 100;  // Maximum x position
      const minY = -100; // Minimum y position
      const maxY = 100;  // Maximum y position
  
      // Ensure newX and newY are within the bounds
      const constrainedX = Math.min(Math.max(newX, minX), maxX);
      const constrainedY = Math.min(Math.max(newY, minY), maxY);
  
      setPosition({
        x: constrainedX,
        y: constrainedY,
      });
    }
  };
  




  const handleMouseDown = (e) => {
    if (e.target.tagName === 'IMG') {
      e.preventDefault();
      const startX = e.pageX - position.x;
      const startY = e.pageY - position.y;
      const minX = -100;
      const maxX = 100;
      const minY = -200;
      const maxY = 200;
  
      const handleMouseMove = (moveEvent) => {
        let newX = moveEvent.pageX - startX;
        let newY = moveEvent.pageY - startY;
  
        newX = Math.max(minX, Math.min(maxX, newX));
        newY = Math.max(minY, Math.min(maxY, newY));
  
        setPosition({
          x: newX,
          y: newY,
        });
      };
  
      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
  
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };
  
  const handleImageClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    const newScale = Math.min(scale + 0.5, 3);
    const newOriginX = (offsetX / rect.width) * 100;
    const newOriginY = (offsetY / rect.height) * 100;
    setOrigin({ x: newOriginX, y: newOriginY });
    setScale(newScale);
  };

  return (
    <div className="modal-photo-container">
      <div
        className="modal-photo-overlay"
        onClick={() => onClosePhoto(false)}
      />
      <div className="modal-photo-content">
        <div className="modal-photo-controls">
          <button className="modal-photo-zoom-in" onClick={handleZoomIn}>Zoom In</button>
          <button className="modal-photo-zoom-out" onClick={handleZoomOut}>Zoom Out</button>
          <button className="modal-photo-reset" onClick={handleReset}>Reset</button>
          <button className="modal-photo-close" onClick={() => onClosePhoto(false)}>Close</button>
        </div>
        <div className="modal-photo-wrapper">
          <img
            ref={imageRef}
            src={photoList[photoIndex].image}
            alt="Modal"
            className="modal-photo"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onMouseDown={handleMouseDown}
            onClick={handleImageClick}
            style={{
              transformOrigin: `${origin.x}% ${origin.y}%`,
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            }}
          />
        </div>
        <div className="modal-photo-nav">
        <button
            className={`modal-photo-prev`}
            onClick={() =>
              setPhotoIndex(
                (photoIndex + photoList.length - 1) % photoList.length
              )
            }
            disabled={photoIndex === 0}
            style={{ color: photoIndex === 0 ? "gray" : "black" }}
          >
            Prev
          </button>

          <button
            className={`modal-photo-next`}
            onClick={() => setPhotoIndex((photoIndex + 1) % photoList.length)}
            disabled={photoIndex === photoList.length - 1}
            style={{
              color: photoIndex === photoList.length - 1 ? "gray" : "black",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalPhoto;
