import { useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import {
  getMorePostmortemData,
  getPostmortems,
  resetList,
} from "../../../store/postmortem";
import { getUsers } from "../../../store/user";
import Loading from "../Loading/Loading";
import PostMortemTabRows from "./PostMortemTabRows";

const PostMortemTab = (props) => {
  const { tab, showLoadmoreButton, Pagination,getPostmortems } = props;
  const [showIsloading, setShowIsloading] = useState(false);
  const [activePage, setActivePage] = useState(0); // State to track active page
  const [page, setPage] = useState(1);

  const perPage = 20;
  const records = useMemo(() => props.postmortem.list, [props.postmortem.list]);
  const loading = useMemo(
    () => props.postmortem.loading,
    [props.postmortem.loading]
  );
  const containerRef = useRef(null);
  const total = useMemo(() => Pagination.total ?? 0, [Pagination.total]);
  const pageCount = useMemo(
    () => (total ? Math.ceil(total / perPage) : 0),
    [total]
  );
  const handlePageChange = (selectedItem) => {
    setPage(selectedItem.selected + 1); // Update page state
  };
  
  // Use useEffect to update the active page when new data is received
  useEffect(() => {
    setActivePage(props.Pagination.current - 1); // Update active page based on current page in Pagination
  }, [props.Pagination]);
  

  useEffect(() => {
    setActivePage(0);
    setPage(1) // Set active page to 0 when tab changes
  }, [tab]);

  const applyFetchFilter = () => {
    switch (tab) {
      case "inbox":
      case "pending":
        return {
          "filter[veterinary_records.in_progress]": false,
          "filter[veterinary_records.completed_at:isnull]": true,
          "filter[veterinary_records.draft]": false,
        };
      case "inprogress":
      case "scheduled":
        return {
          "filter[veterinary_records.in_progress]": true,
        };
      case "complete":
        return {
          "filter[veterinary_records.completed_at:isnull]": false,
          sort: "-veterinary_records.created_at",
        };
      case "draft":
        return {
          "filter[veterinary_records.draft]": true,
        };
      default:
        return {};
    }
  };

  useEffect(() => {
    if (prevRecords.current) {
      setShowIsloading(false);
    }
  }, [props.postmortem.list]);

  const prevRecords = useRef(null);

  useEffect(() => {
    prevRecords.current = props.postmortem.list;
  }, [props.postmortem.list]);

  useEffect(() => {
    if (!Object.keys(props.vets).length) {
      props.getUsers({
        "filter[roles.name]": `Veterinarian${ props.isAdminUser ? ",Admin,SuperAdmin" : "" }`,
      });
    }

    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }

    let filters = applyFetchFilter();
    if (props.siteId) {
      filters["filter[veterinary_records.site_id]"] = props.siteId;
    }
    props.resetList();
    props.getPostmortems({
      per_page: 20,
      page,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, perPage, page, getPostmortems]);

  if (loading) {
    return <Loading visible={true} showStyles={false} />;
  }


  return (
    <div
      ref={containerRef}
      style={{ overflowY: "auto", maxHeight: "100%" }}
      // onScroll={handleScroll}
    >
      <PostMortemTabRows
        tab={tab}
        records={records}
        confirmModalAction={props.confirmModalAction}
        showPostMortemSmall={props.showPostMortemRow}
      />
      {showIsloading && (
        <div
          className="flex justify-center items-center"
          style={{ height: "150px" }}
        >
          <i className="fas fa-spinner  text-4xl fa-spin  mx-auto text-white" />
        </div>
      )}



      {showLoadmoreButton && (
        // <button  className={`text-white py-2 px-2 flex-1 ml-1 DashBoard_dashboardBtn__CTBW1 rounded ${
        //   props.Pagination?.next_page_url ? 'border-gre border-2' : ''
        // }`} onClick={handleLoadMore} >
        //   {props.Pagination?.next_page_url ?<FontAwesomeIcon icon={faSpinnerThird} /> : ''}
        //   <span className="xs:ml-2 text-xs block xs:inline xs:text-base"style={{color:'#fff',fontSize:16}} >
        //     {props.Pagination?.next_page_url ? "Load More" : ""}
        //   </span>
        // </button>


        
        <ReactPaginate
          breakLabel="..."
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          containerClassName="pagination-containerleft"
          pageClassName="pagination-item"
          activeClassName="pagination-item-active"
          previousClassName={`pagination-previous ${
            page === 1 ? "disabled" : ""
          }`}
          nextClassName={`pagination-next ${
            page === pageCount ? "disabled" : ""
          }`}
          pageLinkClassName="pagination-link"
          previousLinkClassName="pagination-link"
          nextLinkClassName="pagination-link"
          pageRangeDisplayed={2}
          // onPageChange={(selectedItem) => {
            
          //   setPage(selectedItem.selected + 1);
          // }}
          onPageChange={handlePageChange}

          forcePage={activePage}
          previousLabel={
            <span
              style={{
                color: page === 1 ? "#999" : "#fff",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {"<Previous"}
            </span>
          }
          nextLabel={
            <span
              style={{
                color: page === pageCount ? "#999" : "#fff",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {"Next >"}
            </span>
          }
          previousLinkProps={{
            style: { pointerEvents: page === 1 ? "none" : "auto" },
            onClick: page === 1 ? null : () => setPage(page - 1),
          }}
          nextLinkProps={{
            style: { pointerEvents: page === pageCount ? "none" : "auto" },
            onClick: page === pageCount ? null : () => setPage(page + 1),
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    postmortem: {
      ...state.postmortem,
    },
    vets: state.user.list,
    Pagination: state?.postmortem?.pagination,
  };
};

const mapDispatchToProps = {
  getPostmortems,
  resetList,
  getUsers,
  getMorePostmortemData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostMortemTab);


