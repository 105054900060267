import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { authenticate } from "../../store/auth";
import PropTypes from "prop-types";
import { unsuspend } from "../../store/connections";
import history from "../../utils/history";

const PublicRoute = ({
  component: Component,
  authenticated,
  authenticate,
  loading,
  unsuspend,
  ...rest
}) => {
  useEffect(() => {
    authenticate();
    unsuspend();
    //eslint-disable-next-line
  }, [history.location, authenticated]);

  //Prevents page from flashing briefly
  if (loading) return null;
  else
    return (
        authenticated ? <Redirect to='/' /> : <Route component={Component} />
    );
};

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  authenticate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = {
  authenticate,
  unsuspend,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
