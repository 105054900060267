import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/common/PageWrapper/PageWrapper";
import { faCertificate, faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { getTrainings } from "../../../store/training";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const DashBoard = ({ getTrainings, trainings, loading }) => {
  
  const [selectedTraining, setSelectedTraining] = useState(null);
  
  useEffect(() => {
    getTrainings();
  }, [getTrainings]);

  console.log(selectedTraining);
  return (
    <PageWrapper
      loading={loading}
      title="Training"
      type="all"
      icon={faCertificate}
    >
      <div className="px-6">
        {trainings && selectedTraining == null && 
          Object.values(trainings).map((training) => {
            return (
              <div key={training.id} onClick={()=>setSelectedTraining(training)} className="cursor-pointer text-white border border-white rounded p-3 mb-3">
                <h3><FontAwesomeIcon icon={faCertificate} /> {training.title}</h3>
                <p>{training.description}</p>
              </div>
              // <div key={training.id} className="text-white border border-white rounded p-3 mb-3">
              //   <Accordion.Toggle eventKey={training.id} className="w-full text-left">
                  
              //     
              //   </Accordion.Toggle>
              //   <Accordion.Collapse eventKey={training.id}>
              //     <Accordion>
              //     {
              //       training.training_sections && Object.values(training.training_sections).map( section => {
              //           return (
              //           <div key={section.id}>
              //             <Accordion.Toggle className="w-full text-left" eventKey={section.id}><h4>{section.heading}</h4></Accordion.Toggle>
              //             <Accordion.Collapse eventKey={section.id}><p>{section.content}</p></Accordion.Collapse>
              //           </div>
              //           );
              //       })
              //     }
              //     </Accordion>
              //   </Accordion.Collapse>
              // </div>
            );
          })}
          {
            selectedTraining != null && typeof selectedTraining == "object" && 
              (
                <div class="text-white relative">
                  <FontAwesomeIcon icon={faTimes} className="absolute top-0 right-0 cursor-pointer" onClick={()=>setSelectedTraining(null)}></FontAwesomeIcon>
                  <h1>{selectedTraining.title}</h1>
                  <p>{selectedTraining.pre_content}</p>
                  {
                    selectedTraining.training_sections && Object.values(selectedTraining.training_sections).map ( section => {
                      return (
                        <div key={section.id}>
                          <h2>{section.heading}</h2>
                          <p>{section.content}</p>
                        </div>
                      );

                    })
                  }
                  <p>{selectedTraining.post_content}</p>
                  <Button onClick={()=>setSelectedTraining(null)} className="mr-1 w-100 text-white !border-gre hover:!border-gre !bg-gre hover:!bg-gre active:!bg-gre active:!border-gre focus:!border-gre border-2 py-2 flex-1 rounded">Back to menu</Button>
                </div>
              )
          }
      </div>
    </PageWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.training.loading,
    trainings: state.training.trainings,
  };
};

const mapDispatchToProps = {
  getTrainings,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
