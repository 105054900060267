import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faKey } from "@fortawesome/pro-solid-svg-icons";

import { resetChallenge } from "../../../store/auth";

import PasswordInput from "../../../components/common/PasswordInput";
import Loading from "../../../components/common/Loading/Loading";

import "./ResetChallengeScreen.css";

const ResetChallengeScreen = ({ closeEditProfile }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.loading.UPDATE_USER);
  const auth = useSelector((state) => state.auth);

  const [steps, setStep] = useState({
    setInfo: true,
    setPassword: false,
  });
  const [userInfo, setUserInfo] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
  });
  const [password, setPassword] = useState({ pass: "", confirmPass: "" });
  const [passError, setPassError] = useState({
    pass: "",
    confirmPass: ""
  });
  const [nameError, setNameError] = useState({
    first_name: "",
    last_name: "",
  });

  const onChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const passwordValidation = () => {
    const newError = {};

    if (password.confirmPass.trim().length === 0) {
      newError.confirmPass = "Passwords do not match.";
    }
    if (password.pass.trim() !== password.confirmPass.trim()) {
      newError.confirmPass = "Passwords do not match.";
    }
    if (password.pass.trim().length < 8) {
      newError.pass = "Password needs to be at least 8 characters long.";
    }
    if (password.pass.trim().match(/[A-Z]/g) === null) {
      newError.pass = "Password needs to have at least 1 capital letter.";
    }
    if (password.pass.trim().match(/\d/g) === null) {
      newError.pass = "Password needs to have at least 1 number.";
    }
    if (password.pass.trim().match(/[!@#$%^&*?]/g) === null) {
      newError.pass =
        "Password needs to have at least 1 special character !@#$%^&*?";
    }

    if (Object.keys(newError).length === 0) {
      closeEditProfile();
      dispatch(
        resetChallenge({
          session: auth.session,
          username: auth.username,
          password: password.pass,
          userInfo: { ...userInfo }
        })
      );
    } else {
      setPassError({ ...newError });
    }
  };

  const onChangePass = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const nextStep = (step) => {
    const newSteps = {};
    for (const key in steps) {
      if (Object.hasOwnProperty.call(steps, key)) {
        if (key === step) {
          newSteps[key] = true;
        } else {
          newSteps[key] = false;
        }
      }
    }
    setStep(newSteps);
  };

  const onSaveName = () => {
    const newError = {};

    if (userInfo.first_name.trim().length === 0) {
      newError.first_name = "Please enter a first name.";
    }
    if (userInfo.last_name.trim().length === 0) {
      newError.last_name = "Please enter a last name.";
    }
    if (Object.keys(newError).length === 0) {
      nextStep("setPassword");
    } else {
      setNameError({ ...newError });
    }
  };

  const setProfileInfo = () => {
    return (
      <Form className="mx-8 d-flex flex-column align-items-center">
        <Form.Group className="w-100">
          <Form.Label className="text-white text-left w-100">
            First Name
          </Form.Label>
          <Form.Control
            name="first_name"
            className="w-100 mb-2 rounded"
            placeholder="First name"
            type="text"
            value={userInfo.first_name}
            onChange={onChange}
          />
          <span className="error-container text-danger text-sm">
            {nameError.first_name}
          </span>
        </Form.Group>
        <Form.Group className="w-100">
          <Form.Label className="text-white text-left w-100">
            Last Name
          </Form.Label>
          <Form.Control
            name="last_name"
            className="w-100 mb-2 rounded"
            placeholder="Last name"
            type="text"
            value={userInfo.last_name}
            onChange={onChange}
          />
          <span className="error-container text-danger text-sm">
            {nameError.last_name}
          </span>
        </Form.Group>
        <button
          className="edit-profile-btn rounded"
          type="button"
          onClick={onSaveName}
        >
          SET MY NAME
        </button>
      </Form>
    );
  };

  const resetPassword = () => (
    <Form className="mx-8 d-flex flex-column align-items-center">
       <PasswordInput
          inputLabel="Set your new password"
          inputName="pass"
          placeholder="Password"
          passwordValue={password.pass}
          error={passError.pass}
          handleOnChange={onChangePass}
        />
        <PasswordInput
          inputLabel="Repeat your new password"
          inputName="confirmPass"
          placeholder="Repeat password"
          passwordValue={password.confirmPass}
          error={passError.confirmPass}
          handleOnChange={onChangePass}
        />
      <button
        className="edit-profile-btn rounded"
        type="button"
        onClick={passwordValidation}
      >
        SET MY PASSWORD
      </button>
    </Form>
  );

  return (
    <div className="login-container w-100">
      {loading ? (
        <Loading visible={loading} />
      ) : (
        <>
          <ul className="w-100 d-flex justify-content-center list-unstyled">
            {Object.entries(steps).map(([step, value]) => (
              <li
                className={`${
                  value ? "bg-white" : "bg-transparent"
                } border-white rounded-circle mx-4`}
                style={{ width: "20px", height: "20px", borderWidth: "1px" }}
                key={step}
              />
            ))}
          </ul>
          <p className="w-100 d-flex justify-content-center text-white text-xl">
            {steps.setInfo
              ? "Almost there! Please tell us your name."
              : "Now lets set a password for you."}
          </p>
          <div className="w-100 d-flex justify-content-center mb-2">
            <FontAwesomeIcon
              icon={steps.setInfo ? faCheck : faKey}
              color="white"
              className="text-5xl"
            />
          </div>
          {steps.setInfo && setProfileInfo()}
          {steps.setPassword && resetPassword()}
        </>
      )}
    </div>
  );
};

export default ResetChallengeScreen;
