import callApi from "../../utils/callApi";
import getSiteEndpoint from "../../utils/getSiteEndpoint";

export function* getUnitsOfMeasure() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/unit-of-measures`,
    type: "GET_UNITS_OF_MEASURE",
    dataProp: "unit_of_measures",
    errorMessage: "Could not get units of measure.",
  });
}

export function* getUnitOfMeasure(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/unit-of-measures/${action.payload.uomId}`,
    type: "GET_UNIT_OF_MEASURE",
    dataProp: "unit_of_measure",
    errorMessage: "Could not get unit of measure.",
  });
}

export function* getSpeciesList() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/species`,
    type: "GET_SPECIES_LIST",
    dataProp: "species",
    errorMessage: "Could not get species list.",
  });
}

export function* getSpecies(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/species/${action.payload.speciesId}`,
    type: "GET_SPECIES",
    dataProp: "specie",
    errorMessage: "Could not get species.",
  });
}

export function* getAnimalTypes() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/animal-types`,
    type: "GET_ANIMAL_TYPES",
    dataProp: "animal_types",
    errorMessage: "Could not get animal types.",
  });
}

export function* getAnimalType(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/species/${action.payload.typeId}`,
    type: "GET_ANIMAL_TYPE",
    dataProp: "animal_type",
    errorMessage: "Could not get animal type.",
  });
}

export function* getAnimalColors() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/animal-colors`,
    type: "GET_ANIMAL_COLORS",
    dataProp: "animal_colors",
    errorMessage: "Could not get animal colours.",
  });
}

export function* getAnimalColor(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/animal-colors/${action.payload.colorId}`,
    type: "GET_ANIMAL_COLOR",
    dataProp: "animal_color",
    errorMessage: "Could not get animal colour.",
  });
}

export function* getDiagnoses() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/diagnoses?per_page=9999`,
    type: "GET_DIAGNOSES",
    dataProp: "diagnoses",
    errorMessage: "Could not get diagnoses.",
  });
}

export function* getDiagnosis(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/diagnoses/${action.payload.diagnosisId}`,
    type: "GET_DIAGNOSIS",
    dataProp: "diagnosis",
    errorMessage: "Could not get diagnosis.",
  });
}

export function* getHealthRisks() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/health-risks`,
    type: "GET_HEALTH_RISKS",
    dataProp: "health_risks",
    errorMessage: "Could not get health risks.",
  });
}

export function* getHealthRisk(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/health-risks/${action.payload.healthRiskId}`,
    type: "GET_HEALTH_RISK",
    dataProp: "health_risk",
    errorMessage: "Could not get health risk.",
  });
}

export function* getTreatments() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/treatments`,
    type: "GET_TREATMENTS",
    dataProp: "treatments",
    errorMessage: "Could not get treatments.",
  });
}

export function* getTreatment(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/treatments/${action.payload.treatmentId}`,
    type: "GET_TREATMENT",
    dataProp: "treatment",
    errorMessage: "Could not get treatment.",
  });
}

export function* getBreeds() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/breeds`,
    type: "GET_BREEDS",
    dataProp: "breeds",
    errorMessage: "Could not get breeds.",
  });
}

export function* getBreed(action) {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/breeds/${action.payload.breedId}`,
    type: "GET_BREED",
    dataProp: "breed",
    errorMessage: "Could not get breed.",
  });
}

export function* getTagColors(params) {
  let defaultParams = {};
  
  if (params) {
    params = params.payload || {};
  } else {
    params  = {};
  }

  params = {
    ...defaultParams,
    ...params
  };

  let query = new URLSearchParams();
  Object.keys(params).map(key => {
    query.set(key, params[key]);
    return null;
  });

  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/tag-colors?${query.toString()}`,
    type: "GET_TAG_COLORS",
    dataProp: "tag_colors",
    errorMessage: "Could not get tag colors.",
  });
}

export function* getAnimalRecords() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/animal-records`,
    type: "GET_ANIMAL_RECORDS",
    dataProp: "animal_records",
    errorMessage: "Could not get animal records.",
  });
}
