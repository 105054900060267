import React, { forwardRef } from 'react';
import { Router, Switch } from 'react-router-dom';

import history from '../../utils/history';

import ChangePassword from '../../screens/changePassword';
import { withRouterAndRef } from '../../HOCs/withRouterAndRef';

const PasswordChangeRouter = forwardRef((props, ref) => {
  return (
    <Router history={history}>
      <Switch>
        <ChangePassword exact path='/a/change-password' />
      </Switch>
    </Router>
  );
})

export default withRouterAndRef(PasswordChangeRouter);