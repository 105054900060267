
import { put } from "@redux-saga/core/effects";
import callApi from "../../utils/callApi";
import getSiteEndpoint from "../../utils/getSiteEndpoint";


export function* getTrainings() {
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/trainings",
    type: "GET_TRAININGS",
    useFullResponse: false,
    dataProp: "trainings",
    errorMessage: "There was an error while getting sites.",
  });
}
export function* getTraining(id) {
  id =id.payload.id;
  if (!id) { put({}); }

  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/trainings/"+id,
    type: "GET_TRAINING",
    useFullResponse: false,
    dataProp: "trainings",
    errorMessage: "There was an error while getting site.",
  });
}

