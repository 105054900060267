import connectionTypes from "./connectionTypes";

const initialState = {
  paused: false,
  connecting: false,
  connected: false,
  scanning: false,
  context: null,
  rawResponse: null,
  formattedResponse: "",
  device: null,
  gattServer: null,
  service: null,
  characteristic: null,
  connectionRetry: 0,
  connectionRetryLimit: 5,
  stateMessage: "Disconnected",
  scannerConfig: {
    name: "M4S ID Microchip Scanner",
    serviceUUID: "0000ffe0-0000-1000-8000-00805f9b34fb",
    readCharacterisiticUUID: "0000ffe1-0000-1000-8000-00805f9b34fb",
    notifyCharacteristicUUID: "0000ffe1-0000-1000-8000-00805f9b34fb",
    scanFormat: "notify",
    resultFormat: "uInt8",
    resultStart: 9,
    resultEnd: -6,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case connectionTypes.SET_MESSAGE:
      return {
        ...state,
        stateMessage: action.payload,
      };

    case connectionTypes.SET_CONNECTING:
      return {
        ...state,
        connecting: action.payload,
      };

    case connectionTypes.SET_CONNECTED:
      return {
        ...state,
        connected: action.payload,
        scanning: action.payload,
      };

    case connectionTypes.SET_DEVICE:
      return {
        ...state,
        device: action.payload,
      };

    case connectionTypes.SET_SERVER:
      return {
        ...state,
        gattServer: action.payload,
      };

    case connectionTypes.SET_SERVICE:
      return {
        ...state,
        service: action.payload,
      };

    case connectionTypes.SET_CHARACTERISTIC:
      return {
        ...state,
        characteristic: action.payload,
      };

    case connectionTypes.SET_RETRY_LIMIT:
      return {
        ...state,
        connectionRetryLimit: action.payload,
      };

    case connectionTypes.INCREMENT_RETRIES:
      return {
        ...state,
        connectionRetry: state.connectionRetry + 1,
      };

    case connectionTypes.SET_FORMATTED_RESPONSE:
      return {
        ...state,
        formattedResponse: action.payload,
      };

    case connectionTypes.SET_PAUSED:
      if (state.connected)
        return {
          ...state,
          paused: action.payload,
          scanning: !action.payload,
          stateMessage: action.payload ? "Paused" : "Connected",
        };
      else return state;

    case connectionTypes.SET_SUSPENDED:
      if (state.connected)
        return {
          ...state,
          scanning: !state.paused && !action.payload,
          stateMessage:
            !state.paused && !action.payload ? "Connected" : "Paused",
        };
      else return state;

    case connectionTypes.SET_CONFIG:
      return {
        ...state,
        scannerConfig: action.payload,
      };

    case connectionTypes.DISCONNECT:
      return {
        ...state,
        stateMessage: action.payload,
        paused: false,
        scanning: false,
        connectionRetry: 0,
        connected: false,
        connecting: false,
        device: null,
        gattServer: null,
        service: null,
        characteristic: null,
      };

    case "CLEAR_ALL":
      return initialState;

    default:
      return state;
  }
};
