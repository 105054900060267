import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
// import { Form } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import MUTooltip from '@material-ui/core/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCamera, faImages } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { addSingleMedia, deleteMedia } from '../../../store/media';

import history from '../../../utils/history';

import Icon from '../Icon/Icon';
import PageWrapper from '../PageWrapper/PageWrapper';
import ModalPhoto from '../Modal/ModalPhoto';

// import { getMediaList } from '../../../store/media';
// import { getVetAnimal } from '../../../store/vetanimal';

import './UploadPhoto.css';


const UploadPhoto = (props) => {
  const animalId = props.match.params.animalID;

  const [showPhoto, setShowPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [photos, setPhotos] = useState([]);

  // const animal = useSelector(
  //   (state) => state.vetanimal.veterinary_record_animal
  // );

  const dispatch = useDispatch();

  useEffect(() => {
    // if (!animal || animal.id !== animalId) {
    //   dispatch(getVetAnimal(animalId));
    // } else {
    //   dispatch(getMediaList(animalId));
    // }
  });

  const loading = useSelector((state) => {
    let hasLoad = false;
    Object.entries(state.loading).map(([key, val]) => {
      if (val) {
        hasLoad = true;
      }
      return null;
    });
    return hasLoad;
  });

  const mediaList = useSelector((state) =>
    state.media.list && state.media.list[animalId]
      ? state.media.list[animalId]
      : []
  );

  const savePhoto = (newPhotos) => {
    setPhotos([...newPhotos, ...photos]);
  };

  const uploadPhoto = () => {
    hiddenFileInput.current.click();
  };

  const hiddenFileInput = React.useRef(null);

  const handleChange = (event) => {
    const filesUploaded = [];
    for (const file of event.target.files) {
      filesUploaded.push({
        id: `${Date.now()}-${Math.floor(Math.random() * (Date.now() + 1))}`,
        image: URL.createObjectURL(file),
        name: file.name,
        file: file,
        draft: true,
      });
    }
    console.log('LENGTH===>',filesUploaded.length)
    savePhoto(filesUploaded);
  };

  const openPhoto = (photo) => {
    setShowPhoto(true);
    setSelectedPhoto(photo.image);
  };

  const deletePhoto = (photo) => {
    if (photo.draft) {
      setPhotos(photos.filter((ph) => photo.id !== ph.id));
    } else {
      dispatch(deleteMedia({ animalId: animalId, id: photo.id }));
    }
  };

  const handleAddMedia = () => {
    if (photos.length !== 0) {
        dispatch(addSingleMedia({array: photos, animalId: animalId}));
    }

    handleClickBack();
  };

  const handleClickBack = () => {
    history.goBack();
  };

  if (showPhoto) {
    return (
      <ModalPhoto selectedPhoto={selectedPhoto} onClosePhoto={setShowPhoto} />
    );
  }

  const showListPhoto = [...photos, ...mediaList];

  return (
    <div>
      {loading ? (
        <PageWrapper
          title="Media"
          type="all"
          icon={faCamera}
          loading={loading}
        />
      ) : (
        <div>
          {/* <div className="px-6 pt-4">
            <Form.Label className="text-white text-left w-100">
              PM TICKET #1
            </Form.Label>
            <span className="text-white upload-photo-text-pin">
              CHM-{animal ? animal.veterinary_record.pin : ""}
            </span>
            <Form.Label className="pt-3 text-white text-left w-100">
              RFID
            </Form.Label>
            <span className="text-white upload-photo-text-pin">
              #{animal ? animal.rfid : ""}
            </span>
          </div> */}
          <div className="d-flex upload-btn-container">
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              accept=".jpg, .jpeg, .png"
              multiple={true}
            />
            <button
              className={`upload-btn text-white rounded mt-6 `}
              style={{ maxWidth: "816px" }}
              onClick={uploadPhoto}
            >
              Upload Photo
            </button>
          </div>
          {showListPhoto.length === 0 ? (
            <div className="no-photo-container mt-3 mb-20">
              <div>
                <FontAwesomeIcon
                  icon={faImages}
                  color="white"
                  className="text-8xl"
                />
              </div>
              <div className="text-center mt-4">
                <p className="text-white">There are no photos</p>
              </div>
            </div>
          ) : (
            <div
              className="mt-6 photos-container"
              style={{ paddingBottom: "50px" }}
            >
              {showListPhoto.map((photoObj) => (
                <div
                  className="m-2 rounded px-2 pt-2 card-container"
                  key={photoObj.id}
                >
                  <div
                    className="card-photo-container"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src={photoObj.image}
                      className="card-photo"
                      onClick={() => openPhoto(photoObj)}
                      alt="photoAnimal"
                    />
                  </div>
                  <div className="align-self-start">
                    <button
                      className="text-white remove-photo-btn"
                      onClick={() => deletePhoto(photoObj)}
                    >
                      REMOVE
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="pb-2 block flex">
            <MUTooltip
              title="Back"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{ tooltip: "text-base" }}
            >
              { <Icon
                onClick={handleClickBack}
                icon={faArrowLeft}
                size="45"
                className="m-auto cursor-pointer"
                style={{ display: "block" }}
              /> }
            </MUTooltip>
            <MUTooltip
              title="Save"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{ tooltip: "text-base" }}
            >
              <Icon
                onClick={handleAddMedia}
                icon={faCheck}
                size="45"
                className="m-auto cursor-pointer"
                style={{ display: "block" }}
              />
            </MUTooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(UploadPhoto);
