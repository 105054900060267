import { connect } from "react-redux";
import CustomDropDown from "../customDropDown";

const Pagination = ({
    pagination,
    currentPage,
    setCurrentPage,
    perPage = 20
}) => {    
    const calculatePages = () => {
        return Math.ceil(pagination.total / perPage);
    };

    const createPages = () => {
        let pages = [];
        for (let i = 1; i <= calculatePages(); ++i) {
            pages.push({
                label: `Page ${i}`,
                id: i
            });
        }

        return pages;
    };

    const paginationSelectionOptions = createPages();
    const selectedOption = paginationSelectionOptions.find(
        (val) => val.id === currentPage
    );

    return (
        <CustomDropDown
            optionsList={paginationSelectionOptions}
            itemText="No pages"
            selectedOption={selectedOption}
            placeholderTitle="Select a page"
            onSelect={(e) => setCurrentPage(Number(e))}
            theme="dark"
            customMenu={true}
            hideFilter={true}
            menuCanOpen={true}
        />
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
