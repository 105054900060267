import animalTypes from "./animalTypes";

// ANIMALS

export const getAnimal = (animalId) => ({
  type: animalTypes.GET_ANIMAL_REQUEST,
  payload: { animalId },
});

export const getAnimals = () => ({
  type: animalTypes.GET_ANIMALS_REQUEST,
});

export const addAnimal = (animal) => ({
  type: animalTypes.ADD_ANIMAL_REQUEST,
  payload: { animal },
});

export const updateAnimal = (animal) => ({
  type: animalTypes.UPDATE_ANIMAL_REQUEST,
  payload: { animal },
});

export const deleteAnimal = (animalId) => ({
  type: animalTypes.DELETE_ANIMAL_REQUEST,
  payload: { animalId },
});

// GROUPS

export const getGroup = (groupId) => ({
  type: animalTypes.GET_GROUP_REQUEST,
  payload: { groupId },
});

export const updateGroup = (group) => ({
  type: animalTypes.UPDATE_GROUP_REQUEST,
  payload: { group },
});

export const getGroups = () => ({
  type: animalTypes.GET_GROUPS_REQUEST,
});

export const getAnimalGroups = (animalId) => ({
  type: animalTypes.GET_ANIMAL_GROUPS_REQUEST,
  payload: { animalId },
});

export const getGroupAnimals = (groupId) => ({
  type: animalTypes.GET_GROUP_ANIMALS_REQUEST,
  payload: { groupId },
});

export const attachAnimalToGroup = (animalId, groupId) => ({
  type: animalTypes.ATTACH_ANIMAL_TO_GROUP_REQUEST,
  payload: { animalId, groupId },
});

export const detachAnimalFromGroup = (animalId, groupId) => ({
  type: animalTypes.DETACH_ANIMAL_FROM_GROUP_REQUEST,
  payload: { animalId, groupId },
});

// RECORDS

export const getRecord = (recordType, recordId) => ({
  type: animalTypes.GET_RECORD_REQUEST,
  payload: { recordType, recordId },
});

export const getRecords = (animalId, recordType) => ({
  type: animalTypes.GET_RECORDS_REQUEST,
  payload: { animalId, recordType },
});

export const addRecord = (recordType, record) => ({
  type: animalTypes.ADD_RECORD_REQUEST,
  payload: { recordType, record },
});

export const updateRecord = (recordType, record) => ({
  type: animalTypes.UPDATE_RECORD_REQUEST,
  payload: { recordType, record },
});

export const deleteRecord = (recordType, recordId, onSucc) => ({
  type: animalTypes.DELETE_RECORD_REQUEST,
  payload: { recordType, recordId, onSucc },
});

export const getTags = () => ({
  type: animalTypes.GET_TAGS_REQUEST,
});

export const addTag = (tag) => ({
  type: animalTypes.ADD_TAG_REQUEST,
  payload: { tag },
});

export const getReports = () => ({
  type: animalTypes.GET_REPORTS_REQUEST,
});

export const addReport = (report) => ({
  type: animalTypes.ADD_REPORT_REQUEST,
  payload: { report },
});
