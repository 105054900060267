import Empty from "../../../components/common/Empty";
import PostMortemTabRowFull from "./PostMortemTabRowFull";
import PostMortemTabRowSmall from "./PostMortemTabRowSmall";

const PostMortemTabRows = (props) => {
    if (!props.records.length) {
        return <Empty text={`You have no ${props.tab} PMs! Click the plus icon to start one.`} />
    }
   
    if (props.showPostMortemSmall) { 
        return <PostMortemTabRowSmall
            tab={props.tab}
            records={props.records}
        />
    }

    return <PostMortemTabRowFull
        records={props.records}
        confirmModalAction={props.confirmModalAction}
    />

}

export default PostMortemTabRows;
