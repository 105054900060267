import {all} from "redux-saga/effects";
import authSagas from "./auth/authSagas";
import animalSagas from "./animals/animalSagas";
import librarySagas from "./library/librarySagas";
import connectionSagas from "./connections/connectionSagas";
import postmortemSagas from "./postmortem/postmortemSagas";
import vetAnimalSagas from "./vetanimal/vetAnimalSagas";
import userSagas from "./user/userSagas";
import customerSagas from "./customers/customerSagas";
import siteSagas from "./sites/siteSagas";
import mediaSagas from "./media/mediaSagas";
import notificationSagas from "./notifications/notificationSaga";
import inviteSagas from './invites/inviteSagas';
import trainingSagas from './training/trainingSagas';
import searchGetPostmortemsSagas from "./search/searchSagas";

export default function* rootSaga() {
  yield all([
    authSagas(),
    animalSagas(),
    postmortemSagas(),
    connectionSagas(),
    librarySagas(),
    vetAnimalSagas(),
    userSagas(),
    customerSagas(),
    siteSagas(),
    notificationSagas(),
    inviteSagas(),
    mediaSagas(),
    trainingSagas(),
    searchGetPostmortemsSagas()
  ]);
}
