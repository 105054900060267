import React, { useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";

import {
  faArrowLeft,
  faPencilAlt,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";

import MUTooltip from '@material-ui/core/Tooltip';

import Icon from "../../../components/common/Icon/Icon";
import PageWrapper from "../../../components/common/PageWrapper/PageWrapper";

import { withRouterAndRef } from "../../../HOCs/withRouterAndRef";

const ProfileDetails = forwardRef(({ history }, ref) => {
  const user = useSelector((state) => state.user);

   useEffect(() => {
    // if (ref.current) {
    //   ref.current.scrollTo(0,0)
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const renderUserDetails = () => {
    const unnecessaryFields = [
      "id",
      "created_at",
      "updated_at",
      "deleted_at",
      "email_verified_at",
      "customers",
      "remember_token",
      "roles",
      "pin",
      "status",
      "loading",
      "list"
    ];

    return Object.keys(user)
      .filter((field) => !unnecessaryFields.includes(field))
      .map((_field) => (
        <div key={_field} className="px-6 pt-4">
          <Form.Label className="text-white text-left w-100">
            {_field.charAt(0).toUpperCase() + _field.slice(1).replace("_", " ")}
          </Form.Label>
          <span
            className={`${
              user[_field] ? "text-white" : "text-secondary"
            } text-2xl text-break`}
          >
            {user[_field] || "Not specified"}
          </span>
        </div>
      ));
  };

  return (
    <PageWrapper title={"Profile Details"} type="all" icon={faUser}>
      {renderUserDetails()}
      <div className="mt-20 mb-20 block flex">
        <div className="flex-1">
          <MUTooltip
            title="Back"
            enterDelay={500}
            leaveDelay={200}
            placement="top"
            classes={{
              tooltip: "text-base ",
            }}
          >
            <Icon
              icon={faArrowLeft}
              size="45"
              className="m-auto cursor-pointer"
              style={{ display: "block" }}
              onClick={() => {
                history.goBack();
              }}
            />
          </MUTooltip>
        </div>
        <div className="flex-1">
        <MUTooltip
          title="Edit Profile"
          enterDelay={500}
          leaveDelay={200}
          placement="top"
          classes={{
            tooltip: "text-base ",
          }}
        >
          <Icon
            icon={faPencilAlt}
            size="45"
            className="m-auto cursor-pointer"
            style={{ display: "block" }}
            onClick={() => { history.push("/a/profile/edit") }}
          />
        </MUTooltip>
        </div>
      </div>
    </PageWrapper>
  );
});

export default withRouterAndRef(ProfileDetails);
