import connectionTypes from "./connectionTypes";

export const connect = () => ({
  type: connectionTypes.CONNECT_ASYNC,
});

export const reconnect = () => ({
  type: connectionTypes.RECONNECT_ASYNC,
});

export const disconnect = () => ({
  type: connectionTypes.DISCONNECT_ASYNC,
});

export const pause = () => ({
  type: connectionTypes.PAUSE,
});

export const unpause = () => ({
  type: connectionTypes.UNPAUSE,
});

export const suspend = () => ({
  type: connectionTypes.SUSPEND,
});

export const unsuspend = () => ({
  type: connectionTypes.UNSUSPEND,
});

export const setConfig = (config) => ({
  type: connectionTypes.SET_CONFIG_ASYNC,
  payload: config,
});
