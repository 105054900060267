import { takeLatest, all } from "redux-saga/effects";
import libraryTypes from "./libraryTypes";
import {
  getAnimalColor,
  getAnimalColors,
  getUnitOfMeasure,
  getSpecies,
  getDiagnosis,
  getHealthRisk,
  getTreatment,
  getBreed,
  getUnitsOfMeasure,
  getSpeciesList,
  getAnimalTypes,
  getDiagnoses,
  getHealthRisks,
  getTreatments,
  getBreeds,
  getAnimalType,
  getTagColors,
  getAnimalRecords,
} from "./libraryActions";

export default function* librarySagas() {
  yield all([
    yield takeLatest(libraryTypes.GET_ANIMAL_COLOR_REQUEST, getAnimalColor),
    yield takeLatest(
      libraryTypes.GET_UNIT_OF_MEASURE_REQUEST,
      getUnitOfMeasure
    ),
    yield takeLatest(libraryTypes.GET_SPECIES_REQUEST, getSpecies),
    yield takeLatest(libraryTypes.GET_ANIMAL_TYPE_REQUEST, getAnimalType),
    yield takeLatest(libraryTypes.GET_DIAGNOSIS_REQUEST, getDiagnosis),
    yield takeLatest(libraryTypes.GET_HEALTH_RISK_REQUEST, getHealthRisk),
    yield takeLatest(libraryTypes.GET_TREATMENT_REQUEST, getTreatment),
    yield takeLatest(libraryTypes.GET_BREED_REQUEST, getBreed),
    yield takeLatest(libraryTypes.GET_ANIMAL_COLORS_REQUEST, getAnimalColors),
    yield takeLatest(
      libraryTypes.GET_UNITS_OF_MEASURE_REQUEST,
      getUnitsOfMeasure
    ),
    yield takeLatest(libraryTypes.GET_SPECIES_LIST_REQUEST, getSpeciesList),
    yield takeLatest(libraryTypes.GET_ANIMAL_TYPES_REQUEST, getAnimalTypes),
    yield takeLatest(libraryTypes.GET_DIAGNOSES_REQUEST, getDiagnoses),
    yield takeLatest(libraryTypes.GET_HEALTH_RISKS_REQUEST, getHealthRisks),
    yield takeLatest(libraryTypes.GET_TREATMENTS_REQUEST, getTreatments),
    yield takeLatest(libraryTypes.GET_BREEDS_REQUEST, getBreeds),
    yield takeLatest(libraryTypes.GET_TAG_COLORS_REQUEST, getTagColors),
    yield takeLatest(libraryTypes.GET_ANIMAL_RECORDS_REQUEST, getAnimalRecords),
  ]);
}
