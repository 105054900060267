import React from "react";
import AuthFrame from "../frames/AuthFrame";
import AuthRouter from "../routers/AuthRouter/AuthRouter";

class PublicView extends React.Component {
  render() {
    return (
      <AuthFrame>
        <AuthRouter/>
      </AuthFrame>
    );
  }
}

export default PublicView;
