import PostMortemTicket from "../PostMortemTicket/PostMortemTicket";
import { connect } from "react-redux";
import { scheduledOrCompletePostmortem } from "../../../store/postmortem";

const PostMortemTabRowsFull = (props) => {
    
    return Object.keys(props.records).map((index) => {
        const pm = props.records[index]

        if (!pm.veterinary_record) {
            return <></>
        }

        const updatePostmortemFromModal = (postmortemUpdateInfo) => {
            props.scheduledOrCompletePostmortem(postmortemUpdateInfo);
        }

        return (
            <PostMortemTicket 
                {...pm}
                key={pm.id+'-'+index}
                vet={true}
                vetList={props.vets}
                updatePostmortem={null}
                confirmModalAction={(ticketContext) => props.confirmModalAction(pm, ticketContext)}
                actions={['view']}
                updatePostmortemFromModal={(postmortem) => updatePostmortemFromModal(postmortem)}
            />
        );

    });

}

const mapStateToProps = (state) => {
    const admin = {
        city: state.user?.city,
        country: state.user?.country,
        created_at: state.user?.created_at,
        customers: state.user?.customers,
        deleted_at: state.user?.deleted_at,
        email: state.user?.email,
        email_verified_at: state.user?.email_verified_at,
        first_name: state.user?.first_name,
        id: state.user?.id,
        last_name: state.user?.last_name,
        phone: state.user?.phone,
        pin: state.user?.pin,
        region: state.user?.region,
        remember_token: state.user?.remember_token,
        roles: state.user?.roles,
        updated_at: state.user?.updated_at
    };

    let updatedVets = [];

    if (Array.isArray(state.user?.list)) {
        updatedVets = [...state.user.list];
    } else if (state.user?.list && typeof state.user.list === 'object') {
        updatedVets = Object.values(state.user.list);
    } else {
        console.error('Expected state.user.list to be an array or object, but got:', state.user?.list);
    }

    // updatedVets.push(admin);

    return {
        vets: updatedVets,
        admin: admin // Optional, in case you still want to return admin separately
    };
};


  
const mapDispatchToProps = {
    scheduledOrCompletePostmortem,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostMortemTabRowsFull);