import types from "./notificationTypes";

const initialState = ''

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_NOTIFICATION:
      return '';
    case types.SET_NOTIFICATION_SUCCESS:
      return action.payload
    default: {
      return state;
    }
  }
};
