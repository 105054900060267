import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";

import { login, authenticate } from "../../../store/auth";

import Loading from "../../../components/common/Loading/Loading";
import PasswordInput from "../../../components/common/PasswordInput";
import history from "../../../utils/history";

import "./LoginScreen.css";

const LoginScreen = ({ login, authenticate, authenticated }) => {
  const [user, setUser] = useState({
    email: "",
    password: ""
  });
  const [error, setError] = useState({
    email: "",
    password: ""
  });

  const loading = useSelector((state) => state.loading.LOGIN);

  useEffect(() => {
    authenticate();
    if (authenticated) {
      // history.push("/a/");
    }
    //eslint-disable-next-line
  }, [authenticated, history]);

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: "" });
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newError = {};

    if (user.email.trim() === "") {
      newError.email = "Email is required.";
    }

    if (user.password.trim() === "") {
      newError.password = "Password is required.";
    }

    if (newError.email || newError.password) {
      return setError(newError);
    }

    login(user.email, user.password);
  };

  if (loading) {
    return (
      <div className="login-container my-auto">
        <Loading visible={loading} />
      </div>
    );
  }

  return (
    <div className="login-container w-100">
      <Form className="login-button-container d-flex flex-column mx-auto">
        <Form.Group className="w-100">
          <Form.Label className="text-white text-left w-100">Email</Form.Label>
          <Form.Control
            name="email"
            className="w-100 mb-2 rounded"
            placeholder="Email"
            type="text"
            value={user.email}
            onChange={onChange}
          />
          <span className="error-container text-danger text-sm">
            {error.email}
          </span>
        </Form.Group>
        <PasswordInput
          inputLabel="Password"
          inputName="password"
          placeholder="Password"
          passwordValue={user.password}
          error={error.password}
          handleOnChange={onChange}
        />
        <div className="recover-container w-100">
          <Link className="recover-password-text text-white" to="/u/reset">
            Reset my password
          </Link>
        </div>
        <button
          className="login-button rounded"
          type="submit"
          onClick={onSubmit}
          disabled={loading}
        >
          LOGIN
        </button>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  loading: state.auth.loading,
});

const mapStateToDispatch = {
  login,
  authenticate,
};

export default connect(mapStateToProps, mapStateToDispatch)(LoginScreen);
