import React, { Component } from 'react';
import styles from './Logo.module.css';
// import svg from '../../../assets/CVC-logo.svg';
import logo from '../../../assets/CHM-Logo.svg';
import logo_white from '../../../assets/CHM-Logo-white.svg';
import { SvgLoader, SvgProxy } from 'react-svgmt';

export default class Logo extends Component {
  render() {
    return (
      <div style={this.props.style} onClick={this.props.onClick}>
        <SvgLoader path={this.props.fill ? logo_white : logo} className={styles.Logo}>
          <SvgProxy selector="#main" fill={this.props.fill || "#0051A0"} />
        </SvgLoader>
      </div>
    )
  }
}
