import types from './vetAnimalTypes';
import {defaultVetAnimal} from './index';

const initialState =  
{
    new: defaultVetAnimal(),
    edited: {},
    list: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_VETANIMALS_SUCCESS:
            return {
                ...state,
                list: action.payload
            }
        case types.GET_VETANIMAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
            
        case types.ADD_VETANIMAL_SUCCESS:
            return {
                    ...state,
                    new: {...action.payload}
            };
        case types.DELETE_VETANIMAL_SUCCESS:
            return {
                    ...state,
                    new: {}
            };
        case types.UPDATE_VETANIMAL_REQUEST:
            return {
                ...state,
                edited: {...action.payload.animal}
            }
        default:
            return state;
    }
}