import types from "./postmortemTypes";
import { defaultPostmortem } from "./index";
import { sortPostmortem } from "../../../src/utils/helpers";

const initialState = defaultPostmortem();

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.DELETE_POSTMORTEM_REQUEST:
      return {
        ...state,
        currentPostmortem: {
          ...state.currentPostmortem,
          id: action.payload.id,
        },
        loading: true,
      };
    case types.ADD_POSTMORTEM_REQUEST:
    case types.GET_POSTMORTEMS_REQUEST:
    case types.GET_POSTMORTEM_REQUEST:
    case types.UPDATE_POSTMORTEM_REQUEST:
    case types.SCHEDULED_OR_COMPLETE_POSTMORTEM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.ADD_POSTMORTEM_SUCCESS:
      console.log('lll===>',action.payload, '==>',state.list)
      return {
        ...state,
        list: [
          ...state.list.filter((pm) => pm.id !== action.payload.id),
          action.payload,
        ],
        currentPostmortem: { ...state.currentPostmortem, ...action.payload },
        loading: false,
      };
    case types.POSTMORTEM_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
        loading: false,
      };
    case 'RESET_REPORT':
      return {
        ...state,
        report: null,
        loading: false,
      };
    case types.DELETE_POSTMORTEM_SUCCESS:
      return {
        ...state,
        currentPostmortem: { ...defaultPostmortem().currentPostmortem },
        list: [
          ...state.list.filter((pm) => pm.id !== state.currentPostmortem.id),
        ],
        loading: false,
      };
      case types.GET_POSTMORTEMS_SUCCESS:
        console.log('ACTION.PAYLOAD--', action.payload?.data.post_mortems);
        let newPostMortems = [];
        newPostMortems = action.payload.data.post_mortems;
        // if (state.list.length !== 0) {
        //   console.log('if part', state.list.length);
        //   // newPostMortems = action.payload.data.post_mortems.filter(postMortem => state.list.some(existingPostMortem => existingPostMortem.id == postMortem.id));
        //   console.log('22==>', newPostMortems, state.list);
        // } else {
        //   console.log('else part', state.list.length);
        //   const uniqueIds = new Set();
        //   newPostMortems = action.payload.data.post_mortems.filter(postMortem => {
        //     if (uniqueIds.has(postMortem.id)) {
        //       return false;
        //     }
        //     uniqueIds.add(postMortem.id);
        //     return true;
        //   });
        // }
        return {
          ...state,
          list: [...state.list, ...newPostMortems],
          loading: false,
          pagination: action.payload?.data?.pagination
        };
      
      case types.GET_MORE_POSTMORTEMS_SUCCESS :
        console.log('DATA IN LOADMORE-->',action.payload);
        const PostMortems = action.payload.data.post_mortems.filter(postMortem => state.list.some(existingPostMortem => existingPostMortem.id !== postMortem.id));
        return{
          ...state,
          list :[ ...state.list , ...PostMortems],
          loading :false,
          pagination :action.payload?.data?.pagination
        }
    case types.GET_POSTMORTEM_SUCCESS:
     
      return {
        ...state,
        currentPostmortem: { ...state.currentPostmortem, ...action.payload },
        list: [
          ...state.list.filter((pm) => pm.id !== action.payload.id),
          action.payload,
        ],
        loading: false,
      };
    case types.UPDATE_POSTMORTEM_SUCCESS:
    case types.SCHEDULED_OR_COMPLETE_POSTMORTEM_SUCCESS:
      return {
        ...state,
        currentPostmortem: { ...state.currentPostmortem, ...action.payload },
        list: sortPostmortem([
          ...state.list.filter((pm) => pm.id !== action.payload.id),
          action.payload,
        ]),
        loading: false,
      };
    case types.ADD_POSTMORTEM_FAILURE:
    case types.DELETE_POSTMORTEM_FAILURE:
    case types.GET_POSTMORTEMS_FAILURE:
    case types.GET_POSTMORTEM_FAILURE:
    case types.UPDATE_POSTMORTEM_FAILURE:
    case types.SCHEDULED_OR_COMPLETE_POSTMORTEM_FAILURE:
    case types.POSTMORTEM_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_LIST_STORE:
      return {
        ...state,
        list: []
      }
    default:
      return state;
  }
};
