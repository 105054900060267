import animalTypes from "./animalTypes";
import {put} from "redux-saga/effects";
import callApi from "../../utils/callApi";
import history from "../../utils/history";
import getSiteEndpoint from "../../utils/getSiteEndpoint";
import store from "../rootStore";

export function* getAnimal(action) {
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/animals/" + action.payload.animalId,
    type: "GET_ANIMAL",
    dataProp: "animal",
    errorMessage: "There was an error while getting an animal.",
  });
}

export function* getAnimals() {
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/animals",
    type: "GET_ANIMALS",
    dataProp: "animals",
    errorMessage: "There was an error while getting animals.",
  });
}

export function* addAnimal(action) {
  const {group_id, tag, ...animal} = action.payload.animal;
  if (!animal) yield put({type: animalTypes.ADD_ANIMAL_FAILURE});

  yield callApi({
    method: "post",
    url: getSiteEndpoint() + "/animals",
    type: "ADD_ANIMAL",
    dataProp: "animal",
    body: animal,
    errorMessage: "There was an error while adding an animal.",
    onSuccess: (data) => {
      if (group_id)
        store.dispatch({
          type: animalTypes.ATTACH_ANIMAL_TO_GROUP_REQUEST,
          payload: {animalId: data.id, groupId: group_id},
        });
      if (tag) {
        store.dispatch({
          type: animalTypes.ADD_TAG_REQUEST,
          payload: {
            animal_id: data.id,
            tag_color_id: tag.colorId,
            primary: 1,
            letter: tag.letter,
            number: tag.number,
          },
        })
      }
      history.push(`/a/animals/${data.id}?new`);
    },
  });
}

export function* updateAnimal(action) {
  const animal = action.payload.animal;
  if (!animal) yield put({type: animalTypes.UPDATE_ANIMAL_FAILURE});

  yield callApi({
    method: "put",
    url: `${getSiteEndpoint()}/animals/${animal.id}`,
    type: "UPDATE_ANIMAL",
    dataProp: "animal",
    body: animal,
    errorMessage: "There was an error while updating an animal.",
    onSuccess: (data) => history.push("/a/animals/" + data.id),
  });
}

export function* deleteAnimal(action) {
  yield callApi({
    method: "delete",
    url: `${getSiteEndpoint()}/animals/${action.payload.animalId}`,
    type: "DELETE_ANIMAL",
    reqData: {id: action.payload.animalId},
    errorMessage: "There was an error while deleting an animal.",
    onSuccess: () => history.push("/a/animals"),
  });
}

export function* getGroup(action) {
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/animal-groups/" + action.payload.groupId,
    type: "GET_GROUP",
    dataProp: "animal_group",
    errorMessage: "There was an error while getting a group.",
  });
}

export function* updateGroup(action) {
  const { group } = action.payload;
  if (!group) yield put({type: animalTypes.UPDATE_GROUP_FAILURE});
  yield callApi({
    method: "put",
    url: getSiteEndpoint() + "/animal-groups/" + group.id,
    type: "UPDATE_GROUP",
    dataProp: "animal_group",
    body: group,
    errorMessage: "There was an error while updating a group.",
  });
}

export function* getGroups() {
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/animal-groups",
    type: "GET_GROUPS",
    dataProp: "animal_groups",
    errorMessage: "There was an error while getting groups.",
  });
}

export function* getAnimalGroups(action) {
  const {animalId} = action.payload;
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/animals/${animalId}/groups`,
    type: "GET_ANIMAL_GROUPS",
    dataProp: "animal_groups",
    errorMessage: "There was an error while getting groups for an animal.",
    reqData: {id: animalId},
  });
}

export function* getGroupAnimals(action) {
  const {groupId} = action.payload;
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/animal-groups/${groupId}/animals`,
    type: "GET_GROUP_ANIMALS",
    dataProp: "animals",
    errorMessage: "There was an error while getting animals for a group.",
    reqData: {id: groupId},
  });
}

export function* attachAnimalToGroup(action) {
  const {animalId, groupId} = action.payload;
  yield callApi({
    method: "post",
    url: `${getSiteEndpoint()}/animals/${animalId}/groups/${groupId}`,
    type: "ATTACH_ANIMAL_TO_GROUP",
    dataProp: "animal_groups",
    errorMessage: "There was an error while attaching an animal to a group.",
    reqData: {animalId, groupId},
  });
}

export function* detachAnimalFromGroup(action) {
  const {animalId, groupId} = action.payload;
  yield callApi({
    method: "delete",
    url: `${getSiteEndpoint()}/animals/${animalId}/groups/${groupId}`,
    type: "DETACH_ANIMAL_FROM_GROUP",
    dataProp: "animal_groups",
    errorMessage: "There was an error while detaching an animal from a group.",
    reqData: {animalId, groupId},
  });
}

// RECORDS
export function* getRecord(action) {
  const {recordType, recordId} = action.payload;
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/${recordType}-records/${recordId}`,
    type: "GET_RECORD",
    dataProp: `${recordType}_record`,
    errorMessage: "There was an error while getting a record.",
    reqData: {recordType, recordId}
  });
}

export function* getRecords(action) {
  const {animalId, recordType} = action.payload;
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/animals/${animalId}/${recordType}-records`,
    type: "GET_RECORDS",
    dataProp: `${recordType}_records`,
    errorMessage: "There was an error while getting records.",
    reqData: {recordType, animalId}
  });
}

export function* addRecord(action) {
  const {recordType, record} = action.payload;
  if (!record) yield put({type: animalTypes.ADD_RECORD_FAILURE});

  yield callApi({
    method: "post",
    url: `${getSiteEndpoint()}/${recordType}-records`,
    type: "ADD_RECORD",
    dataProp: `${recordType}_record`,
    body: record,
    errorMessage: "There was an error while adding an animal record.",
    reqData: {recordType, record},
    onSuccess: (data) => {
      history.push(`/a/records/${data.id}?new`);
    },
  });
}

export function* updateRecord(action) {
  const {recordType, record} = action.payload;
  if (!record) yield put({type: animalTypes.UPDATE_RECORD_FAILURE});

  yield callApi({
    method: "put",
    url: `${getSiteEndpoint()}/${recordType}-records/${record.ID}`,
    type: "UPDATE_RECORD",
    dataProp: `${recordType}_record`,
    body: record,
    errorMessage: "There was an error while updating a record.",
    reqData: {recordType, record},
  });
}

export function* deleteRecord(action) {
  const {recordType, recordId, onSucc} = action.payload;
  yield callApi({
    method: "delete",
    url: `${getSiteEndpoint()}/${recordType}-records/${recordId}`,
    type: "DELETE_RECORD",
    reqData: {recordType, recordId},
    errorMessage: "There was an error while deleting an RECORD.",
    onSuccess: onSucc,
  });
}

export function* getTags() {
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/animal-tags",
    type: "GET_TAGS",
    dataProp: "animal_tags",
    errorMessage: "There was an error while getting tags.",
  });
}

export function* addTag(action) {
  const tag = action.payload;
  if (!tag) yield put({type: animalTypes.ADD_TAG_FAILURE});

  yield callApi({
    method: "post",
    url: `${getSiteEndpoint()}/animal-tags`,
    type: "ADD_TAG",
    dataProp: `animal_tag`,
    body: tag,
    errorMessage: "There was an error while adding an animal tag.",
  });
}

export function* getReports() {
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/filter-reports`,
    type: "GET_REPORTS",
    dataProp: `filter_reports`,
    errorMessage: "There was an error while getting filter reports.",
  });
}

export function* addReport(action) {
  const {report} = action.payload;
  if (!report) yield put({type: animalTypes.ADD_REPORT_FAILURE});

  yield callApi({
    method: "post",
    url: `${getSiteEndpoint()}/filter-reports`,
    type: "ADD_REPORT",
    dataProp: `filter_report`,
    body: report,
    errorMessage: "There was an error while adding an animal tag.",
    onSuccess: () => history.push('/a/reports'),
  });
}
