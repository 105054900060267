import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ReactReduxContext  } from "react-redux";
import { withRouter } from 'react-router-dom';
import Datetime from 'react-datetime';

import AnimalRow from '../AnimalRow/AnimalRow';
import { Modal } from '../../../components/common/Modal/Modal.js';
import CustomDropDown from '../customDropDown';

import  history  from '../../../utils/history';

import MUTooltip from '@material-ui/core/Tooltip';
import { resetReport } from '../../../store/postmortem';

import './PostMortemTicket.css';

class PostMortemTicket extends Component {

  static reduxContext = ReactReduxContext;

  constructor(props) {
    super(props);

    props.resetReport();

    this.state = {
      ...props.data,
      modal: {
        ...Modal.defaultState(),
        title: 'Schedule Post Mortem',
        description: '',
        body: null
      },
      error: null,
      date: null,
      updatePostmortem: null,
      pm_scheduled_for_id: props.veterinary_record.scheduled_for_id,
      pm_scheduled_at: props.veterinary_record.scheduled_at,
      pm_completed_at: props.veterinary_record.completed_at,
      filterValue: '',
      diagnoses: props.diagnoses,
      tagColor: props.tagColor,
    };

    this.toggleScheduleModalAction = this.toggleScheduleModalAction.bind(this);
    this.clickEditAction=this.clickEditAction.bind(this);
  }

  componentDidUpdate(prevProps) {

    if (this.props.veterinary_record && this.props.veterinary_record &&  (this.props.veterinary_record.scheduled_at !== prevProps.veterinary_record.scheduled_at)) {
      this.setState({...this.props});
    }
  }

  /* ========================================================================================================================
                                                    HELPERS
========================================================================================================================*/

  getID() {
    if (this.props.id) {
      let numbers = String(this.props.id).match(/\d+/g);
      if (numbers) {
        return numbers.join('').substring(0,5);
     }
    }
    return 99999;
  }

  getInitials() {
    let vetRecord = this.props.veterinary_record || { scheduled_for: { first_name: "Unknown", last_name: "Name" }};
    let firstName = vetRecord.scheduled_for.first_name;
    let lastName = vetRecord.scheduled_for.last_name;

    return <>{firstName?firstName[0]:"U"}{lastName?lastName[0]:"N"}</>
  }

  getVetName() {
    let vetRecord = this.props.veterinary_record || { scheduled_for: { first_name: "Unknown", last_name: "Name" } };
    let firstName = vetRecord.scheduled_for.first_name;
    let lastName = vetRecord.scheduled_for.last_name;

    return <>{firstName || ''} {lastName || ''}</>
  }

  getRequestedBy() {
    const vetRecord = this.props.veterinary_record.requested_by
    const firstName = vetRecord ? vetRecord.first_name : '';
    const lastName = vetRecord ? vetRecord.last_name : '';
    return <>{firstName} {lastName}</>
  }

  getCustomerName() {
   return this.props.veterinary_record.customer.name || '';
  }

  /* ========================================================================================================================
                                                    ACTIONS
========================================================================================================================*/

/**
 * I've left this in for future development. Will need it.
 */
toggleScheduleModalAction(bodyState) {

  let modalTitle = this.state.modal.title;
  switch(bodyState) {
    case 'schedule': modalTitle = "Schedule Postmortem"; break;
    case 'assign': modalTitle = 'Assign veterinarian'; break;
    case 'complete': modalTitle = 'Complete postmortem'; break;
    default: break;
  }

  this.setState({
    modal: {
      ...this.state.modal,
      visible: !this.state.modal.visible,
      title: modalTitle,
      body: bodyState,
    }
  });
}

clickEditAction(animalID = null) {
    if (animalID === null) {
      history.push('/a/vet/ticketdetail/' + this.props.id);
    } else {
      history.push('/a/vet/ticketdetail/' + this.props.id +'/'+animalID);
    }

}

addPhotoToAnimal(animalID = null) {
  if (animalID === null) {
    history.push(`/a/vet/ticketdetail/${this.props.id}/uploadPhoto`);
  } else {
    history.push(`/a/vet/ticketdetail/${this.props.id}/${animalID}/uploadPhoto`);
  }
}

closeAction() {
  this.setState({error: null});
}

  confirmModalAction() {
    switch (this.state.modal.body) {
      case 'assign':
        if (!this.state.pm_scheduled_for_id || this.state.pm_scheduled_for_id === 0) {
          this.setState({ error: "You must choose a veterinarian to assign to this postmortem" });
          return false;
        }
        this.props.updatePostmortemFromModal({
          id: this.props.id,
          scheduled_by_id:
            this.state.pm_scheduled_for_id === "unassignedId"
              ? ""
              : localStorage.uuid,
          scheduled_for_id:
            this.state.pm_scheduled_for_id === "unassignedId"
              ? ""
              : this.state.pm_scheduled_for_id,
        });
        break;
      case 'schedule':
        this.props.updatePostmortemFromModal({
          id: this.props.id,
          scheduled_at: this.state.pm_scheduled_at ? this.state.pm_scheduled_at : (new moment()).format('MM/DD/YYYY')
        });
        break;
      case 'complete':
        this.props.updatePostmortemFromModal({
          id: this.props.id,
          completed_at: this.state.pm_completed_at ? this.state.pm_completed_at : (new moment()).format('MM/DD/YYYY')
        });
        break;
      default:
        break;
    }

    this.setState({error: null});
    return true;
  }

  reportClick() {
    history.push(`/a/vet/ticketdetail/${this.props.id}/report`);
  }

/* ========================================================================================================================
                                                    MAIN RENDER
========================================================================================================================*/

  render() {
    let vetRecord = this.props.veterinary_record || {
      site: { name: 'Unknown Name', region: 'PL', city: 'Unknown' },
    };
    let isEditTicket = moment(
      this.props.veterinary_record.completed_at
    ).isValid();
    let site = vetRecord.site || {
      name: 'Unknown Name',
      region: 'PL',
      city: 'Unknown',
    };
    let animals = vetRecord.veterinary_record_animals || {};
    const selectedVet =
      this.state.pm_scheduled_for_id &&
      Object.values(this.props.vetList).find(
        (vet) => vet.id === this.state.pm_scheduled_for_id
      );
    const selectedUnassigned = {
      id: 'unassignedId',
      label: 'Unassigned',
    };
  // console.log('this.props.vetList====>>>>',this.props.vetList);
    const vetOptionsList =
      selectedVet !== null
        ? [
          selectedUnassigned,
            ...Object.values(this.props.vetList).map((vet) => ({
              id: vet.id,
              label:
                vet.first_name && vet.last_name
                  ? `${vet.first_name} ${vet.last_name}`
                  : "Unknown name",
            })),
          ]
        : Object.values(this.props.vetList).map((vet) => ({
            id: vet.id,
            label:
              vet.first_name && vet.last_name
                ? `${vet.first_name} ${vet.last_name}`
                : "Unknown name",
          }));

    const selectedVetForDropdown = selectedVet
      ? {
          id: selectedVet.id,
          label:
            selectedVet.first_name && selectedVet.last_name
              ? selectedVet.first_name + " " + selectedVet.last_name
              : "Unknown name",
        }
      : { id: selectedUnassigned.id, label: selectedUnassigned.label };
    const dateString = !vetRecord.completed_at
      ?  vetRecord.scheduled_at
        ? `Scheduled: ${moment.parseZone(vetRecord.scheduled_at).local(true).format(' MMMM Do')}`
        : `Submitted: ${moment(this.props.created_at).format(' MMMM Do')}`
      : `Submitted: ${moment.parseZone(this.props.created_at).format(' MMMM Do')}`;

    const scheduleDate = this.state.pm_scheduled_at
    ? moment.parseZone(this.state.pm_scheduled_at).local(true).format('MM/DD/YYYY')
    : (new moment()).format('MM/DD/YYYY');

    const completedDate = this.state.pm_completed_at
    ? moment.parseZone(this.state.pm_completed_at).local(true).format('MM/DD/YYYY')
    : (new moment()).format('MM/DD/YYYY');

    // const isInboxUrl = this.props.match.params.tab !== 'inbox';
    const isCompleteTab = this.props.match.params.tab === 'complete';

    let alert = (text, type) => {
      let clss = '';
      switch(type) {
        case "none": return;
        case "warn": clss = "bg-yellow-300"; break;
        case "danger": clss = "bg-red-700"; break;
        default: clss = "bg-blue-400";

      }
      return <div className={`text-center p-1 m-1 ${clss} text-sm font-bold uppercase text-black`}>{text}</div>;
    };
    let text, type = "none";
    if (this.state.pm_completed_at === null && this.state.pm_scheduled_at !== null && this.state.pm_scheduled_for_id !== null && moment(scheduleDate).isBefore(moment(), 'day')) {
      text = "Overdue"; type = "danger";
    } else if (this.state.pm_scheduled_for_id !== null && this.state.pm_scheduled_at === null) {
      text = "Not Scheduled"; type = "warn";
    } else if (this.state.pm_scheduled_for_id === null && this.state.pm_scheduled_at !== null) {
      text = "No Vet Assigned"; type = "warn";
    }

    return (
      <div className="text-white border border-white rounded-med rounded-b-xl mt-4 mb-4">

        {alert(text, type)}

        <div className="p-3">

    {/* VET INITIALS */}
    {vetRecord.scheduled_for && (
            <span className="float-right">{this.getVetName()}</span>
                // <MUTooltip
                // enterDelay={500}
                // leaveDelay={200}
                // placement="top"
                // title=""
                // classes={{
                //   tooltip: "text-base",
                // }}
                // >
                //   <span
                //     className="fa-stack flex-initial text-right float-right mr-0 p-0"
                //     style={{ width:"2.5rem" }}
                //   >
                //     <i className="fas fa-circle fa-stack-1x text-white" style={{ fontSize: "2.05rem" }} />
                //     <i className="fa-stack-1x text-black font-bold not-italic " style={{ fontSize: "1.05rem" }}>{this.getInitials()}</i>
                //   </span>
                // </MUTooltip>
              )}

    {/* END VET INITIALS */}
          <span
            className="cursor-pointer"
            onClick={() => this.clickEditAction()}
          >
            <h5 className="font-bold">{`CHM-${vetRecord.pin}` || "CHM-###"}</h5>
          </span>

          <h6 className="flex">
            <span className="flex-1">
              { this.getCustomerName() } - {site.name || "Unknown Name" }
              <span className="block text-xs">{dateString}</span>
            </span>
          </h6>
          <small>
            submitted by: { this.getRequestedBy() }
          </small>
          <div>

          </div>
        </div>

        {Object.keys(animals).map(i => (
          <AnimalRow
            key={i}
            data={animals[i]}
            actions={{
              view: true,
              uploadPhoto: this.props.vet ? () => this.addPhotoToAnimal(animals[i].id) : false,
              edit: this.props.vet && !isEditTicket ? () => this.clickEditAction(animals[i].id) : false,
              check: !!(animals[i].vet_diagnosis && animals[i].vet_findings)
            }}
          />
        ))}

        <Modal
          {...this.state.modal}
          toggle={this.toggleScheduleModalAction}
          closeAction={this.closeAction.bind(this)}
          confirmAction={() => {return this.confirmModalAction()}}
        >
          {this.state.error && <div className="alert alert-danger"> {this.state.error} </div>}
            {this.state.modal.body === 'schedule' && (
              <>
                <b>Choose a date</b>
                <Datetime
                  timeFormat={false}
                  inputProps={{
                    style: { 'color': '#000' },
                    readOnly: true,
                    className: 'form-control rounded'
                  }}
                  value={scheduleDate}
                  onChange={e => {
                    if (e instanceof moment)
                      this.setState({ pm_scheduled_at: e.format("YYYY-MM-DD HH:mm:ss") });
                  }}
                />
              </>
            )}
            {this.state.modal.body === "assign" && (
              <CustomDropDown
                optionsList={vetOptionsList}
                selectedOption={selectedVetForDropdown}
                placeholderTitle="Choose a vet"
                onSelect={(_, e) =>
                  this.setState({ pm_scheduled_for_id: e.target.id })
                }
                theme="light"
                customMenu={true}
                menuCanOpen={true}
              />
            )}
            {this.state.modal.body === 'complete' &&
              <>
                <b>Choose a date</b>
                <Datetime
                  timeFormat={false}
                  inputProps={{
                    style: { 'color': '#000' },
                    readOnly: true,
                    className: 'form-control rounded'
                  }}
                  value={completedDate}
                  onChange={e => {
                    if (e instanceof moment)
                      this.setState({ pm_completed_at: e.format("YYYY-MM-DD HH:mm:ss") });
                  }}
                />
              </>
            }
        </Modal>     
          <footer className="bg-white rounded-b-xl border border-white p-1 text-right">
            {!isEditTicket &&
              <div>
                <span
                  className="fa-stack flex-initial cursor-pointer mr-2"
                  style={{ width: '2rem' }}
                  onClick={() => { this.toggleScheduleModalAction('assign') }}
                >
                <MUTooltip
                  enterDelay={500}
                  leaveDelay={200}
                  placement="top"
                  title="Assign a vet"
                  classes={{
                    tooltip: "text-base",
                  }}
                  >
                    <span
                      className="fa-stack flex-initial text-right float-right mr-0 p-0"
                      style={{ width:"2.5rem" }}
                    >
                      <i className="fas fa-circle fa-stack-1x text-black" style={{ fontSize: '2rem' }} />
                      <i className="fas fa-syringe fa-stack-1x text-white" style={{ fontSize: '1rem' }} />
                    </span>
                </MUTooltip>
                </span>
                <span
                  className="fa-stack flex-initial cursor-pointer mr-2"
                  style={{ width: '2rem' }}
                  onClick={()=>{ this.toggleScheduleModalAction('schedule') }}
                >
                  <MUTooltip
                  enterDelay={500}
                  leaveDelay={200}
                  placement="top"
                  title="Schedule Postmortem"
                  classes={{
                    tooltip: "text-base",
                  }}
                  >
                    <span
                      className="fa-stack flex-initial text-right float-right mr-0 p-0"
                      style={{ width:"2.5rem" }}
                    >
                      <i className="fas fa-circle fa-stack-1x text-black" style={{ fontSize: '2rem' }} />
                      <i className="fas fa-calendar fa-stack-1x text-white" style={{ fontSize: '1rem' }} />
                    </span>
                </MUTooltip>
                </span>
                  {(!isCompleteTab) &&
                    <span
                      className="fa-stack flex-initial cursor-pointer"
                      style={{ width: '2rem' }}
                      onClick={()=>{ this.toggleScheduleModalAction('complete') }}
                    >
                      <i className="fas fa-circle fa-stack-1x text-black" style={{ fontSize: '2rem' }} />
                      <i className="fas fa-check fa-stack-1x text-white" style={{ fontSize: '.9rem' }} />
                    </span>
                  }
              </div>
            }
            {(isCompleteTab) &&
              <span
                className="fa-stack flex-inital cursor-pointer"
                style={{ width: '2rem' }}
                onClick={this.reportClick.bind(this)}
              >
                <MUTooltip
                  enterDelay={500}
                  leaveDelay={200}
                  placement="top"
                  title="Generate Report"
                  classes={{ tooltip: "text-base" }}
                >
                  <span className="fa-stack flex-inital text0right float-right mr=0 p-0" style={{ width: "2.5rem" }}>
                    <i className="fas fa-circle fa-stack-1x text-black" style={{ fonstSize: '2rem' }}/>
                    <i className="fas fa-file-chart-line fa-stack-1x text-white" style={{ fontSize: '.8rem' }} />
                  </span>
                </MUTooltip>
              </span>}
          </footer>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  resetReport,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostMortemTicket));
