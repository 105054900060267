export class AuthTokenProvider {
  auth = null
  storage = null;


  constructor(tokenStorage) {
    this.storage = tokenStorage
  }

  async load() {
    this.auth = await this.storage.getItem()
    if (!this.auth) {
      console.warn("No auth information to load")
    }
    return this.auth
  }

  async getAuth(options = {}) {
    if (!this.auth || options.forceReload === true) {
      await this.load()
    }
    return this.auth
  }

  async saveAuth(auth) {
    this.storage.setItem(auth)
    this.auth = auth
  }

  async getAccessToken(options = {}) {
    const auth = await this.getAuth(options)

    return auth ? auth.token : null
  }

  async getRefreshToken(options = {}) {
    const auth = await this.getAuth(options)

    return auth ? auth.refresh : null
  }

  async clear() {
    await this.storage.removeItem()
  }

  getStorage() {
    return this.storage
  }
}

// export const authTokenService = new AuthTokenService(axiosProvider(), tokenStorage);
