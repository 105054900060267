import React, { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "react-bootstrap";
import {
  faArrowLeft,
  faCheck,
  faPencilAlt,
} from "@fortawesome/pro-solid-svg-icons";

import MUTooltip from '@material-ui/core/Tooltip';

import Icon from "../../../components/common/Icon/Icon";
import PageWrapper from "../../../components/common/PageWrapper/PageWrapper";
import Loading from "../../../components/common/Loading/Loading";

import { withRouterAndRef } from "../../../HOCs/withRouterAndRef";

import { updateUser } from "../../../store/user";

const unnecessaryFields = [
  "id",
  "created_at",
  "updated_at",
  "deleted_at",
  "email_verified_at",
  "customers",
  "remember_token",
  "roles",
  "pin",
  "status",
  "loading",
  "list"
];

const EditDetails = forwardRef(({ history }, ref) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.loading.UPDATE_USER);

  const [formFields, setFormFields] = useState(() => {
    return Object.keys(user)
      .filter((field) => !unnecessaryFields.includes(field))
      .reduce((acc, _field) => {
        acc[_field] = user[_field];
        return acc;
      }, {});
  });

  useEffect(() => {
    // if (ref.current) {
    //   ref.current.scrollTo(0,0)
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeFields = (e) => {
    const { name, value } = e.target;

    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const sumbitForm = async () => {
    //TODO add validation

    dispatch(updateUser({ id: user.id, ...formFields }));
  };

  return (
    <>
      <PageWrapper title={"Edit Profile"} type="all" icon={faPencilAlt}>
        {loading ? (
          <Loading visible={loading} />
        ) : (
          <>
            <div className="px-6 pt-4">
              <Form.Label className="text-white text-left w-100">
                Email
              </Form.Label>
              <Form.Control
                name="email"
                placeholder="Email address"
                className="text-2xl text-white rounded"
                value={formFields.email || ""}
                plaintext
                disabled
              />
            </div>
            <div className="px-6 pt-4">
              <Form.Label className="text-white text-left w-100">
                First name
              </Form.Label>
              <Form.Control
                name="first_name"
                placeholder="First name"
                className="text-med rounded"
                onChange={onChangeFields}
                value={formFields.first_name || ""}
              />
            </div>
            <div className="px-6 pt-4">
              <Form.Label className="text-white text-left w-100">
                Last name
              </Form.Label>
              <Form.Control
                name="last_name"
                placeholder="Last name"
                className="text-med rounded"
                onChange={onChangeFields}
                value={formFields.last_name || ""}
              />
            </div>
            <div className="px-6 pt-4">
              <Form.Label className="text-white text-left w-100">
                Phone
              </Form.Label>
              <Form.Control
                name="phone"
                placeholder="Phone"
                className="text-med rounded"
                onChange={onChangeFields}
                value={formFields.phone || ""}
              />
            </div>
            <div className="px-6 pt-4">
              <Form.Label className="text-white text-left w-100">
                City
              </Form.Label>
              <Form.Control
                name="city"
                placeholder="City"
                className="text-med rounded"
                onChange={onChangeFields}
                value={formFields.city || ""}
              />
            </div>
            <div className="px-6 pt-4">
              <Form.Label className="text-white text-left w-100">
                Region
              </Form.Label>
              <Form.Control
                name="region"
                placeholder="Region"
                className="text-med rounded"
                onChange={onChangeFields}
                value={formFields.region || ""}
              />
            </div>
            <div className="px-6 pt-4">
              <Form.Label className="text-white text-left w-100">
                Country
              </Form.Label>
              <Form.Control
                name="country"
                placeholder="Country"
                className="text-med rounded"
                onChange={onChangeFields}
                value={formFields.country || ""}
              />
            </div>
            <div className="mt-20 mb-20 block flex">
              <div className="flex-1">
                <MUTooltip
                  title="Back"
                  enterDelay={500}
                  leaveDelay={200}
                  placement="top"
                  classes={{
                    tooltip: "text-base ",
                  }}
                >
                  <Icon
                    icon={faArrowLeft}
                    size="45"
                    className="m-auto cursor-pointer"
                    style={{ display: "block" }}
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                </MUTooltip>
              </div>
              <div className="flex-1">
                <MUTooltip
                  title="Complete Edit"
                  enterDelay={500}
                  leaveDelay={200}
                  placement="top"
                  classes={{
                    tooltip: "text-base ",
                  }}
                >
                  <Icon
                    icon={faCheck}
                    size="45"
                    className="m-auto cursor-pointer"
                    style={{ display: "block" }}
                    onClick={() => sumbitForm()}
                  />
                </MUTooltip>
              </div>
            </div>
          </>
        )}
      </PageWrapper>
    </>
  );
});

export default withRouterAndRef(EditDetails);
