import React, { Component } from "react";
import { Router, Switch } from "react-router-dom";
import history from "../../utils/history";
import DashBoard from '../../screens/training/DashBoard/DashBoard';
//import StubScreen from "../../screens/training/StubScreen/StubScreen";
import GenerateRouters from "../helpers/GenerateRouters";

const traningRouters = [
  // {
  //   path: "/a/training",
  //   component: DashBoard,
  //   permissionRoles: ["Veterinarian", "SuperAdmin", "Client"],
  // },
  {
    path: "/a/training",
    component: DashBoard,
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
  },
];

export default class TrainingRouter extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          {/* If you want to see the training screen, just uncomment the following line and comment out the line that directs to the stub */}
          {/* <Route exact path='/a/training' component={DashBoard} /> */}
          <GenerateRouters routersConst={traningRouters} />
        </Switch>
      </Router>
    );
  }
}
