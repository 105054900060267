import { put } from "@redux-saga/core/effects";
import callApi from "../../utils/callApi";
import getSiteEndpoint from "../../utils/getSiteEndpoint";
import customerTypes from "./customerTypes";
import { } from "redux-saga/effects";

export function* getAllData(url) {
  const next = url.payload?.url?.next;

  try {
    if (next) {
      const response = yield callApi({
        method: "get",
        url: getSiteEndpoint() + `/customers?page=${next}`,
        type: "GET_NEXT_CUSTOMERS",
        useFullResponse: false,
        dataProp: "customers",
        errorMessage: "There was an error while getting users.",
      });
      console.log("Response from first API:", response);
    }
    // Dispatch the success action with data and pagination
  } catch (error) {
    // Handle errors here
    console.error("Error while fetching customers:", error);
  }


}
export function* getCustomers() {
  try {
    yield callApi({
      method: "get",
      url: getSiteEndpoint() + "/customers",
      type: "GET_CUSTOMERS",
      useFullResponse: false,
      dataProp: "customers",
      errorMessage: "There was an error while getting users.",
      reqData: { per_page: 100 }
      // onSuccess: getAllData,
    });

  } catch (error) {
    // Handle errors here
    console.error("Error while fetching customers:", error);
  }
}

export function* getCustomer(action) {
  const { id } = action.payload;
  if (!id) yield put({ type: customerTypes.GET_CUSTOMER_FAILURE });
  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/customers/" + id,
    type: "GET_CUSTOMER",
    useFullResponse: false,
    dataProp: "customer",
    errorMessage: "There was an error while getting users.",
  });
}

export function* getCustomerSites(action) {
  const { userId } = action.payload;
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/users/${userId}/sites`,
    type: "GET_CUSTOMER_SITES",
    dataProp: "sites",
    errorMessage: "There was an error while getting sites.",
  });
}
