import types from './notificationTypes';
import userTypes from '../user/userTypes';
import authTypes from '../auth/authTypes';
import inviteTypes from '../invites/inviteTypes';
import mediaTypes from '../media/mediaTypes';
import postmortemTypes from '../postmortem/postmortemTypes';
import { takeLatest, all } from "redux-saga/effects";
import * as actions from './notificationActions';

export default function* notificationSagas() {
  yield all([
    yield takeLatest(userTypes.UPDATE_USER_SUCCESS, actions.setNotification),
    yield takeLatest(userTypes.UPDATE_USER_FAILURE, actions.setNotification),
    yield takeLatest(userTypes.UPDATE_PASSWORD_SUCCESS, actions.setNotification),
    yield takeLatest(userTypes.UPDATE_PASSWORD_FAILURE, actions.setNotification),
    yield takeLatest(authTypes.LOGIN_SUCCESS, actions.setNotification),
    yield takeLatest(authTypes.LOGIN_FAILURE, actions.setNotification),
    yield takeLatest(authTypes.RESET_CHALLENGE_FAILURE, actions.setNotification),
    yield takeLatest(types.SET_NOTIFICATION, actions.setNotification),
    yield takeLatest(types.SET_NOTIFICATION_SUCCESS, actions.clearNotification),
    yield takeLatest(inviteTypes.INVITE_CUSTOMER_SUCCESS, actions.setNotification),
    yield takeLatest(inviteTypes.INVITE_CUSTOMER_FAILURE, actions.setNotification),
    yield takeLatest(mediaTypes.GET_MEDIA_LIST_FAILURE, actions.setNotification),
    yield takeLatest(mediaTypes.ADD_MEDIA_FAILURE, actions.setNotification),
    yield takeLatest(mediaTypes.ADD_MEDIA_SUCCESS, actions.setNotification),
    yield takeLatest(postmortemTypes.SCHEDULED_OR_COMPLETE_POSTMORTEM_FAILURE, actions.setNotification),
    yield takeLatest(postmortemTypes.SCHEDULED_OR_COMPLETE_POSTMORTEM_SUCCESS, actions.setNotification),
    yield takeLatest(mediaTypes.DELETE_MEDIA_FAILURE, actions.setNotification),
    yield takeLatest(mediaTypes.DELETE_MEDIA_SUCCESS, actions.setNotification),
  ]);
}