// eslint-disable-next-line import/no-anonymous-default-export
export default {
    GET_USERS_REQUEST: "GET_USERS_REQUEST",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USERS_FAILURE: "GET_USERS_FAILURE",
    GET_USER_REQUEST: "GET_USER_REQUEST",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAILURE: "GET_USER_FAILURE",
    LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
    UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
    UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
    UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
}