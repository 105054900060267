import types from './trainingTypes';
import { takeLatest, all } from "redux-saga/effects";
import * as actions from './trainingActions';


export default function* customerSagas() {
    yield all([
        yield takeLatest(types.GET_TRAININGS_REQUEST, actions.getTrainings),
        yield takeLatest(types.GET_TRAINING_REQUEST, actions.getTraining),
    ]);
}