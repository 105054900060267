import React, { Component } from 'react';
import PageWrapper from '../../../components/common/PageWrapper/PageWrapper';
import styles from '../DashBoard/DashBoard.module.css';
// import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import { initialSite, getSite } from "../../../store/sites";
import * as md5 from 'md5';
import './Location.css';
import { faCalendar, faCheck, faDraftingCompass, faHourglass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PostMortemTab from '../../../components/common/PostMortemTab/PostMortemTab';

export class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site: initialSite(),
      tabs: {
        draft: false,
        pending: false,
        scheduled: false,
        complete: false
      },
      loading: true
    };

    // const tempLoading = false;

    this.turnOffTabs = this.turnOffTabs.bind(this);
    this.turnOn = this.turnOn.bind(this);

    this.props.getSite(this.props.match.params.id);
  }


  componentDidMount() {
    const tabFromUrl = this.props.match.params.tab ? this.props.match.params.tab : 'draft';
    this.setState({ tabs: { ...this.state.tabs, [tabFromUrl]: true } });
  }

  componentDidUpdate(prevProps) {
      if (this.state.loading !== this.props.loading) {
        this.setState({
          loading: this.props.loading
        });
      }

    if (md5(JSON.stringify(this.state.site)) !== md5(JSON.stringify(this.props.site))) {
      this.setState({
        ...this.state,
        site: {
          ...this.props.site
        }
      });
    }
  }
  /* ========================================================================================================================
                                                      HELPERS
  ========================================================================================================================*/
  turnOffTabs() {
    let tabs = { ...this.state.tabs };
    for (let tab in tabs) {
      tabs[tab] = false;
    }
    this.setState((state) => {
      state.tabs = tabs;
      return state;
    });
    return true;
  }
  turnOn(active) {
    let tabs = { ...this.state.tabs };

    for (let tab in tabs) {
      tabs[tab] = tab === active;
    }

    this.setState((state) => {
      state.tabs = tabs;
      return state;
    });

    this.props.history.push(`/a/location/${this.props.match.params.id}/${active}`);

    return true;
  }

  activeTab() {
    let tabs = { ...this.state.tabs };
    let name = "None";
    Object.entries(tabs).map(([key, tab]) => {
      if (tab) {
        name = key;
      }
      return null;
    });
    return name;
  }

  /* ========================================================================================================================
                                                      RENDER
  ========================================================================================================================*/

  render() {
    const { tabs } = this.state;
    const buttonClasses = 'w-100 text-white border-gre border-2 py-2 flex-1 rounded dashboardBtn';

    return (
      <PageWrapper
        loading={this.state.loading || this.tempLoading}
        title={this.state.site.name}
        type="all"
        // icon={faNotesMedical}
      >
        <div className="px-3 mb-6 flex">
          <button
            onClick={() => this.turnOn("draft")}
            className={`${tabs.draft ? styles.active : ""
              } mr-1 ${buttonClasses}`}
          >
            <FontAwesomeIcon icon={faDraftingCompass} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">DRAFT</span>
          </button>
          <button
            onClick={() => this.turnOn("pending")}
            className={`${tabs.pending ? styles.active : ""
              } mr-1 ${buttonClasses}`}
          >
              <FontAwesomeIcon icon={faHourglass} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">PENDING</span>
          </button>
          <button
            onClick={() => this.turnOn("scheduled")}
            className={`${tabs.scheduled ? styles.active : ""
              } mr-1 ${buttonClasses}`}
          >
            
              <FontAwesomeIcon icon={faCalendar} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">IN PROGRESS</span>
          </button>
          <button
            onClick={() => this.turnOn("complete")}
            className={`${tabs.complete ? styles.active : ""} ${buttonClasses}`}
          >
            <FontAwesomeIcon icon={faCheck} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">COMPLETE</span>
          </button>
        </div>
        <div className="px-6" style={{ paddingBottom: "100px" }}>
          <PostMortemTab
            tab={this.activeTab()}
            siteId={this.props.match.params.id}
            showPostMortemRow={true}
          />
        </div>
        </PageWrapper>
    )
  }
}
/* ========================================================================================================================
                                                    REDUX
========================================================================================================================*/
const mapStateToProps = (state) => {
  return {
    site: state.site,
    roles: state.auth.roles,
    loading: state.site.loading
  }
};

const mapDispatchToProps = {
  getSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
