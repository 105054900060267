import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeft, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUTooltip from "@material-ui/core/Tooltip";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Icon from '../../../components/common/Icon/Icon';
import PageWrapper from '../../../components/common/PageWrapper/PageWrapper';
import { loadUser } from "../../../store/auth";
import {
  defaultPostmortem,
  scheduledOrCompletePostmortem,
} from "../../../store/postmortem";
import { getSite } from "../../../store/sites";
import styles from './DashBoard.module.css';

import PostMortemTab from '../../../components/common/PostMortemTab/PostMortemTab';

export class DashBoard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      postmortem: defaultPostmortem(),
      tabs: {
        inbox: false,
        scheduled: false,
        inprogress: false,
        complete: false
      },
      loading: true,
      currentUser: this.props.auth.user,
      siteId: this.props.match.params.id !== "all" ? this.props.match.params.id : ''
    };

    this.turnOffTabs = this.turnOffTabs.bind(this);
    this.turnOn = this.turnOn.bind(this);

    if (this.props.match.params.id !== 'all') {
      props.getSite(this.props.match.params.id);
    }

  }

  componentDidMount() {
    const tabFromUrl = this.props.match.params.tab ? this.props.match.params.tab : 'inbox';

    this.setState({
      tabs: { ...this.state.tabs, [tabFromUrl]: true },
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }

    if (this.state.currentUser !== this.props.auth.user) {
      this.setState({
        currentUser: this.props.auth.user
      });
    }

  }

  isAdminUser () {
    if (!this.state.currentUser || !this.state.currentUser.roles) {
      return false;
    }

    return this.state.currentUser.roles.includes('Admin') || this.state.currentUser.roles.includes('SuperAdmin');
  }

  /* ========================================================================================================================
                                                      HELPERS
  ========================================================================================================================*/

  turnOffTabs() {

    let tabs = { ...this.state.tabs };
    for (let tab in tabs) {
      tabs[tab] = false;
    }
    this.setState(state => {
      state.tabs = tabs;
      return state;
    });
    return true;
  }
  turnOn(active) {
    let tabs = { ...this.state.tabs };

    for (let tab in tabs) {
      tabs[tab] = tab === active;
    }

    this.setState(state => {
      state.tabs = tabs;
      return state;
    });
    this.props.history.push(`/a/location/${this.props.match.params.id}/${active}`);
    return true;
  }

  activeTab() {
    let tabs = { ...this.state.tabs };
    let name = "None";
    Object.entries(tabs).map(([key, tab]) => {
      if (tab) {
        name = key;
      }
      return null;
    });
    return name;
  }

  updatePostmortemFromModal(postmortemUpdateInfo) {
    this.props.scheduledOrCompletePostmortem(postmortemUpdateInfo);
  }

  /* ========================================================================================================================
                                                      ACTIONS
  ========================================================================================================================*/

  confirmModalAction(postmortem, ticketContext) {
    if (ticketContext.state.date == null) {
      ticketContext.setState({
        error: "Please choose a valid date"
      });
      return false;
    } else {
      this.props.scheduledOrCompletePostmortem({
        id: postmortem.id,
        scheduled_at: ticketContext.state.date,
        scheduled_by_id: localStorage.uuid,
        scheduled_for_id: localStorage.uuid
      });
    }

    return true; //dismiss the modal;
  }
  /* ========================================================================================================================
                                                      MAIN RENDER
  ========================================================================================================================*/

  render() {
    const buttonClasses = "text-white border-gre border-2 w-100 py-2 mt-2 flex-1";
    let site = this.props.site || {};

    return (
      <div className={`${styles.DashBoard}`}>
        <PageWrapper
          title={
            this.props.auth.user
              ? `${this.props.auth.user.first_name || "Unknown"} ${this.props.auth.user.last_name || "Name"
              }`
              : ""
          }
          type="all"
          // icon={faNotesMedical}
        >
          <p className="p-4 text-white text-sm">
            This is your veterinary scheduling console for the {site.name} location. 
            You have three tabs.  
            When a customer or another vet creates an outbound ticket it will appear in their pending folder at the same time as it is in your inbox. 
            When you are working on the record it will be "in progress".
          </p>
          <Link to={"/a/search"}>
            <p
              className="px-4 m-0 text-white text-xl text-right cursor-pointer"
              style={{
                textDecoration: "underline"
              }}
            >
              Search
            </p>
          </Link>
          <div className="text-white mb-3 mx-3 p-2">
            <div className="flex items-center">
              <div className="flex-1 p-0">
                <h5>{site.name}
                </h5>
              </div>
            </div>
          </div>
          <div className="px-3 mb-6 flex">
            <button
              className={`${this.state.tabs.inbox ? styles.active : ""
                } ${buttonClasses} ${styles.dashboardBtn}`}
              onClick={() => this.turnOn('inbox')}
            >
             
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">INBOX</span>
            </button>
            {/* <button
              className={`${this.state.tabs.scheduled ? styles.active : ""
                } ${buttonClasses} mx-1 ${styles.dashboardBtn}`}
              onClick={() => this.turnOn('scheduled')}
            >
                <FontAwesomeIcon icon={faCalendar} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">SCHEDULED</span>
            </button> */}
            <button
              className={`${this.state.tabs.inprogress ? styles.active : ""
                } ${buttonClasses} mx-1  ${styles.dashboardBtn}`}
              onClick={() => this.turnOn('inprogress')}
            >
                <FontAwesomeIcon icon={faSpinnerThird} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">IN PROGRESS</span>
            </button>
            <button
              className={`${this.state.tabs.complete ? styles.active : ""
                } ${buttonClasses} ${styles.dashboardBtn}`}
              onClick={() => this.turnOn('complete')}
            ><FontAwesomeIcon icon={faCheck} /> 
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">RECENTLY COMPLETE</span>
            </button>
          </div>
          <div style={{ paddingBottom: '100px' }}>
            <div className="px-6" style={{ paddingBottom: "100px" }}>
              <PostMortemTab
                tab={this.activeTab()}
                siteId={this.state.siteId}
                confirmModalAction={this.confirmModalAction}
                updatePostmortemFromModal={this.updatePostmortemFromModal}
                showLoadmoreButton ={true}
              />
            </div>
            <MUTooltip
              title="Create new Post mortem"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{
                tooltip: "text-base ",
              }}
            >
              <Icon
                icon={faArrowLeft}
                size="45"
                className={`${styles.new_post_mortem_btn} m-auto`}
                style={{ display: 'block', cursor: 'pointer' }}
                onClick={() => this.props.history.push('/a/locations')}
              />
            </MUTooltip>
          </div>
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const loading = () => {
    let hasLoad = false;
    Object.entries(state.loading).map(([key, val]) => {
      if (val && key !== 'GET_MEDIA_LIST') {
        hasLoad = true;
      }
      return null;
    });
    return hasLoad;
  }
  return {
    ...state,
    loading: loading(),
    currentUser: state.auth.user,
    site: state.site,
    tagDiagnoses: state.library.diagnoses
      ? Object.values(state.library.diagnoses)
      : [],
    tagColor: state.library.tagColors
      ? Object.values(state.library.tagColors)
      : [],
  };
};

const mapDispatchToProps = {
  loadUser,
  scheduledOrCompletePostmortem,
  getSite,
};
/* const mergeProps = (a,b,c) => {
  return {
    ...b,
    auth: a.auth
  }
}; */

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);