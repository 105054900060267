import React, { useState, useEffect, forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  faArrowLeft,
  faCheck,
  faEnvelopeOpenText,
  faUser
} from '@fortawesome/pro-solid-svg-icons';
import MUTooltip from '@material-ui/core/Tooltip';

import { getCustomers } from '../../store/customers';
import { inviteCustomer } from '../../store/invites';

import Icon from '../../components/common/Icon/Icon';
import CustomDropDown from '../../components/common/customDropDown';
import PageWrapper from '../../components/common/PageWrapper/PageWrapper';

import { withRouterAndRef } from '../../HOCs/withRouterAndRef';

import './InviteUser.css';

const InviteUser = forwardRef(({ history }, ref) => {
  const [email, setEmail] = useState('');
  const [sites, setSites] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [error, setError] = useState({
    email: '',
    sites: '',
    customer: '',
  });

  const dispatch = useDispatch();

  const notification = useSelector(state => state.notification);
  const customers = useSelector((state) => state.customer.list);
  const loading = useSelector((state) => state.loading.INVITE_CUSTOMER);

  useEffect(() => {
    if (!Object.keys(customers).length) {
      dispatch(getCustomers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    // if (ref.current) {
    //   ref.current.scrollTo(0, 0);
    // }
  }, [notification]);

  const onChange = (e) => {
    setEmail(e.target.value);

    setError({
      ...error,
      email: '',
    });
  };

  const onChangeCheckbox = (event) => {
    const newSites = sites.map((site) => {
      if (site.id === event.target.id) {
        return { ...site, selected: !site.selected };
      }
      return site;
    });
    setSites(newSites);
    setError({
      ...error,
      sites: '',
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newError = {
      email: '',
      sites: '',
      customer: '',
    };

    if (email.trim() === '') {
      newError.email = 'Email is required.';
    }
    if (selectedCustomer === null) {
      newError.customer = 'Customer is required.';
    }
    if (sites.length !== 0 && !sites.some((site) => site.selected)) {
      newError.sites = 'You need to choose a sites.';
    }
    if (selectedCustomer && sites.length === 0 ) {
      newError.customer = 'The chosen customer has no sites. Please choose another customer.';
    }

    if (newError.email || newError.sites || newError.customer) {
      setError(newError);
    } else {
      const invite = {
        email,
        sites: sites.filter((site) => site.selected),
        customer_id: selectedCustomer.id,
      };
      dispatch(inviteCustomer(invite));
    }
  };

  const customerOptionsList = Object.values(customers).map((tag) => ({
    id: tag.id,
    label: tag.name,
    sites: tag.sites,
  }));

  const selectCastomer = (_, e) => {
    const selectedCustomerObj = customerOptionsList.find(
      (el) => el.id === e.target.id
    );
    setSelectedCustomer({ id: e.target.id, label: selectedCustomerObj.label });
    const newSites = selectedCustomerObj.sites.map((el) => ({
      id: el.id,
      name: el.name,
      selected: false,
    }));
    setSites(newSites);
    setError({
      ...error,
      sites: '',
      customer: '',
    });
  };

  return (
    <div>
      <PageWrapper
        title={"Invite User"}
        type="all"
        icon={faEnvelopeOpenText}
        loading={loading}
      />
      {/* <div className="text-left w-100 invite-user-container d-flex mx-auto my-4 text-white">
        a lot of text
      </div> */}
      <div className="login-container w-100">
        <Form className="w-100 invite-user-container d-flex flex-column mx-auto">
          <Form.Group className="w-100">
            <Form.Label className="text-white text-left w-100">
              Email address
            </Form.Label>
            <Form.Control
              name="email"
              className="w-100 mb-2 rounded border-white text-xl"
              placeholder="Email"
              type="text"
              value={email}
              onChange={onChange}
            />
            <span className="error-container text-danger text-sm">
              {error.email}
            </span>
          </Form.Group>
          <div className="p-2 w-100 mb-2 flex items-center border rounded">
            <Icon
              icon={faUser}
              size="50"
              className="flex-initial pr-20"
            />
            <div className="flex-1" style={{ width: 'calc(100% - 80px)' }}>
              <Form.Label className="text-white text-left">Customer</Form.Label>
              <CustomDropDown
                smallText={true}
                optionsList={customerOptionsList}
                selectedOption={selectedCustomer}
                onSelect={selectCastomer}
                placeholderTitle="Please choose a customer"
                theme="dark"
                customMenu={true}
                menuCanOpen={true}
              />
            </div>
          </div>
          <div className="w-100">
            <span className="error-container text-danger text-sm">
              {error.customer}
            </span>
          </div>
          {sites.map((sites) => (
            <div
              key={sites.id}
              className="text-left w-100 d-flex flex-column mx-auto mb-3"
            >
              <Form.Check
                id={sites.id}
                type="checkbox"
                checked={sites.selected}
                onChange={(e) => onChangeCheckbox(e)}
                label={sites.name}
              />
            </div>
          ))}
          <div className="w-100">
            <span className="error-container text-danger text-sm">
              {error.sites}
            </span>
          </div>
          <div className="mt-20 mb-20 block flex w-100">
            <div className="flex-1">
              <MUTooltip
                title="Back"
                enterDelay={500}
                leaveDelay={200}
                placement="top"
                classes={{ tooltip: "text-base" }}
              >
                <Icon
                  icon={faArrowLeft}
                  size="45"
                  className="m-auto cursor-pointer"
                  style={{ display: "block" }}
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </MUTooltip>
            </div>
            <div className="flex-1">
              <MUTooltip
                title="Invite"
                enterDelay={500}
                leaveDelay={200}
                placement="top"
                classes={{ tooltip: "text-base" }}
              >
                <Icon
                  icon={faCheck}
                  size="45"
                  className="m-auto cursor-pointer"
                  style={{ display: "block" }}
                  onClick={onSubmit}
                />
              </MUTooltip>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
});

export default withRouterAndRef(InviteUser);
