import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { faArrowLeft, faCheck, faKey } from '@fortawesome/pro-solid-svg-icons';
import MUTooltip from '@material-ui/core/Tooltip';

import { updatePassword } from '../../store/user';

import Icon from '../../components/common/Icon/Icon';
import PasswordInput from '../../components/common/PasswordInput';
import PageWrapper from '../../components/common/PageWrapper/PageWrapper';

import history from '../../utils/history';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.UPDATE_PASSWORD);

  const [reset, setReset] = useState({
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState({
    password: '',
    confirmPassword: '',
  });

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: '' });
    setReset({
      ...reset,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newError = {};

    if (reset.password.trim() !== reset.confirmPassword.trim()) {
      newError.confirmPassword = "Passwords do not match.";
    }
    if (reset.password.trim().length < 8) {
      newError.password = "Password needs to be at least 8 characters long.";
    }
    if (reset.password.trim().match(/[A-Z]/g) === null) {
      newError.password = "Password needs to have at least 1 capital letter.";
    }
    if (reset.password.trim().match(/\d/g) === null) {
      newError.password = "Password needs to have at least 1 number.";
    }
    if (reset.password.trim().match(/[!@#$%^&*?]/g) === null) {
      newError.password =
        "Password needs to have at least 1 special character !@#$%^&*?";
    }

    if (newError.password || newError.confirmPassword) {
      setError(newError);
    } else {
      dispatch(updatePassword(reset.password));
    }
  };

  return (
    <div>
      <PageWrapper
        title={"Change Password"}
        type="all"
        icon={faKey}
        loading={loading}
      />
      <Form className="login-button-container d-flex flex-column mx-auto mt-5">
        <Form.Group className="w-100">
          <PasswordInput
            inputLabel="Set your new password"
            inputName="password"
            placeholder="Password"
            passwordValue={reset.password}
            error={error.password}
            handleOnChange={onChange}
          />
        </Form.Group>
        <Form.Group className="w-100">
          <PasswordInput
            inputLabel="Repeat your new password"
            inputName="confirmPassword"
            placeholder="Repeat password"
            passwordValue={reset.confirmPassword}
            error={error.confirmPassword}
            handleOnChange={onChange}
          />
        </Form.Group>
        <div className="mb-20 block flex w-100">
          <div className="flex-1">
            <MUTooltip
              title="Back"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{ tooltip: 'text-base' }}
            >
              <Icon
                icon={faArrowLeft}
                size="45"
                className="m-auto cursor-pointer"
                style={{ display: 'block' }}
                onClick={() => {
                  history.goBack();
                }}
              />
            </MUTooltip>
          </div>
          <div className="flex-1">
            <MUTooltip
              title="Complete Edit"
              enterDelay={500}
              leaveDelay={200}
              placement="top"
              classes={{ tooltip: 'text-base' }}
            >
              <Icon
                icon={faCheck}
                size="45"
                className="m-auto cursor-pointer"
                style={{ display: 'block' }}
                onClick={onSubmit}
              />
            </MUTooltip>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;
