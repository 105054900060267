import * as md5 from "md5";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { faLayerGroup, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faHourglass } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faDraftingCompass } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../components/common/Icon/Icon";
import PageWrapper from "../../../components/common/PageWrapper/PageWrapper";
import { defaultPostmortem } from "../../../store/postmortem";
import MUTooltip from "@material-ui/core/Tooltip";
import PostMortemTab from "../../../components/common/PostMortemTab/PostMortemTab";
import styles from "./DashBoard.module.css";

export class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        draft: false,
        pending: false,
        scheduled: false,
        complete: false,
      },
      shouldLoadMore: false,
      loadMoreCount: 0,
      postmortem: defaultPostmortem(),
    };

    this.turnOffTabs = this.turnOffTabs.bind(this);
    this.turnOn = this.turnOn.bind(this);
  }

  componentDidMount() {
    const tabFromUrl = this.props.match.params.tab
      ? this.props.match.params.tab
      : "draft";
    this.setState({ tabs: { ...this.state.tabs, [tabFromUrl]: true } });
  }

  componentDidUpdate(prevProps) {
    if (
      md5(JSON.stringify(this.state.postmortem)) !==
      md5(JSON.stringify(this.props.postmortem))
    ) {
      this.setState((state) => {
        return {
          postmortem: {
            ...this.props.postmortem,
          },
        };
      });
    }
  }
  /* ========================================================================================================================
                                                    HELPERS
========================================================================================================================*/
  turnOffTabs() {
    const tabs = { ...this.state.tabs };

    for (let tab in tabs) {
      tabs[tab] = false;
    }

    this.setState({
      ...this.state,
      tabs,
    });

    return true;
  }

  turnOn(active) {
    let tabs = { ...this.state.tabs };

    for (let tab in tabs) {
      tabs[tab] = tab === active;
    }

    this.setState({
      ...this.state,
      tabs,
    });

    this.props.history.push(`/a/postmortems/${active}`);

    return true;
  }

  getActiveTab() {
    const { tabs } = this.state;
    let activeTab;

    for (let key in tabs) {
      if (!!tabs[key]) {
        activeTab = key;
      }
    }
   console.log('ACTIVE TAB=>',activeTab);
    return activeTab;
  }

  isVet() {
    const { roles } = this.props;
    return roles.some((el) => el === "Veterinarian");
  }

  /* ========================================================================================================================
                                                    RENDER
========================================================================================================================*/

  render() {
    const buttonClasses = "text-white border-gre border-2 w-100 py-2 flex-1";

    return (
      <div className={styles.DashBoard}>
        <PageWrapper title="My PM Requests" type="all" icon={faLayerGroup}>
          <p className="p-4 text-white text-sm">
            These are the PM records <b>you have created</b>. Draft records have
            not been sent yet. Pending requests have been sent to a vet. A vet
            will respond to PM requests and you will automatically see your
            request move when it is in progress.
          </p>
          <Link to={"/a/search"}>
            <p
              className="px-4 m-0 text-white text-xl text-right cursor-pointer"
              style={{
                textDecoration: "underline",
              }}
            >
              Search
            </p>
          </Link>
          <div className="p-3 mb-6 flex">
            <button
              className={`${
                this.state.tabs.draft ? styles.active : ""
              } ${buttonClasses} ${styles.dashboardBtn} rounded`}
              onClick={() => {
                this.turnOn("draft");
              }}
            >
              <FontAwesomeIcon icon={faDraftingCompass} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">
                DRAFT
              </span>
            </button>

            <button
              className={`${
                this.state.tabs.pending ? styles.active : ""
              } ${buttonClasses} ml-1 ${styles.dashboardBtn} rounded`}
              onClick={() => {
                this.turnOn("pending");
              }}
            >
              <FontAwesomeIcon icon={faHourglass} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">
                PENDING
              </span>
            </button>
            <button
              className={`${
                this.state.tabs.scheduled ? styles.active : ""
              } ${buttonClasses} ml-1 ${styles.dashboardBtn} rounded`}
              onClick={() => {
                this.turnOn("scheduled");
              }}
            >
              <FontAwesomeIcon icon={faSpinnerThird} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">
                IN PROGRESS
              </span>
            </button>
            <button
              className={`${
                this.state.tabs.complete ? styles.active : ""
              } ${buttonClasses} ml-1 ${styles.dashboardBtn} rounded`}
              onClick={() => {
                this.turnOn("complete");
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
              <span className="xs:ml-2 text-xs block xs:inline xs:text-base">
                COMPLETE
              </span>
            </button>
          </div>
          <PostMortemTab
            showPostMortemRow={true}
            tab={this.getActiveTab()}
            isVet={this.isVet()}
            showLoadmoreButton ={true}
          />
         
          <MUTooltip
            title="Create new Post mortem"
            enterDelay={500}
            leaveDelay={200}
            placement="top"
            classes={{
              tooltip: "text-base ",
            }}
          >
            <Icon
              icon={faPlus}
              size="45"
              className={`${styles.new_post_mortem_btn} m-auto`}
              style={{ display: "block" }}
              onClick={() => this.props.history.push("/a/postmortem/add")}
            />
          </MUTooltip>
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postmortem: state.postmortem,
    roles: state.auth.roles,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
