import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getGroups, addReport, getReports } from "../../../store/animals";
import { getTagColors, getBreeds, getAnimalColors } from "../../../store/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faThermometer,
  faObjectGroup,
  faWeight,
  faCalendar,
  faCow,
  faBrush,
} from "@fortawesome/pro-light-svg-icons";
import { GridSelect } from "../../common";
import "./Filter.css";

const Filter = ({
  filter,
  setFilter,
  tagColors,
  getTagColors,
  groups,
  getGroups,
  breeds,
  getBreeds,
  animalColors,
  getAnimalColors,
  reports,
  getReports,
  addReport,
  className,
}) => {

  useEffect(() => {
    if (!tagColors) getTagColors();
    if (!groups) getGroups();
    if (!breeds) getBreeds();
    if (!animalColors) getAnimalColors();
    if (!reports) getReports();
    window.scroll(0, 0);
    //eslint-disable-next-line
  }, []);

  const filterTypes = {
    tagColor: {
      icon: faTag,
      type: 'SELECT',
      datatype: 'string',
    },
    temperature: {
      icon: faThermometer,
      type: 'RANGE',
      datatype: 'number',
    },
    weight: {
      icon: faWeight,
      type: 'RANGE',
      datatype: 'number',
    },
    group: {
      icon: faObjectGroup,
      type: 'SELECT',
      datatype: 'string',
    },
    date: {
      icon: faCalendar,
      type: 'RANGE',
      datatype: 'date',
    },
    breed: {
      icon: faCow,
      type: 'SELECT',
      datatype: 'string',
    },
    animalColor: {
      icon: faBrush,
      type: 'SELECT',
      datatype: 'string',
    },
  };
  const [focusFilter, setFocusFilter] = useState(null);
  const filterItems = {
    tagColor: tagColors,
    group: groups,
    breed: breeds,
    animalColor: animalColors,
  }
  const [focusFilterValue, setFocusFilterValue] = useState({
    min: '',
    max: '',
    ids: [],
  });
  const [errMsg, setErrMsg] = useState('');
  const [reportModal, showReportModal] = useState(false);
  const [reportName, setReportName] = useState('');

  function showFilterModal(filterKey) {
    if (filter[filterKey]) {
      setFocusFilterValue(filter[filterKey])
    } else {
      setFocusFilterValue({
        min: '',
        max: '',
        ids: [],
      });
    }
    setFocusFilter(filterKey);
  }
  function updateFilterMin(value) {
    if(value.length === 0) {
      setFocusFilterValue({ ...focusFilterValue, min: '' });
    } else {
      const datatype = filterTypes[focusFilter].datatype;
      if (datatype === 'date') setFocusFilterValue({ ...focusFilterValue, min: value });
      else setFocusFilterValue({ ...focusFilterValue, min: Number(value) });
    }
  }
  function updateFilterMax(value) {
    if(value.length === 0) {
      setFocusFilterValue({ ...focusFilterValue, max: '' });
    } else {
      const datatype = filterTypes[focusFilter].datatype;
      if (datatype === 'date') setFocusFilterValue({ ...focusFilterValue, max: value });
      else setFocusFilterValue({ ...focusFilterValue, max: Number(value) });
    }
  }
  function applyFilter() {
    if (filterTypes[focusFilter].type === 'RANGE') {
      if (focusFilterValue.min === '' && focusFilterValue.max === '') {
        setErrMsg('Min or max value is required');
        return;
      }
      if (focusFilterValue.min !== '' && focusFilterValue.max !== '') {
        if (focusFilterValue.min > focusFilterValue.max) {
          setErrMsg('Invalid value');
          return;
        }
      }
      setErrMsg('');
      setFilter({
        ...filter,
        [focusFilter]: focusFilterValue
      });
      setFocusFilter(null);
    } else {
      setErrMsg('');
      setFilter({
        ...filter,
        [focusFilter]: focusFilterValue
      });
      setFocusFilter(null);
    }
  }
  function removeFilter() {
    setFilter({
      ...filter,
      [focusFilter]: null
    });
    setFocusFilter(null);
  }
  function isNum(n) {
    return typeof(n) === 'number';
  }
  function isFilter(filterKey) {
    if (!filter[filterKey]) return false;
    if (filterTypes[filterKey].type === 'RANGE') {
      if (filterTypes[filterKey].datatype === 'number') {
        if (isNum(filter[filterKey].min) || isNum(filter[filterKey].max)) {
          return true;
        }
        return false;
      }
      return true;
    }
    if (Array.isArray(filter[filterKey].ids) && filter[filterKey].ids.length > 0) {
      return true;
    }
    return false;
  }
  function saveAsReport() {
    showReportModal(true);
  }

  return (
    <div className={`filters-container ${className}`}>
      {Object.entries(filter).map(([key, value]) => (
        <div
          className='filter clickable'
          key={key}
          style={{border: isFilter(key) ? '1px solid #F3AA00' : null}}
          onClick={() => {showFilterModal(key)}}
        >
          <FontAwesomeIcon className='filter-icon' icon={filterTypes[key].icon} size='2x' />
          <hr className='filter-border' />
          {value && (filterTypes[key].type === 'RANGE' ? (
            filterTypes[key].datatype === 'number' ? (
              isNum(value.min) ? isNum(value.max) ? (
                <span className='filter-text'>{value.min + ' - ' + value.max}</span>
              ) : (
                <span className='filter-text'>{'> ' + value.min}</span>
              ) : isNum(value.max) && (
                <span className='filter-text'>{'< ' + value.max}</span>
              )
            ) : (
              <span className='filter-text'>{value.min + ' - ' + value.max}</span>
            )
          ) : (
            Array.isArray(value.ids) && value.ids.length > 0 && (
              value.ids.length > 1 ? (
                <span className='filter-text'>{value.ids.length} items</span>
              ) : (
                <span className='filter-text'>{filterItems[key][value.ids[0]].name}</span>
              )
            )
          ))
        }
        </div>
      ))}
      <button
        className='buton mt-2'
        onClick={saveAsReport}
      >
        Save as Report
      </button>
      {focusFilter && (
        <div className='w-100 h-100 fixed-top' onClick={() => setFocusFilter(null)}>
          <div
            className='modal-box'
            onClick={e => e.stopPropagation()}
          >
            {filterTypes[focusFilter].type === 'RANGE' ? (
              <div>
                <div className='input-tag'>
                  <input
                    className='inputbox'
                    type={filterTypes[focusFilter].datatype}
                    value={focusFilterValue.min}
                    max={filterTypes[focusFilter].datatype === 'date' ? "2100-12-31" : undefined}
                    onChange={e => updateFilterMin(e.target.value)}
                  />
                  <p className='tag'>Min {focusFilter}</p>
                </div>
                <div className='input-tag'>
                  <input
                    className='inputbox'
                    type={filterTypes[focusFilter].datatype}
                    value={focusFilterValue.max}
                    max={filterTypes[focusFilter].datatype === 'date' ? "2100-12-31" : undefined}
                    onChange={e => updateFilterMax(e.target.value)}
                  />
                  <p className='tag'>Max {focusFilter}</p>
                </div>
              </div>
            ) : (
              <div>
                <GridSelect
                  items={filterItems[focusFilter]}
                  value={focusFilterValue.ids}
                  onChangeValue={v => setFocusFilterValue({...focusFilterValue, ids: focusFilterValue.ids.includes(v) ? focusFilterValue.ids.filter(f => (f !== v)) : [...focusFilterValue.ids, v]})}
                  tag={focusFilter}
                  multiple
                />
              </div>
            )}
            <p className='mt-4 text-danger'>{errMsg}</p>
            <div className='mt-2 d-flex'>
              <button
                className='buton'
                onClick={() => applyFilter()}
              >
                Apply
              </button>
              <button
                className='buton'
                onClick={() => removeFilter()}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      )}
      {reportModal && (
        <div className='w-100 h-100 fixed-top' onClick={() => showReportModal(false)}>
          <div
            className='modal-box'
            onClick={e => e.stopPropagation()}
          >
            <div className='input-tag'>
              <input
                className='inputbox'
                value={reportName}
                onChange={e => setReportName(e.target.value)}
              />
              <p className='tag'>
                Report name
              </p>
            </div>
            <div className='mt-2 d-flex'>
              <button
                className='buton'
                onClick={() => addReport({ type: 'animals', name: reportName, parameters: { filter: filter } })}
              >
                Add Report
              </button>
              <button
                className='buton'
                onClick={() => showReportModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tagColors: state.library.tagColors,
  groups: state.animals.groups,
  breeds: state.library.breeds,
  animalColors: state.library.animalColors,
  reports: state.animals.reports,
});

const mapDispatchToProps = {
  getTagColors,
  getGroups,
  getBreeds,
  getAnimalColors,
  getReports,
  addReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
