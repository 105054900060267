import callApi from '../../utils/callApi';
import history from '../../utils/history';
import getSiteEndpoint from '../../utils/getSiteEndpoint';

export function* inviteCustomer(action) {
  yield callApi({
    method: "post",
    url: getSiteEndpoint() + "/user-invites",
    type: "INVITE_CUSTOMER",
    useFullResponse: false,
    dataProp: "",
    body: action.payload.invite,
    successMessage: "User was successfully invited",
    errorMessage: "There was an error while the invite user.",
    onSuccess: () => history.push("/a/locations"),
  });
}
