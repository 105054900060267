export class VoidStorage {
  length = 0
  clear() { }
  getItem(key) {
    return ""
  }
  key(index) {
    return ""
  }
  removeItem(key) { }
  setItem(key, value) { }
}

const voidStorage = new VoidStorage()
export { voidStorage }
