import React from "react";
import "./NotFound.css";

class NotFound extends React.Component {
  render() {
    return (
      <div>
        <h1>Sorry Page Not Found :)</h1>
      </div>
    );
  }
}

export default NotFound;
