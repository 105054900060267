/* eslint-disable no-unused-expressions */
/* eslint-disable no-labels */
import Axios from "axios"
import history from '../history';

// const FORBIDDEN = 403
const UNAUTHORIZED = 401

// https://stackoverflow.com/questions/52246393/rxjs-subscription-queue/52250016
let isRefreshing = false

class BufferQueue {
  // private queue$ = new Subject<RequestTask[]>();
  // private process$ = new Subject<RequestTask[]>();

  buffer = []

  delay = 200

  async enqueue(task) {
    const p = new Promise((resolve, reject) => {
      console.info(
        `Adding request task to buffer for ${task.method} ${task.url}`,
        task
      )
      this.buffer.push({ resolve: resolve, reject, ori: task })
    })

    return (
      p
        // if flush success
        .then(this.injectAuthToken(task))
        // if flush error
        .catch(err => {
          console.error("Error injecting auth token after refresh")
          console.error(err)
          return Promise.reject(err)
        })
    )
  }

  async flushSuccess(token) {
    return this.process(buffer => {
      for (let i = 0; i < buffer.length; i++) {
        const b = buffer[i]
        b.resolve(token)
      }
      console.info("Refresh queue has been resolved")
      return token
    })
  }

  async flushError(err) {
    return this.process(buffer => {
      for (let i = 0; i < buffer.length; i++) {
        const b = buffer[i]
        b.reject(err)
      }
      console.error("Refresh queue has been rejected with", err)
      return err
    })
  }

  async process(proc) {
    // make the processing of the buffer async so that we may choose to
    // delegate it to a worker in the future
    return new Promise((resolve, reject) => {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        // todo. Will flushError make it so the buffer doesn't get handled properly? needs testing
        try {
          if (this.isEmpty()) {
            console.info("Processing buffer is empty.")
            return proc([])
          }
          // pull out of array to make sure it doesn't get process twice
          // proc function can enqueue if needed
          const tasks = this.buffer.splice(0)
          console.info(`Processing ${tasks.length} tasks from buffer`)

          return resolve(proc(tasks))
        } catch (err) {
          reject(err)
        }
      }, this.delay)
    })
  }

  injectAuthToken(r) {
    return token => {
      if (typeof r.headers === "undefined") {
        r.headers = {}
      }

      r.headers = { ...r.headers }
      r.headers.Authorization = `Bearer ${token}`
      console.log("[injectAuthToken] Replaying axiox request", r.url)
      return Axios(r)
    }
  }

  isEmpty() {
    return this.buffer.length === 0
  }
}

const queue = new BufferQueue()
const MAX_RETRY = 1

// const regex = /\/user\/[\w\-]+\/?/g
const defaultOptions = {
  handleLogout: () => {
    console.error("MISSING LOGOUT HANDLER")
    // window.location.href = '/u/login';
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("refresh");
    localStorage.removeItem("uuid");
    history.push('/u/login');
    // console.log(window.location.href.search(/\/user\/logout$/))
    // TODO unabled to use store since the store is not initialized here.
    // alternative solution would be to redirect to the logout
    // this can probably be done when we create a new auth service
    // store.dispatch(authActions.authLogoutAsync.request());
    // history.push('/user/logout');
    // if (
    //   typeof window !== "undefined" &&
    //   window.location.href.search(regex) === -1
    // ) {
    //   const logoutUrl = `${process.env.NEXT_PUBLIC_AUTH_LOGOUT_URL}`
    //   window.location.href = logoutUrl || "/u/login"
    // }
  }
}

export const refreshTokenInterceptorProvider = (
  tokenService,
  authService,
  refreshOptions = {}
) => ({
  reject: async err => {
    console.log('(Refresh token) refresh token interceptor!', err)
    if (typeof err !== "object") {
      return Promise.reject(err)
    }
    const { response, config } = err
    const options = { ...defaultOptions, ...refreshOptions }

    // if the response return a 401 then we attempt to refresh the token
    if (!response || !response.status || response.status !== UNAUTHORIZED) {
      return Promise.reject(err)
    }
    console.error("(Refresh token) Refresh error", err)
    // if the response return a 401 or 403 then we attempt to refresh the token
    // if (!response || !response.status || (response.status !== FORBIDDEN && response.status !== UNAUTHORIZED)) {
    //   return Promise.reject(err);
    // }

    // 0.19.1 allows for this again
    // basically keep track of the number of times a retry attempt was made
    const ori = config

    // have it default to 0 if not set otherwise check if too many retried attempts we made
    if (typeof ori._retryRefresh === "undefined") {
      ori._retryRefresh = 0
    } else if (ori._retryRefresh >= MAX_RETRY) {
      console.error(`Max retry attempts reached ${ori._retryRefresh}`)
      return Promise.reject(err)
    }

    // if there are several requests being made (ie looping and running several ajax calls)
    // the we queue it up to the buffer
    if (isRefreshing) {
      // console.error('isRefreshing. Push to queue');
      return queue.enqueue(ori)
    }

    isRefreshing = true
    ori._retryRefresh++

    // begin the refresh token logic
    return new Promise(async (resolve, reject) => {
      // check for an existing request token and queue up the current request
      // note that enqueue returns a promise that will inject auth token and replay the request
      const refreshToken = await tokenService.getRefreshToken({
        forceReload: true
      })
      const current = queue.enqueue(ori)

      // todo: Not sure if this is the correct way to handle errors for this situation
      // should the reject map to the current?
      if (!refreshToken) {
        return queue
          .flushError(err)
          .then(() => {
            console.log(
              "Logging out: No refresh token provided from token service"
            )
            options.handleLogout()
          })
          .catch(err => reject(err))
      }

      let refreshResponse = null
      // const response = await authService.refresh({ token: refreshToken });
      try {
        console.log("Performing refresh request")
        refreshResponse = await authService.refresh({ token: refreshToken })
        if (!refreshResponse) {
          reject("Missing response from refresh request")
          return
        }
      } catch (e) {
        console.error(e, refreshResponse)
        return queue
          .flushError(err)
          .then(() => {
            console.log("Logging out due to failed refresh request")
            options.handleLogout()
          })
          .catch(err => reject(err))
      }

      if (
        refreshResponse.status !== 200 ||
        !refreshResponse.data ||
        !refreshResponse.data.token
      ) {
        console.error(
          `Invalid refresh response ${refreshResponse.status}`,
          refreshResponse
        )
        return (
          queue
            .flushError(err)
            // .then(() => {
            //   if (response.status === 403) {
            //     console.log('Logging out', authActions.authLogoutAsync)
            //     options.handleLogout();
            //     // store.dispatch(authActions.authLogoutAsync.request());
            //   }
            // })
            .catch(err => reject(err))
        )
      }

      const authPayload = refreshResponse.data
      const { token } = authPayload
      if (!authPayload.refresh) {
        authPayload.refresh = refreshToken
      }

      // project specific, we need to merge this because the login payload is different from the
      // refresh payload.
      const currentToken = await tokenService.getAuth()
      await tokenService.saveAuth({ ...(currentToken || {}), ...authPayload })

      queue.flushSuccess(token)

      return resolve(current)
    }).finally(() => (isRefreshing = false))
  }
})
