import authTypes from "./authTypes";
import { takeLatest, all, throttle } from "redux-saga/effects";
import {
  login,
  logout,
  authenticate,
  refreshToken,
  loadUser,
  resetRequest,
  verify,
  clearError,
  resetChallenge
} from "./authActions";

function* loginWatcher() {
  yield takeLatest(authTypes.LOGIN_REQUEST, login);
}
function* resetRequestWatcher() {
  yield takeLatest(authTypes.RESET_PASSWORD_REQUEST, resetRequest);
}
function* verifyWatcher() {
  yield takeLatest(authTypes.VERIFY_REQUEST, verify);
}

function* resetChallengeWatcher() {
  yield takeLatest(authTypes.RESET_CHALLENGE_REQUEST, resetChallenge);
}

function* logoutWatcher() {
  yield takeLatest(authTypes.LOGOUT_ASYNC, logout);
}

function* authenticateWatcher() {
  yield takeLatest(authTypes.AUTHENTICATE_REQUEST, authenticate);
}

function* refreshTokenWatcher() {
  yield throttle(10000, authTypes.REFRESH_TOKEN_REQUEST, refreshToken);
}

function* loadUserWatcher() {
  yield takeLatest(authTypes.LOAD_USER_REQUEST, loadUser);
}

function* clearErrorWatcher() {
  yield takeLatest(authTypes.LOAD_USER_FAILURE, clearError);
}

export default function* authSagas() {
  yield all([
    loginWatcher(),
    logoutWatcher(),
    authenticateWatcher(),
    refreshTokenWatcher(),
    loadUserWatcher(),
    resetRequestWatcher(),
    verifyWatcher(),
    clearErrorWatcher(),
    resetChallengeWatcher()
  ]);
}
