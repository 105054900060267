// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CLEAR_LIBRARY_STORE: "CLEAR_LIBRARY_STORE",

  GET_UNITS_OF_MEASURE_REQUEST: "GET_UNITS_OF_MEASURE_REQUEST",
  GET_UNIT_OF_MEASURE_REQUEST: "GET_UNIT_OF_MEASURE_REQUEST",
  GET_SPECIES_LIST_REQUEST: "GET_SPECIES_LIST_REQUEST",
  GET_SPECIES_REQUEST: "GET_SPECIES_REQUEST",
  GET_ANIMAL_TYPES_REQUEST: "GET_ANIMAL_TYPES_REQUEST",
  GET_ANIMAL_TYPE_REQUEST: "GET_ANIMAL_TYPE_REQUEST",
  GET_ANIMAL_COLORS_REQUEST: "GET_ANIMAL_COLORS_REQUEST",
  GET_ANIMAL_COLOR_REQUEST: "GET_ANIMAL_COLOR_REQUEST",
  GET_DIAGNOSES_REQUEST: "GET_DIAGNOSES_REQUEST",
  GET_DIAGNOSIS_REQUEST: "GET_DIAGNOSIS_REQUEST",
  GET_HEALTH_RISKS_REQUEST: "GET_HEALTH_RISKS_REQUEST",
  GET_HEALTH_RISK_REQUEST: "GET_HEALTH_RISK_REQUEST",
  GET_TREATMENTS_REQUEST: "GET_TREATMENTS_REQUEST",
  GET_TREATMENT_REQUEST: "GET_TREATMENT_REQUEST",
  GET_BREED_REQUEST: "GET_BREED_REQUEST",
  GET_BREEDS_REQUEST: "GET_BREEDS_REQUEST",
  GET_TAG_COLORS_REQUEST: "GET_TAG_COLORS_REQUEST",
  GET_ANIMAL_RECORDS_REQUEST: "GET_ANIMAL_RECORDS_REQUEST",

  GET_UNITS_OF_MEASURE_SUCCESS: "GET_UNITS_OF_MEASURE_SUCCESS",
  GET_UNIT_OF_MEASURE_SUCCESS: "GET_UNIT_OF_MEASURE_SUCCESS",
  GET_SPECIES_LIST_SUCCESS: "GET_SPECIES_LIST_SUCCESS",
  GET_SPECIES_SUCCESS: "GET_SPECIES_SUCCESS",
  GET_ANIMAL_TYPES_SUCCESS: "GET_ANIMAL_TYPES_SUCCESS",
  GET_ANIMAL_TYPE_SUCCESS: "GET_ANIMAL_TYPE_SUCCESS",
  GET_ANIMAL_COLORS_SUCCESS: "GET_ANIMAL_COLORS_SUCCESS",
  GET_ANIMAL_COLOR_SUCCESS: "GET_ANIMAL_COLOR_SUCCESS",
  GET_DIAGNOSES_SUCCESS: "GET_DIAGNOSES_SUCCESS",
  GET_DIAGNOSIS_SUCCESS: "GET_DIAGNOSIS_SUCCESS",
  GET_HEALTH_RISKS_SUCCESS: "GET_HEALTH_RISKS_SUCCESS",
  GET_HEALTH_RISK_SUCCESS: "GET_HEALTH_RISK_SUCCESS",
  GET_TREATMENTS_SUCCESS: "GET_TREATMENTS_SUCCESS",
  GET_TREATMENT_SUCCESS: "GET_TREATMENT_SUCCESS",
  GET_BREED_SUCCESS: "GET_BREED_SUCCESS",
  GET_BREEDS_SUCCESS: "GET_BREEDS_SUCCESS",
  GET_TAG_COLORS_SUCCESS: "GET_TAG_COLORS_SUCCESS",
  GET_ANIMAL_RECORDS_SUCCESS: "GET_ANIMAL_RECORDS_SUCCESS",

  GET_UNITS_OF_MEASURE_FAILURE: "GET_UNITS_OF_MEASURE_FAILURE",
  GET_UNIT_OF_MEASURE_FAILURE: "GET_UNIT_OF_MEASURE_FAILURE",
  GET_SPECIES_LIST_FAILURE: "GET_SPECIES_LIST_FAILURE",
  GET_SPECIES_FAILURE: "GET_SPECIES_FAILURE",
  GET_ANIMAL_TYPES_FAILURE: "GET_ANIMAL_TYPES_FAILURE",
  GET_ANIMAL_TYPE_FAILURE: "GET_ANIMAL_TYPE_FAILURE",
  GET_ANIMAL_COLORS_FAILURE: "GET_ANIMAL_COLORS_FAILURE",
  GET_ANIMAL_COLOR_FAILURE: "GET_ANIMAL_COLOR_FAILURE",
  GET_DIAGNOSES_FAILURE: "GET_DIAGNOSES_FAILURE",
  GET_DIAGNOSIS_FAILURE: "GET_DIAGNOSIS_FAILURE",
  GET_HEALTH_RISKS_FAILURE: "GET_HEALTH_RISKS_FAILURE",
  GET_HEALTH_RISK_FAILURE: "GET_HEALTH_RISK_FAILURE",
  GET_TREATMENTS_FAILURE: "GET_TREATMENTS_FAILURE",
  GET_TREATMENT_FAILURE: "GET_TREATMENT_FAILURE",
  GET_BREED_FAILURE: "GET_BREED_FAILURE",
  GET_BREEDS_FAILURE: "GET_BREEDS_FAILURE",
  GET_TAG_COLORS_FAILURE: "GET_TAG_COLORS_FAILURE",
  GET_ANIMAL_RECORDS_FAILURE: "GET_ANIMAL_RECORDS_FAILURE",
};
