import React, { forwardRef } from "react";
import { Router, Switch } from "react-router-dom";

import ProfileDetails from "../../screens/profile/ProfileScreen";
import EditProfile from "../../screens/profile/EditProfile";

import history from "../../utils/history";
import { withRouterAndRef } from "../../HOCs/withRouterAndRef";

const ProfileRouter = forwardRef((props, ref) => {
  return (
    <Router history={history}>
      <Switch>
        <ProfileDetails ref={ref} {...props} exact path="/a/profile" />
        <EditProfile ref={ref} {...props} exact path="/a/profile/edit" />
      </Switch>
    </Router>
  );
})

export default withRouterAndRef(ProfileRouter);
