// eslint-disable-next-line import/no-anonymous-default-export
export default {
    GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
    GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
    GET_CUSTOMER_FAILURE: "GET_CUSTOMER_FAILURE",
    GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
    GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
    GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_FAILURE",
    GET_NEXT_CUSTOMERS_REQUEST:"GET_NEXT_CUSTOMERS_REQUEST",
    GET_NEXT_CUSTOMERS_SUCCESS:"GET_NEXT_CUSTOMERS_SUCCESS",
    GET_CUSTOMER_SITES_REQUEST: "GET_CUSTOMER_SITES_REQUEST",
    GET_CUSTOMER_SITES_SUCCESS: "GET_CUSTOMER_SITES_SUCCESS",
    GET_CUSTOMER_SITES_FAILURE: "GET_CUSTOMER_SITES_FAILURE",
}
