import React, { Component } from 'react';
import { Router, Switch } from "react-router-dom";
import history from "../../utils/history";
import DashBoard from '../../screens/vet/DashBoard/DashBoard';
import PostMortemWizard from '../../screens/postmortem/PostMortemWizard/PostMortemWizard';

import AnimalDetail from '../../screens/vet/AnimalDetail/AnimalDetail';
import Locations from '../../screens/vet/Locations/Locations';
import ClientLocation from '../../screens/vet/Locations/Location';
import VetLocation from '../../screens/vet/DashBoard/DashBoard';
import GenerateRouters from "../helpers/GenerateRouters";
import VetAnimal from "../../screens/vet/VetAnimal/VetAnimal";
import UploadPhoto from '../../components/common/UploadPhoto';
import PostMortemReport from '../../components/common/PostMortemTicket/PostMortemReport';

const vetRouters = [
  {
    path: "/a/vet",
    component: DashBoard,
    permissionRoles: ["Veterinarian", "Admin", "SuperAdmin"],
  },
  {
    path: "/a/vet/ticketdetail/:id/:animalID/uploadPhoto",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Admin"],
    render: (props) => <UploadPhoto {...props} />,
  },
  {
    path: "/a/vet/ticketdetail/:id/report",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Admin", "Client"],
    render: (props) => <PostMortemReport {...props} />
  },
  {
    path: "/a/vet/ticketdetail/:id",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Admin"],
    render: (props) => <PostMortemWizard {...props} isEdit={true} />,
  },
  {
    path: "/a/vet/ticketdetail/:id/:animalID",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Admin"],
    render: (props) => <PostMortemWizard {...props} isEdit={true} />,
  },
  {
    path: "/a/animal/:id",
    component: AnimalDetail,
    permissionRoles: ["Veterinarian", "SuperAdmin", "Admin"],
  },
  {
    path: "/a/locations",
    component: Locations,
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
  },
  {
    path: "/a/location/:id",
    component: ClientLocation,
    permissionRoles: ["Client"],
  },
  {
    path: "/a/location/:id/:tab",
    component: ClientLocation,
    permissionRoles: ["Client"],
  },
  {
    path: "/a/location/:id",
    component: VetLocation,
    permissionRoles: ["SuperAdmin", "Veterinarian", "Admin"],
  },
  {
    path: "/a/location/:id/:tab",
    component: VetLocation,
    permissionRoles: ["Veterinarian", "Admin", "SuperAdmin"],
  },
  {
    path: "/a/record/:id",
    permissionRoles: ["Veterinarian", "SuperAdmin", "Client", "Admin"],
    render: (props) => <VetAnimal {...props}></VetAnimal>,
  },
];

export default class VetRouter extends Component {
  render() {
    return (
      <Router history={history}>
      <Switch>
        <GenerateRouters routersConst={vetRouters} />
      </Switch>
    </Router>
    )
  }
}
