// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ADD_POSTMORTEM_REQUEST: "ADD_POSTMORTEM_REQUEST",
  ADD_POSTMORTEM_SUCCESS: "ADD_POSTMORTEM_SUCCESS",
  ADD_POSTMORTEM_FAILURE: "ADD_POSTMORTEM_FAILURE",
  GET_POSTMORTEMS_REQUEST: "GET_POSTMORTEMS_REQUEST",
  GET_POSTMORTEMS_SUCCESS: "GET_POSTMORTEMS_SUCCESS",
  GET_POSTMORTEMS_FAILURE: "GET_POSTMORTEMS_FAILURE",
  GET_POSTMORTEM_REQUEST: "GET_POSTMORTEM_REQUEST",
  GET_POSTMORTEM_SUCCESS: "GET_POSTMORTEM_SUCCESS",
  GET_POSTMORTEM_FAILURE: "GET_POSTMORTEM_FAILURE",
  GET_MORE_POSTMORTEMS_REQUEST: "GET_MORE_POSTMORTEMS_REQUEST",
  GET_MORE_POSTMORTEMS_SUCCESS: "GET_MORE_POSTMORTEMS_SUCCESS",

  UPDATE_POSTMORTEM_REQUEST: "UPDATE_POSTMORTEM_REQUEST",
  UPDATE_POSTMORTEM_SUCCESS: "UPDATE_POSTMORTEM_SUCCESS",
  UPDATE_POSTMORTEM_FAILURE: "UPDATE_POSTMORTEM_FAILURE",
  SCHEDULED_OR_COMPLETE_POSTMORTEM_REQUEST:
    "SCHEDULED_OR_COMPLETE_POSTMORTEM_REQUEST",
  SCHEDULED_OR_COMPLETE_POSTMORTEM_SUCCESS:
    "SCHEDULED_OR_COMPLETE_POSTMORTEM_SUCCESS",
  SCHEDULED_OR_COMPLETE_POSTMORTEM_FAILURE:
    "SCHEDULED_OR_COMPLETE_POSTMORTEM_FAILURE",
  DELETE_POSTMORTEM_REQUEST: "DELETE_POSTMORTEM_REQUEST",
  DELETE_POSTMORTEM_SUCCESS: "DELETE_POSTMORTEM_SUCCESS",
  DELETE_POSTMORTEM_FAILURE: "DELETE_POSTMORTEM_FAILURE",

  // reports
  POSTMORTEM_REPORT_REQUEST: "POSTMORTEM_REPORT_REQUEST",
  POSTMORTEM_REPORT_SUCCESS: "POSTMORTEM_REPORT_SUCCESS",
  POSTMORTEM_REPORT_FAILURE: "POSTMORTEM_REPORT_FAILURE",
  POSTMORTEM_REPORT_RESET: "POSTMORTEM_REPORT_RESET",

  //
  RESET_LIST_STORE: "RESET_LIST_STORE",
};
