import React, { Component } from 'react';
import styles from './Icon.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLayerGroup, faCircle as solidCircle} from "@fortawesome/pro-solid-svg-icons";
import {faCircle} from "@fortawesome/pro-regular-svg-icons";
import classnames from 'classnames';


export default class Icon extends Component {

  calcSize(baseSize) {
    this.baseSize = baseSize + 'px';
    this.innerCircleSize = baseSize*0.90 + 'px';
    this.innerIconSize = baseSize*0.50 + 'px';
    this.innerIconSize = baseSize*0.50 + 'px';
    this.innerIconPos = baseSize*0.20 + 'px';
    this.innerCirclePos = baseSize*0.05 + 'px';
  }

  render() {
    this.calcSize(this.props.size?this.props.size:10);
    return (
      <div {...this.props} className={this.props.className + ' fa-stack'} style={this.props.style?this.props.style:{position:'relative'}} > 
      <FontAwesomeIcon
        style={{fontSize: this.baseSize}}
        className={`${styles.center}`}
        icon={solidCircle} 
        color="#FFF"/>
      <FontAwesomeIcon
        style={{ fontSize: this.innerCircleSize}}
        className={classnames([styles.center, styles.circle])}
        icon={faCircle} 
        />
      <FontAwesomeIcon
        style={{ fontSize: this.innerIconSize}}
        className={`${styles.center} text-darkblue`}
        icon={this.props.icon?this.props.icon:faLayerGroup} 
        color="#000"/>
    </div>
    )
  }
}
