import animalTypes from "./animalTypes";
import { takeLatest, takeEvery, all } from "redux-saga/effects";
import {
  getAnimal,
  getAnimals,
  addAnimal,
  updateAnimal,
  getGroup,
  updateGroup,
  getGroups,
  getAnimalGroups,
  getGroupAnimals,
  deleteAnimal,
  attachAnimalToGroup,
  detachAnimalFromGroup,
  getRecord,
  getRecords,
  addRecord,
  updateRecord,
  deleteRecord,
  getTags,
  addTag,
  getReports,
  addReport,
} from "./animalActions";

export default function* animalSagas() {
  yield all([
    yield takeLatest(animalTypes.GET_ANIMAL_REQUEST, getAnimal),
    yield takeLatest(animalTypes.GET_ANIMALS_REQUEST, getAnimals),
    yield takeLatest(animalTypes.ADD_ANIMAL_REQUEST, addAnimal),
    yield takeLatest(animalTypes.UPDATE_ANIMAL_REQUEST, updateAnimal),
    yield takeLatest(animalTypes.DELETE_ANIMAL_REQUEST, deleteAnimal),

    yield takeLatest(animalTypes.GET_RECORD_REQUEST, getRecord),
    yield takeEvery(animalTypes.GET_RECORDS_REQUEST, getRecords),
    yield takeLatest(animalTypes.ADD_RECORD_REQUEST, addRecord),
    yield takeLatest(animalTypes.UPDATE_RECORD_REQUEST, updateRecord),
    yield takeLatest(animalTypes.DELETE_RECORD_REQUEST, deleteRecord),

    yield takeLatest(animalTypes.GET_GROUP_REQUEST, getGroup),
    yield takeLatest(animalTypes.UPDATE_GROUP_REQUEST, updateGroup),
    yield takeLatest(animalTypes.GET_GROUPS_REQUEST, getGroups),
    yield takeEvery(animalTypes.GET_ANIMAL_GROUPS_REQUEST, getAnimalGroups),
    yield takeLatest(animalTypes.GET_GROUP_ANIMALS_REQUEST, getGroupAnimals),
    yield takeLatest(
      animalTypes.ATTACH_ANIMAL_TO_GROUP_REQUEST,
      attachAnimalToGroup
    ),
    yield takeLatest(
      animalTypes.DETACH_ANIMAL_FROM_GROUP_REQUEST,
      detachAnimalFromGroup
    ),

    yield takeLatest(animalTypes.GET_TAGS_REQUEST, getTags),
    yield takeLatest(animalTypes.ADD_TAG_REQUEST, addTag),

    yield takeLatest(animalTypes.GET_REPORTS_REQUEST, getReports),
    yield takeLatest(animalTypes.ADD_REPORT_REQUEST, addReport),
  ]);
}
