import React from 'react';
import styles from './PageWrapper.module.css';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import Loading from '../Loading/Loading';

class PageWrapper extends React.Component { 
  
  calcClassNames() {
    return classNames({
      [styles['curve-base']]: true,
      [styles['curve-left']]: this.props.type === 'left' || this.props.type === 'all',
      [styles['curve-right']]: this.props.type === 'right' || this.props.type === 'all'
    });
  }

  render () {
    return (
      <>
        <div className={styles.PageWrapper}>
        <h1 className={styles.title} dangerouslySetInnerHTML={{__html: this.props.loading ? "&nbsp;" : this.props.title }} />
          <div className={styles['curve-bg']}>
            <div className={this.calcClassNames()} style={{position: "relative"}}>
              {this.props.icon && (
                <Icon
                  size="50"
                  icon={this.props.icon}
                  className={styles.icon}
                  style={{ position: "absolute", verticalAlign: 'top' }}
                />
              )}
            </div>
          </div>
        </div>
        {(this.props.loadingTitle && this.props.loading) && (
          <div className="text-white text-center relative" style={{ right: "0.5rem", top: "33rem" }}>
            <p>{ this.props.loadingTitle }</p>
          </div>
        )}
          <Loading visible={this.props.loading}></Loading>
        <div
          style={{ height: 'calc(100% - 120px)' }}
          className={`${this.props.loading ? 'hidden' :''} mt-3 flex flex-column`}
        >
          {this.props.children}
        </div>
      </>
    )
  }
}



export default PageWrapper;
