import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from "react-redux";

import { faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import MUTooltip from '@material-ui/core/Tooltip';

import Icon from '../Icon/Icon';
import PageWrapper from '../PageWrapper/PageWrapper';
import PostMortemTicket from '../../../components/common/PostMortemTicket/PostMortemTicket';
import {
  scheduledOrCompletePostmortem,
} from "../../../store/postmortem";


import history from '../../../utils/history';
import { searchGetPostmortems } from '../../../store/search';
import Pagination from '../Pagination/Pagination';

const ResultSearch = ({
  loading,
  records,
  vets,
  scheduledOrCompletePostmortem
}) => {
  const filterValues = useSelector((state) => state.search.filterValues);
  const paginationValues = useSelector((state) => state.search.pagination);

  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const changePage = () => {
    const filters = {
      ...filterValues,
      page: currentPage
    }
    dispatch(searchGetPostmortems({filterValues: filters}));
  }

  useEffect(() => {
    changePage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div>
      { <PageWrapper loading={loading} title="SEARCH RESULT" type="all" icon={faSearch} /> }
      { (records && records.length > 0) && <>
          <p className="text-white px-4">Displaying {records.length} of {paginationValues.total} results.</p>
          <div className='px-6'>
            <Pagination
              pagination={paginationValues}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <div className="px-6" style={{ paddingBottom: "100px" }}>
            {
              records && records.map((pm) => {
                return    <PostMortemTicket
                            {...pm}
                            key={pm.id}
                            vet={true}
                            vetList={vets}
                            updatePostmortem={scheduledOrCompletePostmortem}
                            confirmModalAction={(ticketContext) => this.confirmModalAction(pm, ticketContext)}
                            actions={['view']}
                            updatePostmortemFromModal={(postmortem) => this.updatePostmortemFromModal(postmortem)}
              />
              })
            }
          </div>
          </>
      } { ((!records || records.length === 0) && !loading) && <>

        <p className="text-2xl px-4 text-white">
          No results found. Please go back and expand on the critera.
        </p>

      </>
      }

      <div className="mt-20 block flex">
        <div className="flex-1">
          <MUTooltip
            title="Go to adding an animal"
            enterDelay={500}
            leaveDelay={200}
            placement="top"
            classes={{
              tooltip: "text-base ",
            }}
          >
            <Icon
              icon={faArrowLeft}
              size="45"
              style={{ display: "block" }}
              className="m-auto cursor-pointer"
              onClick={() => history.goBack()}
            />
          </MUTooltip>
          <p className="mt-6 block text-white text-center px-2">SEARCH</p>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  records: state.search.list,
  loading: state.search.loading,
  vets: state.user.list

});

const mapDispatchToProps = {
  scheduledOrCompletePostmortem
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultSearch);
