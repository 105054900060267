/* eslint-disable import/no-anonymous-default-export */
export default {
  CLEAR_ANIMAL_STORE: "CLEAR_ANIMAL_STORE",
  GET_ANIMAL_REQUEST: "GET_ANIMAL_REQUEST",
  GET_ANIMAL_SUCCESS: "GET_ANIMAL_SUCCESS",
  GET_ANIMAL_FAILURE: "GET_ANIMAL_FAILURE",
  GET_ANIMALS_REQUEST: "GET_ANIMALS_REQUEST",
  GET_ANIMALS_SUCCESS: "GET_ANIMALS_SUCCESS",
  GET_ANIMALS_FAILURE: "GET_ANIMALS_FAILURE",
  ADD_ANIMAL_REQUEST: "ADD_ANIMAL_REQUEST",
  ADD_ANIMAL_SUCCESS: "ADD_ANIMAL_SUCCESS",
  ADD_ANIMAL_FAILURE: "ADD_ANIMAL_FAILURE",
  UPDATE_ANIMAL_REQUEST: "UPDATE_ANIMAL_REQUEST",
  UPDATE_ANIMAL_SUCCESS: "UPDATE_ANIMAL_SUCCESS",
  UPDATE_ANIMAL_FAILURE: "UPDATE_ANIMAL_FAILURE",
  DELETE_ANIMAL_REQUEST: "DELETE_ANIMAL_REQUEST",
  DELETE_ANIMAL_SUCCESS: "DELETE_ANIMAL_SUCCESS",
  DELETE_ANIMAL_FAILURE: "DELETE_ANIMAL_FAILURE",

  GET_RECORD_REQUEST: "GET_RECORD_REQUEST",
  GET_RECORD_SUCCESS: "GET_RECORD_SUCCESS",
  GET_RECORD_FAILURE: "GET_RECORD_FAILURE",
  GET_RECORDS_REQUEST: "GET_RECORDS_REQUEST",
  GET_RECORDS_SUCCESS: "GET_RECORDS_SUCCESS",
  GET_RECORDS_FAILURE: "GET_RECORDS_FAILURE",
  ADD_RECORD_REQUEST: "ADD_RECORD_REQUEST",
  ADD_RECORD_SUCCESS: "ADD_RECORD_SUCCESS",
  ADD_RECORD_FAILURE: "ADD_RECORD_FAILURE",
  UPDATE_RECORD_REQUEST: "UPDATE_RECORD_REQUEST",
  UPDATE_RECORD_SUCCESS: "UPDATE_RECORD_SUCCESS",
  UPDATE_RECORD_FAILURE: "UPDATE_RECORD_FAILURE",
  DELETE_RECORD_REQUEST: "DELETE_RECORD_REQUEST",
  DELETE_RECORD_SUCCESS: "DELETE_RECORD_SUCCESS",
  DELETE_RECORD_FAILURE: "DELETE_RECORD_FAILURE",

  GET_ANIMAL_GROUPS_REQUEST: "GET_ANIMAL_GROUPS_REQUEST",
  GET_ANIMAL_GROUPS_SUCCESS: "GET_ANIMAL_GROUPS_SUCCESS",
  GET_ANIMAL_GROUPS_FAILURE: "GET_ANIMAL_GROUPS_FAILURE",
  GET_GROUPS_REQUEST: "GET_GROUPS_REQUEST",
  GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS",
  GET_GROUPS_FAILURE: "GET_GROUPS_FAILURE",
  GET_GROUP_REQUEST: "GET_GROUP_REQUEST",
  GET_GROUP_SUCCESS: "GET_GROUP_SUCCESS",
  GET_GROUP_FAILURE: "GET_GROUP_FAILURE",
  UPDATE_GROUP_REQUEST: "UPDATE_GROUP_REQUEST",
  UPDATE_GROUP_SUCCESS: "UPDATE_GROUP_SUCCESS",
  UPDATE_GROUP_FAILURE: "UPDATE_GROUP_FAILURE",
  GET_GROUP_ANIMALS_REQUEST: "GET_GROUP_ANIMALS_REQUEST",
  GET_GROUP_ANIMALS_SUCCESS: "GET_GROUP_ANIMALS_SUCCESS",
  GET_GROUP_ANIMALS_FAILURE: "GET_GROUP_ANIMALS_FAILURE",

  ATTACH_ANIMAL_TO_GROUP_REQUEST: "ATTACH_ANIMAL_TO_GROUP_REQUEST",
  ATTACH_ANIMAL_TO_GROUP_SUCCESS: "ATTACH_ANIMAL_TO_GROUP_SUCCESS",
  ATTACH_ANIMAL_TO_GROUP_FAILURE: "ATTACH_ANIMAL_TO_GROUP_FAILURE",
  DETACH_ANIMAL_FROM_GROUP_REQUEST: "DETACH_ANIMAL_FROM_GROUP_REQUEST",
  DETACH_ANIMAL_FROM_GROUP_SUCCESS: "DETACH_ANIMAL_FROM_GROUP_SUCCESS",
  DETACH_ANIMAL_FROM_GROUP_FAILURE: "DETACH_ANIMAL_FROM_GROUP_FAILURE",

  GET_TAGS_REQUEST: "GET_TAGS_REQUEST",
  GET_TAGS_SUCCESS: "GET_TAGS_SUCCESS",
  ADD_TAG_REQUEST: "ADD_TAG_REQUEST",
  ADD_TAG_SUCCESS: "ADD_TAG_SUCCESS",
  ADD_TAG_FAILURE: "ADD_TAG_FAILURE",

  GET_REPORTS_REQUEST: "GET_REPORTS_REQUEST",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_FAILURE: "GET_REPORTS_FAILURE",
  ADD_REPORT_REQUEST: "ADD_REPORT_REQUEST",
  ADD_REPORT_SUCCESS: "ADD_REPORT_SUCCESS",
  ADD_REPORT_FAILURE: "ADD_REPORT_FAILURE",
};
