import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../../components/common";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Toast } from "react-bootstrap";

const AppFrame = ({ children }) => {
  const [showToast, setShowToast] = useState(false);

  const notification = useSelector(state => state.notification);
  const navbarExtended = useSelector(state => state.frames.navbarExtended);

 // const backgrondRef = useRef();

  const path = useLocation().pathname;

  const bigNavbar = false; // (path === '/a' || path === '/a/' ||
  // path.substr(0, 7) === '/a/todo' || path.substr(0, 13) === '/a/connection')

  const renderChildren = () => {
    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {});
      }
      return child;
    });

    return childrenWithProps;
  };

  useEffect(() => {
    if (notification.toastMessage) {
      setShowToast(true);
    }
  }, [notification]);

  return (
    <div className="h-100 w-100 d-flex flex-column">
      <Navbar big={bigNavbar} menu={true} path={path} />
      <div
        id="background"
        className="background"
        style={{
          height: `calc(100% - ${bigNavbar && navbarExtended ? 286 : 120}px)`,
          overflow: "auto",
          position: "relative",
        }}
      >
        <div className="login-toast-container" style={{ zIndex: 100 }}>
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={4000}
            autohide
          >
            <Toast.Header bsPrefix={`toast-header-${notification.toastError ? 'error' : 'success'}`}>
              <span>{notification.toastMessage}</span>
            </Toast.Header>
          </Toast>
        </div>
        {renderChildren()}
      </div>
      <Footer />
    </div>
  );
};

export default AppFrame;
