import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { generateReport } from '../../../store/postmortem';
import { downloadPDF } from '../../../utils/PdfFileHelper';
import history from '../../../utils/history';
import PageWrapper from '../PageWrapper/PageWrapper';
import styles from '../../../screens/postmortem/DashBoard/DashBoard.module.css';

const PostMortemReport = ({
    report,
    generateReport,
    match
}) => {
    const [fileEncoded, setFileEncoded] = useState(null);
    const [loading, setLoading] = useState(true);
    const [backAmount, setBackAmount] = useState(-1);
    // const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
   
    
    const buttonClasses = "text-white border-gre border-2 w-50 py-2 flex-1  mx-auto";



    const downloadFile = () => {
        downloadPDF(fileEncoded);
    }

    const goBack = () => {
        history.go(backAmount);
    }

    useEffect(() => {
        // if we dont have a report or the report id is not the same as the id param
        // generate a new report
        // else set the file to the report data
        if (!report || report.report.id !== match.params.id) {
            generateReport(match.params.id);
            setBackAmount(-2);
        } else /*if (report && report.report)*/ {
            setFileEncoded(report.report.report_data);
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report]);

    return (
       
        <PageWrapper
            loading={loading}
            title="Post Mortem Report"
            type="all" 
        >
            <div className='text-white text-center'>
                
                <br />
                <button onClick={goBack}>Click here  to return back!</button>
            </div>
            <div
                //  ref={targetRef}
                className='mt-3' 
                style={
                    { 
                        margin: "auto",
                        width: "50%",
                        height: "100%"
                    }
            }>
                    <iframe
               
                        title={"chm-report"}
                        name={"chm-report"}
                        src={`data:application/pdf;base64,${fileEncoded}`}
                        height={"100%"}
                        width={"100%"}
                    />
                {/* <embed
                    src={`data:application/pdf;base64,${fileEncoded}`}
                    type="application/pdf"
                /> */}
                <div className={`${ styles.active } ${buttonClasses} ml-1 rounded`} style={{display:'flex',justifyContent:'center', minWidth: '200px'}}>
         {/* <button onClick={() => toPDF()}>Download PDF</button> */}
             <button onClick={downloadFile} >Your report is ready! Click here to download</button>
         </div>
            </div>
           
        </PageWrapper>
       
    );
};

const mapStateToProps = (state) => ({
    report: state.postmortem.report
});

const mapDispatchToProps = {
  generateReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostMortemReport);