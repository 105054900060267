// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ADD_VETANIMAL_REQUEST: "ADD_VETANIMAL_REQUEST",
    ADD_VETANIMAL_SUCCESS: "ADD_VETANIMAL_SUCCESS",
    ADD_VETANIMAL_FAILURE: "ADD_VETANIMAL_FAILURE",
    DELETE_VETANIMAL_REQUEST: "DELETE_VETANIMAL_REQUEST",
    DELETE_VETANIMAL_SUCCESS: "DELETE_VETANIMAL_SUCCESS",
    DELETE_VETANIMAL_FAILURE: "DELETE_VETANIMAL_FAILURE",
    UPDATE_VETANIMAL_REQUEST: "UPDATE_VETANIMAL_REQUEST",
    UPDATE_VETANIMAL_SUCCESS: "UPDATE_VETANIMAL_SUCCESS",
    UPDATE_VETANIMAL_FAILURE: "UPDATE_VETANIMAL_FAILURE",
    GET_VETANIMALS_REQUEST: "GET_VETANIMALS_REQUEST",
    GET_VETANIMALS_SUCCESS: "GET_VETANIMALS_SUCCESS",
    GET_VETANIMALS_FAILURE: "GET_VETANIMALS_FAILURE",
    GET_VETANIMAL_REQUEST: "GET_VETANIMAL_REQUEST",
    GET_VETANIMAL_SUCCESS: "GET_VETANIMAL_SUCCESS",
    GET_VETANIMAL_FAILURE: "GET_VETANIMAL_FAILURE",
    CLEAR_VETANIMAL: "CLEAR_VETANIMAL"
}