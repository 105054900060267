import types from './mediaTypes';
import { takeLatest, all } from "redux-saga/effects";
import * as actions from './mediaActions';


export default function* mediaSagas() {
    yield all([
        yield takeLatest(types.GET_MEDIA_REQUEST, actions.getMedia),
        yield takeLatest(types.GET_MEDIA_LIST_REQUEST, actions.getMediaList),
        yield takeLatest(types.ADD_SINGLE_MEDIA_REQUEST, actions.addSingleMedia),
        yield takeLatest(types.ADD_MEDIA_REQUEST, actions.addMedia),
        yield takeLatest(types.DELETE_MEDIA_REQUEST, actions.deleteMedia),
    ]);
}
