import types from './siteTypes';
import { takeLatest, all } from "redux-saga/effects";
import * as actions from './siteActions';


export default function* customerSagas() {
   
    yield all([
        yield takeLatest(types.GET_SITES_REQUEST, actions.getSites),
        yield takeLatest(types.GET_SITE_REQUEST, actions.getSite),
    ]);
}