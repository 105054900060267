import React, { forwardRef } from 'react';
import { Router, Switch } from 'react-router-dom';

import history from '../../utils/history';

import InviteUser from '../../screens/inviteUser';

import { withRouterAndRef } from '../../HOCs/withRouterAndRef';

const InviteUserRouter = forwardRef((props, ref) => {

  return (
    <Router history={history}>
      <Switch>
        <InviteUser ref={ref} {...props} exact path='/a/invite-user' />
      </Switch>
    </Router>
  );
})

export default withRouterAndRef(InviteUserRouter);
