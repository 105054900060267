import React from "react";
import "./TodoScreen.css";

class TodoScreen extends React.Component {
  render() {
    return (
      <div
        className='w-100 h-100 d-flex justify-content-center align-items-center'
        style={{ backgroundColor: '#2f353a', color: 'white' }}
      >
        <span>@TODO</span>
      </div>
    );
  }
}

export default TodoScreen;
