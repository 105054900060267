import libraryTypes from "./libraryTypes";

export const getUnitsOfMeasure = () => ({
  type: libraryTypes.GET_UNITS_OF_MEASURE_REQUEST,
});

export const getUnitOfMeasure = (uomId) => ({
  type: libraryTypes.GET_UNIT_OF_MEASURE_REQUEST,
  payload: { uomId },
});

export const getSpeciesList = () => ({
  type: libraryTypes.GET_SPECIES_LIST_REQUEST,
});

export const getSpecies = (speciesId) => ({
  type: libraryTypes.GET_SPECIES_REQUEST,
  payload: { speciesId },
});

export const getAnimalTypes = () => ({
  type: libraryTypes.GET_ANIMAL_TYPES_REQUEST,
});

export const getAnimalType = (typeId) => ({
  type: libraryTypes.GET_ANIMAL_TYPE_REQUEST,
  payload: { typeId },
});

export const getAnimalColors = () => ({
  type: libraryTypes.GET_ANIMAL_COLORS_REQUEST,
});

export const getAnimalColor = (colorId) => ({
  type: libraryTypes.GET_ANIMAL_COLOR_REQUEST,
  payload: { colorId },
});

export const getDiagnoses = () => ({
  type: libraryTypes.GET_DIAGNOSES_REQUEST,
});

export const getDiagnosis = (diagnosisId) => ({
  type: libraryTypes.GET_DIAGNOSIS_REQUEST,
  payload: { diagnosisId },
});

export const getHealthRisks = () => ({
  type: libraryTypes.GET_HEALTH_RISKS_REQUEST,
});

export const getHealthRisk = (healthRiskId) => ({
  type: libraryTypes.GET_HEALTH_RISK_REQUEST,
  payload: { healthRiskId },
});

export const getTreatments = () => ({
  type: libraryTypes.GET_TREATMENTS_REQUEST,
});

export const getTreatment = (treatmentId) => ({
  type: libraryTypes.GET_TREATMENT_REQUEST,
  payload: { treatmentId },
});

export const getBreeds = () => ({
  type: libraryTypes.GET_BREEDS_REQUEST,
});

export const getBreed = (breedId) => ({
  type: libraryTypes.GET_BREED_REQUEST,
  payload: { breedId },
});

export const getTagColors = (params) => ({
  type: libraryTypes.GET_TAG_COLORS_REQUEST,
  payload: {
    ...params,
  },
});

export const getAnimalRecords = () => ({
  type: libraryTypes.GET_ANIMAL_RECORDS_REQUEST,
});
