import React from "react";
import {Router, Switch, Route} from "react-router-dom";

import history from "../../utils/history";

import RecordSearch from "../../components/common/InterfaceSearch/RecordSearch";
import ResultSearch from "../../components/common/InterfaceSearch/ResultSearch";

const SearchRouter = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/a/search" component={RecordSearch} />
      <Route exact path="/a/search/result" component={ResultSearch} />
    </Switch>
  </Router>
);

export default SearchRouter;
