import React from 'react';
import styles from './Loading.module.css';

const Loading = (props) => {
  
  const showStyles = props.showStyles ?? true;

  if (!props.visible) {
    return ''
  }

  return (
    <div className={`${showStyles ? styles.Loading : ''} block w-100 text-center`}>
      <i className="fas fa-spinner  text-9xl fa-spin  mx-auto text-white" />
    </div>
  )
}

export default Loading;
