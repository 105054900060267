import types from "./searchTypes";

const initialState = {
  filterValues: {
    rfid: '',
    tag: '',
    receivedDate: '',
    completedDate: '',
    vet: '',
    customer: '',
    status: '',
    ticket: '',
    site: '',
    lot: '',
  },
  loading: false,
  pagination: {
    current: 1,
    displayed: 0,
    total: 0
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_FILTER_VALUE:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.payload.name]: action.payload.value
        },
      };
    case types.SEARCH_GET_POSTMORTEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SEARCH_GET_POSTMORTEMS_SUCCESS:
      const payload = action.payload;
      const pagination = payload.data.pagination;
      const list = payload.data.post_mortems
      return {
        ...state,
        list: [...new Map(list.map(item =>[item.id, item])).values()],
        pagination: {...pagination},
        loading: false,
      };
    case types.SEARCH_GET_POSTMORTEMS_FAILURE:
    return {
      ...state,
      loading: false,
    };
    default:
      return state;
  }
}
