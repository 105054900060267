import types from './vetAnimalTypes';

export const addVetAnimal = (animal) => {
  return {
    type: types.ADD_VETANIMAL_REQUEST,
    payload: { animal }
  }
};

export const getVetAnimals = () => ({
  type: types.GET_VETANIMALS_REQUEST,
});

export const getVetAnimal = (id) => ({
  type: types.GET_VETANIMAL_REQUEST,
  payload: {
    id
  }
});

export const clearVetAnimal = () => {
  return {
    type: types.CLEAR_POSTMORTEM,
    payload: {}
  }
}

export const deleteVetAnimal = (animalId, pmId ) => ({
  type: types.DELETE_VETANIMAL_REQUEST,
  payload: {animalId, pmId},
});

export const updateVetAnimal = (animal) => ({
  type: types.UPDATE_VETANIMAL_REQUEST,
  payload: {animal},
});

export const defaultVetAnimal = () => ({
  veterinary_record_id: undefined,
  tag: undefined,
  rfid: undefined,
  pen: undefined,
  lot: undefined
});
