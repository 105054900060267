
import callApi from "../../utils/callApi";
import types from './mediaTypes';
import { take, put } from "redux-saga/effects";
import getSiteEndpoint from "../../utils/getSiteEndpoint";

export function* addSingleMedia (action) {
  const { array, animalId } = action.payload;

  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const params = {
      image: item.file,
      name: item.file.name,
      media_attachable_type: "VeterinaryRecordAnimal",
      media_attachable_id: animalId,
    };

    yield put({ type: types.ADD_MEDIA_REQUEST, payload: { data: params } });
    yield take(types.ADD_MEDIA_SUCCESS);
  };

  yield put({ type: types.GET_MEDIA_LIST_REQUEST, payload: { animalId } });
  yield put({ type: types.ADD_SINGLE_MEDIA_SUCCESS });
}

export function* addMedia (action) {
  const { data } = action.payload;
  yield callApi({
    method: "post",
    url: `${getSiteEndpoint()}/medias`,
    type: "ADD_MEDIA",
    body: data,
    isSendFormData: true,
    errorMessage: "There was an error while saving a photo.",
    successMessage: "Photo was successfully saved.",
  });
}

export function* getMediaList(action) {
  console.log('Animal detail-->',action);
  // const { animalId } = action.payload;
  const animalId = action.payload.animalId
  const page = action.payload.page

  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/medias?filter[media_attachable_type]=VeterinaryRecordAnimal&filter[media_attachable_id]=${animalId}`,
    type: "GET_MEDIA_LIST",
    dataProp: `medias`,
    errorMessage: "There was an error while getting photos.",
    reqData : page ? { page: page, per_page: 10 } :null
  });
}

export function* getMedia (action) {
  const { mediaId } = action.payload;
  yield callApi({
    method: "get",
    url: `${getSiteEndpoint()}/medias/${mediaId}`,
    type: "GET_MEDIA",
    dataProp: `media`,
    errorMessage: "There was an error while getting photo.",
  });
}

export function* deleteMedia (action) {
  const { id } = action.payload;
  yield callApi({
    method: "DELETE",
    url: `${getSiteEndpoint()}/medias/${id}`,
    type: "DELETE_MEDIA",
    dataProp: `medias`,
    body: id,
    errorMessage: "There was an error while deleting photo.",
    successMessage: "Photo was successfully deleted.",
  });
}
