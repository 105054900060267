import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./GridSelect.css";

const GridSelect = ({ items, value, onChangeValue, tag, icon, multiple, className }) => {
  if (!items || Object.keys(items).length < 1) return null;
  const tableItems = [[]];
  let i = 0, j = 0;
  Object.values(items).forEach(item => {
    tableItems[i][j] = item;
    j++;
    if (j > 2) {
      i++;
      j = 0;
      tableItems.push([])
    }
  });
  return (
    <div className={`grid-select-container ${className}`}>
      {icon && (<FontAwesomeIcon icon={icon} size='2x' className='icon' />)}
      <div className='w-100'>
        <table className='grid-select'>
          <tbody>
            {tableItems.map((tableRow, ti) => (
              <tr key={ti}>
                {tableRow.map((tableItem, tj) => (
                  multiple ? (
                    <td
                      onClick={() => onChangeValue(tableItem.id)}
                      className={value.includes(tableItem.id) ? 'selected' : null}
                      key={tj}
                    >
                      {tableItem.name}
                    </td>
                  ) : (
                    <td
                      onClick={() => onChangeValue(tableItem.id)}
                      className={tableItem.id === value ? 'selected' : null}
                      key={tj}
                    >
                      {tableItem.name}
                    </td>
                  )
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='tbl-tag'>{tag}</div>
      </div>
    </div>
  );
};

export default GridSelect;
