import React from 'react';
import usePullToRefresh from './usePullToRefresh';
import Loader from './Loader'; // Import the Loader component

const PullToRefreshWrapper = ({ children }) => {
  const loading = usePullToRefresh(500); // Adjust the threshold value if needed

  return (
    <>
      {loading && <Loader />} {/* Show loader when loading is true */}
      {children}
    </>
  );
};

export default PullToRefreshWrapper;

////////////


