import types from "./siteTypes";

export const getSites = (params) => ({
  type: types.GET_SITES_REQUEST,
  payload: {
    ...params,
  },
});

export const getSite = (id) => ({
  type: types.GET_SITE_REQUEST,
  payload: { id }
});

export const initialSite = () => ({
  loading: true
});